import React from "react";
import {
  Squares2X2Icon,
  ListBulletIcon,
  UserPlusIcon,
} from "@heroicons/react/24/outline";
import { Box, Stack } from "@mui/material";
import SelectorFilter from "../../../components/filters_new/SelectorFilter";
import SimpleFilter from "../../../components/filters_new/SimpleFilter";
import { IApplicationsFilters } from "../../../models/Applications";
import {
  StyledApplicationsFilterContainer,
  StyledAddApplicantsButton,
} from "../styles";
import AutocompleteFilter from "../../../components/filters_new/AutocompleteFilter";

const ApplicationsFiltersComponent = ({
  t,
  jobTypeFilter,
  stateFilter,
  quickSearch,
  jobId,
  location,
  view,
  allJobs,
  allLocations,
  isAgency,
  customers,
  companyFilter,
  onOpenApplyJobDialog,
  onChangeFilters,
  onChangeView,
}: IApplicationsFilters) => (
  <StyledApplicationsFilterContainer
    isAgency={isAgency}
    jobId={jobId}
    direction={"row"}
  >
    <Box className={"quick-search-container"}>
      <SimpleFilter
        className="applications-search-autocomplete"
        value={quickSearch}
        onChange={(value) =>
          onChangeFilters({ value, filterType: "quickSearch" })
        }
        placeholder={t("applications.search_placeholder")}
      />
      {!!isAgency && !jobId && (
        <AutocompleteFilter
          listBoxProps={{
            className: "applications-jobs-autocomplete",
          }}
          onChange={(value: any) =>
            onChangeFilters({ value, filterType: "company" })
          }
          value={companyFilter.value}
          options={customers}
          placeholder={t("joblist.search_by_company_placeholder")}
        />
      )}
      {jobId && (
        <Stack direction="row" spacing={1.5} ml={2}>
          <ListBulletIcon
            width={24}
            height={24}
            stroke={view === "table" ? "#178CF2" : "#6B7280"}
            strokeWidth={1}
            cursor="pointer"
            onClick={() => onChangeView("table")}
          />
          <Squares2X2Icon
            width={24}
            height={24}
            stroke={view === "cards" ? "#178CF2" : "#6B7280"}
            strokeWidth={1}
            cursor="pointer"
            onClick={() => onChangeView("cards")}
          />
        </Stack>
      )}
    </Box>
    <Stack
      direction={"row"}
      className={"advanced-filters-container"}
      spacing={4}
      alignItems="flex-end"
    >
      {view !== "cards" && (
        <Box
          className={"location-search-container"}
          maxWidth={view === "cards" ? "45%" : "100%"}
        >
          <AutocompleteFilter
            listBoxProps={{
              className: "applications-jobs-autocomplete",
            }}
            onChange={(value: any) =>
              onChangeFilters({ value, filterType: "location" })
            }
            value={location}
            options={allLocations || []}
            placeholder={t("joblist.location_placeholder")}
          />
        </Box>
      )}
      {!jobId && (
        <AutocompleteFilter
          listBoxProps={{
            className: "applications-jobs-autocomplete",
          }}
          onChange={(value: any) => {
            onChangeFilters({ value, filterType: "appliedTo" });
          }}
          value={jobTypeFilter.value}
          options={allJobs || []}
          placeholder={t("applications.job_placeholder")}
        />
      )}
      {view === "cards" ? (
        <StyledAddApplicantsButton
          onClick={onOpenApplyJobDialog}
          variant="contained"
          startIcon={<UserPlusIcon />}
        >
          {t("joblist.add")}
        </StyledAddApplicantsButton>
      ) : (
        <SelectorFilter
          isDisabled={!stateFilter.options.length}
          className={"input-selector-filter"}
          label={t("applications.status_placeholder")}
          value={stateFilter.value}
          options={stateFilter.options}
          onChange={(value: string) => {
            onChangeFilters({ value, filterType: "status" });
          }}
        />
      )}
    </Stack>
  </StyledApplicationsFilterContainer>
);

export default ApplicationsFiltersComponent;
