import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import {
  ILoginState,
  TLoginPayload,
  TNewPasswordFormFields,
} from "../../models/Auth";
import { PayloadActionWithCallback } from "../../models/common";

const initialState: ILoginState = {
  email: "",
  isLoading: false,
  error: null,
};

const loginReducers = {
  logIn: (state: Draft<ILoginState>, _: PayloadAction<TLoginPayload>) => {
    state.isLoading = true;
  },
  logInSuccess: (state: Draft<ILoginState>) => {
    state.isLoading = false;
    state.error = null;
  },
  logInFailed: (state: Draft<ILoginState>, action: PayloadAction<unknown>) => {
    state.isLoading = false;
    state.error = action.payload; // TODO handle error
  },
  candidateLogIn: (
    state: Draft<ILoginState>,
    _: PayloadActionWithCallback<{ code: string }>
  ) => {
    state.isLoading = true;
  },
  candidateLogInSuccess: (state: Draft<ILoginState>) => {
    state.isLoading = false;
    state.error = null;
  },
  candidateLogInFailed: (
    state: Draft<ILoginState>,
    action: PayloadAction<unknown>
  ) => {
    state.isLoading = false;
    state.error = action.payload; // TODO handle error
  },
  resetPassword: (
    state: Draft<ILoginState>,
    action: PayloadActionWithCallback<{ email: string }>
  ) => {
    const { email } = action.payload;
    state.email = email;
    state.isLoading = true;
  },
  resetPasswordSuccess: (state: Draft<ILoginState>) => {
    state.isLoading = false;
    state.error = null;
  },
  resetPasswordFailed: (
    state: Draft<ILoginState>,
    action: PayloadAction<unknown>
  ) => {
    state.isLoading = false;
    state.error = action.payload; // TODO handle error
  },
  recoveryPassword: (
    state: Draft<ILoginState>,
    _: PayloadActionWithCallback<TNewPasswordFormFields & { code: string }>
  ) => {
    state.isLoading = true;
  },
  recoveryPasswordSuccess: (state: Draft<ILoginState>) => {
    state.isLoading = false;
    state.error = null;
  },
  recoveryPasswordFailed: (
    state: Draft<ILoginState>,
    action: PayloadAction<unknown>
  ) => {
    state.isLoading = false;
    state.error = action.payload; // TODO handle error
  },
  logOut: () => {},
};

export const AuthSlice = createSlice({
  name: "AUTHENTICATION",
  initialState,
  reducers: {
    ...loginReducers,
  },
});

export const {
  logIn,
  logInSuccess,
  logInFailed,
  candidateLogIn,
  candidateLogInFailed,
  candidateLogInSuccess,
  resetPassword,
  resetPasswordSuccess,
  resetPasswordFailed,
  recoveryPassword,
  recoveryPasswordSuccess,
  recoveryPasswordFailed,
  logOut,
} = AuthSlice.actions;

export default AuthSlice.reducer;
