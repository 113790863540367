import React from "react";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import { Avatar, Divider } from "@mui/material";
import PhotoIcon from "@mui/icons-material/Photo";
import {
  StyledProfilePictureContainer,
  StyledProfilePictureOverlay,
} from "./styles";

const ProfilePicture_new = ({
  imgSrc,
  type = "rounded",
  onDelete,
  onOpenUploadDialog,
}: {
  imgSrc?: string;
  type?: "rounded" | "circular" | "square";
  onDelete: () => void;
  onOpenUploadDialog: () => void;
}) => (
  <StyledProfilePictureContainer
    width={type !== "rounded" ? 180 : 120}
    height={type !== "rounded" ? 92 : 120}
  >
    <Avatar
      src={imgSrc}
      sx={{
        width: type !== "rounded" ? 180 : 120,
        height: type !== "rounded" ? 92 : 120,
        borderRadius: type !== "rounded" ? "6px" : "50%",
        "& img": {
          objectFit: "contain",
        },
      }}
    >
      {imgSrc && type === "rounded" ? undefined : (
        <PhotoIcon
          sx={{
            width: 64,
            height: 64,
          }}
        />
      )}
    </Avatar>
    <StyledProfilePictureOverlay
      width={type !== "rounded" ? 180 : 120}
      height={type !== "rounded" ? 92 : 120}
      borderRadius={type !== "rounded" ? "6px" : "50%"}
    >
      <TrashIcon className={"overlay-icon"} onClick={onDelete} />
      <Divider orientation="vertical" className={"overlay-divider"} />
      <PencilSquareIcon
        className={"overlay-icon"}
        onClick={onOpenUploadDialog}
      />
    </StyledProfilePictureOverlay>
  </StyledProfilePictureContainer>
);

export default ProfilePicture_new;
