import { Box, InputLabel, Stack, TextField, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import React from "react";
import { Controller } from "react-hook-form";
import { IJobDetailsAdditionalInfoSection } from "../../../models/JobEditor";
import AutocompleteFilter from "../../filters_new/AutocompleteFilter";
import { StyledSubSectionContainer } from "../styles";

const AdditionalInfoSection = ({
  t,
  errors,
  control,
  register,
  companyMembers,
}: IJobDetailsAdditionalInfoSection) => (
  <>
    <Typography variant="body2">
      {t("create_job_second_step.general_information")}
    </Typography>
    <StyledSubSectionContainer spacing={2}>
      <Stack direction={"row"} spacing={2}>
        <Stack sx={{ flex: 1 }}>
          <InputLabel>
            {t("create_job_second_step.reference_number")}
          </InputLabel>
          <TextField
            placeholder={t("create_job_second_step.reference_number") as string}
            fullWidth
            {...register("reference_number")}
          />
        </Stack>
        <Stack sx={{ flex: 1 }}>
          <InputLabel>
            {t("create_job_second_step.date_of_publication")}
          </InputLabel>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Controller
              name={"date_of_publication"}
              control={control}
              render={({ field: { onChange, value } }) => {
                return <DatePicker value={dayjs(value)} onChange={onChange} />;
              }}
            />
          </LocalizationProvider>
        </Stack>
      </Stack>
      <Stack direction={"row"} spacing={2}>
        <Box sx={{ flex: 1 }}>
          <InputLabel>{t("create_job_second_step.assign_job_to")}</InputLabel>
          <Controller
            name={"assign_job_to"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <AutocompleteFilter
                multiple
                size={"small"}
                onChange={onChange}
                value={value || []}
                options={companyMembers}
                placeholder={t("create_job_second_step.assign_job_to")}
                errors={errors["assign_job_to"]}
              />
            )}
          />
        </Box>
        <Stack sx={{ flex: 1 }}>
          <InputLabel>{t("create_job_second_step.keywords")}</InputLabel>
          <TextField
            placeholder={t("create_job_second_step.keywords") as string}
            fullWidth
            {...register("keywords")}
          />
        </Stack>
      </Stack>
    </StyledSubSectionContainer>
  </>
);

export default AdditionalInfoSection;
