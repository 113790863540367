import {
  TCompanyRecruiterFormFields,
  TCompanyRegistrationCompanyDataPayload
} from "../models/Registration";
import { apiRequest } from "./config";

export const apiCheckCompanyName = ({
  url,
  apiPayload
}: {
  url: string;
  apiPayload: { name: string };
}) => apiRequest({ type: "post", url, apiPayload });

export const apiCheckRecruiterEmail = ({
  url,
  apiPayload
}: {
  url: string;
  apiPayload: { email: string };
}) => apiRequest({ type: "post", url, apiPayload });

export const apiConfirmInvitation = ({ url }: { url: string }) =>
  apiRequest({ type: "post", url });

export const apiCompleteInvitation = ({
  url,
  apiPayload
}: {
  url: string;
  apiPayload: Omit<TCompanyRecruiterFormFields, "checked" | "email">;
}) => apiRequest({ type: "post", url, apiPayload });

export const apiRegisterCompany = ({
  url,
  apiPayload
}: {
  url: string;
  apiPayload: TCompanyRegistrationCompanyDataPayload;
}) => apiRequest({ type: "post", url, apiPayload });

export const apiResendLink = ({
  url,
  apiPayload
}: {
  url: string;
  apiPayload: { candidate_urlkey: string };
}) => apiRequest({ type: "post", url, apiPayload });

export const apiConfirmEmailCode = ({ url }: { url: string }) =>
  apiRequest({ type: "post", url });

export const apiConfirmExistingCompanyToAgency = ({ url }: { url: string }) =>
  apiRequest({ type: "post", url });

export const apiCompanyMembersMagicLinkLogin = ({ url }: { url: string }) =>
  apiRequest({ type: "post", url });
