import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import {
  BriefcaseIcon,
  ChevronRightIcon,
  FolderOpenIcon,
  MapPinIcon,
  BuildingOffice2Icon,
  BanknotesIcon
} from "@heroicons/react/24/outline";
import EmptyData from "../../components/EmptyData";
import Loading from "../../components/Loading";
import {
  ICandidateDashboardComponent,
  TCandidateApplication
} from "../../models/ApplicationPage";
import { StyledJobContainer, StyledJobItemContainer } from "./styles";
import { StyledStatusChip } from "../../components/DataGrid_new/styles";
import { formatLogoUrl } from "../../utils";
import { convertSalaryString, getSalarySymbol } from "../../utils/common";

const CandidateDashboardComponent = ({
  t,
  desktop,
  isLoading,
  applications,
  isCandidate,
  onSelectJob,
  formattedStatus
}: ICandidateDashboardComponent) =>
  isLoading ? (
    <Loading />
  ) : isCandidate ? (
    applications && applications.length > 0 ? (
      <Stack spacing={1.5} mt={3}>
        <Typography variant="subtitle2" color="textSecondary">
          {t("candidate_dashboard.subtitle")}
        </Typography>
        {applications?.map((application: TCandidateApplication) => (
          <StyledJobContainer
            key={application.id!.toString()}
            direction={"row"}
            spacing={2}
            p={2}
            onClick={() => {
              onSelectJob(application);
            }}
          >
            {desktop && (
              <Box>
                {application.job.logo ? (
                  <img
                    src={formatLogoUrl(application.job.logo)}
                    width={56}
                    height={56}
                    alt={"company logo"}
                    style={{
                      objectFit: "contain"
                    }}
                  />
                ) : (
                  <BuildingOffice2Icon className={"company-logo"} />
                )}
              </Box>
            )}
            <Stack width={"100%"} spacing={1}>
              <Typography variant={"h6"}>{application.job.title}</Typography>
              <Stack
                direction={desktop ? "row" : "column"}
                spacing={desktop ? 2 : 1}
              >
                <StyledJobItemContainer direction="row" spacing={1}>
                  <MapPinIcon />
                  <Typography
                    variant="subtitle2"
                    display="inline"
                    color="textSecondary"
                  >
                    {`${application?.job_locations[0]?.city}, ${application?.job_locations[0]?.nicename}`}
                  </Typography>
                </StyledJobItemContainer>
                <StyledJobItemContainer direction="row" spacing={1}>
                  <BriefcaseIcon />
                  <Typography
                    variant="subtitle2"
                    display="inline"
                    color="textSecondary"
                  >
                    {application.job.position_type}
                  </Typography>
                </StyledJobItemContainer>
                <StyledJobItemContainer direction="row" spacing={1}>
                  <FolderOpenIcon />
                  <Typography
                    variant="subtitle2"
                    display="inline"
                    color="textSecondary"
                  >
                    {application.job.work_type}
                  </Typography>
                </StyledJobItemContainer>
                {application.job.min_salary && application.job.max_salary && (
                  <StyledJobItemContainer direction="row" spacing={1}>
                    <BanknotesIcon className="icon" />
                    <Typography
                      variant="subtitle2"
                      display="inline"
                      color="textSecondary"
                    >
                      {t("jobPreview.salary", {
                        currency: getSalarySymbol(
                          application.job.salary_currency || ""
                        ),
                        min: convertSalaryString(application.job.min_salary),
                        max: convertSalaryString(application.job.max_salary)
                      })}

                      {application.job?.salary_type && (
                        <>
                          <span> / </span>
                          {t(
                            `singleJob.jobDetails.salary.type.${application.job?.salary_type}`
                          )}
                        </>
                      )}
                    </Typography>
                  </StyledJobItemContainer>
                )}
                {application.status && (
                  <StyledStatusChip
                    sx={{ width: "fit-content" }}
                    type={application.status}
                    label={formattedStatus(application.status) as any}
                  />
                )}
              </Stack>
            </Stack>
            {desktop && <ChevronRightIcon className={"next-icon"} />}
          </StyledJobContainer>
        ))}
      </Stack>
    ) : (
      <EmptyData title={t("candidate_dashboard.no_jobs") as string} />
    )
  ) : (
    <></>
  );

export default CandidateDashboardComponent;
