import React from "react";
import { Stack } from "@mui/material";
import { ICellRendererParams } from "ag-grid-community";
import { useTranslation } from "react-i18next";
import StatusSelectorCell from "./statusSelector";
import CandidateInTalentPoolIcon from "../../CandidateInTalentPoolIcon";
import { StyledStatusInColumnText } from "../../Kanban/style";
import { TDataGridActions } from "../../../models/DataGrid";
import { daysBeetwen } from "../../../utils/singleJob";

const statusSelectorWithDaysInStatus = ({
  params,
  actions,
}: {
  readonly params: ICellRendererParams;
  readonly actions: TDataGridActions;
}) => {
  const { t } = useTranslation();

  const showInStatusText =
    params.data.status === "new" || params.data.status === "review";
  const numberOfDaysInStatus = daysBeetwen(params.data.updated_at);
  const statusType =
    numberOfDaysInStatus < 4
      ? "normal"
      : numberOfDaysInStatus < 8
      ? "orange"
      : "red";

  return (
    <Stack mt={showInStatusText ? 0.65 : 0}>
      <Stack direction="row" spacing={0.5}>
        <StatusSelectorCell
          params={params}
          label={params.value}
          type={params.value}
          onSelect={(newStatus: string) => {
            actions.onChangeStatus &&
              actions.onChangeStatus({
                isTalentPooled: params.data.is_talentpooled == 1,
                talentPoolTitle: params.data.talentpool_title,
                applicationId: params.data.application_id,
                newStatus,
              });
          }}
        />
        {params?.data.is_talentpooled == 1 && (
          <CandidateInTalentPoolIcon
            firstname={params?.data?.firstname}
            lastname={`${params?.data?.lastname} ${t(
              "talentPools.isInYourTalentPool"
            )}`}
            photo={params?.data?.photo}
          />
        )}
      </Stack>
      {showInStatusText && (
        <StyledStatusInColumnText type={statusType} paddingLeft={0.5}>
          {t("single_job.in_status_for", { numberOfDaysInStatus })}
        </StyledStatusInColumnText>
      )}
    </Stack>
  );
};

export default statusSelectorWithDaysInStatus;
