import React, { ReactNode, SyntheticEvent } from "react";
import { TreeItem, TreeView } from "@mui/lab";
import { Button, Stack, Tooltip } from "@mui/material";
import { TFunction } from "i18next";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  XMarkIcon
} from "@heroicons/react/24/outline";
import logo from "../../../assets/logo.png";
import oneLetterLogo from "../../../assets/oneLetterLogo.png";
import {
  StyledComingSoonLabel,
  StyledDrawer,
  StyledDrawerContent,
  StyledDrawerHeader,
  StyledToggleMenuButton
} from "./styles";

const SidebarComponent = ({
  t,
  expandedMenuItems,
  isCandidate,
  isOpenMenu,
  isLoading,
  menuItems,
  selectedMenuItems,
  desktop,
  mobileDrawerOpen,
  onMobileDraweClose,
  onExpandMenuItem,
  onNavigateMenu,
  onOpenJobEditor,
  onSelectMenuItem,
  onToggleMenu
}: {
  t: TFunction;
  expandedMenuItems: string[];
  isCandidate: boolean;
  isOpenMenu: boolean;
  isLoading: boolean;
  menuItems: any;
  selectedMenuItems: string[];
  desktop: boolean;
  mobileDrawerOpen: boolean;
  onMobileDraweClose: () => void;
  onExpandMenuItem: (event: SyntheticEvent, nodeId: string[]) => void;
  onNavigateMenu: (route: string) => void;
  onOpenJobEditor: () => void;
  onSelectMenuItem: (event: SyntheticEvent, nodeId: string[]) => void;
  onToggleMenu: () => void;
}) => (
  <>
    {desktop && (
      <StyledToggleMenuButton open={isOpenMenu} onClick={onToggleMenu}>
        <>
          {isOpenMenu ? (
            <ChevronLeftIcon width={16} height={16} stroke={"#fff"} />
          ) : (
            <ChevronRightIcon width={16} height={16} stroke={"#fff"} />
          )}
        </>
      </StyledToggleMenuButton>
    )}
    <StyledDrawer
      open={isOpenMenu}
      variant="permanent"
      mobileDrawerOpen={mobileDrawerOpen}
    >
      <StyledDrawerHeader>
        <Stack
          direction="row"
          px={desktop ? 0 : 3}
          justifyContent={desktop ? "center" : "space-between"}
          alignItems="flex-end"
          width="100%"
        >
          {isOpenMenu ? (
            <img src={logo} alt="logo" />
          ) : (
            <img src={oneLetterLogo} alt="logo" />
          )}
          {!desktop && (
            <XMarkIcon
              width={24}
              height={24}
              stroke="#fff"
              cursor="pointer"
              onClick={onMobileDraweClose}
            />
          )}
        </Stack>
      </StyledDrawerHeader>
      {isLoading ? null : (
        <StyledDrawerContent open={isOpenMenu}>
          <TreeView
            expanded={expandedMenuItems}
            selected={selectedMenuItems}
            onNodeSelect={onSelectMenuItem}
            sx={{ width: "100%" }}
            onNodeToggle={onExpandMenuItem}
          >
            {menuItems.map(
              (menuItem: {
                label: string;
                route: string;
                icon: ReactNode;
                nodeId: string;
                comingSoon: boolean;
                children?: any[];
              }) => (
                <TreeItem
                  key={menuItem.nodeId}
                  nodeId={menuItem.nodeId}
                  onClick={() => onNavigateMenu(menuItem.route)}
                  label={
                    isOpenMenu ? (
                      <Stack
                        pl={1}
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        {menuItem.label}
                        {menuItem.children &&
                          (expandedMenuItems.includes(menuItem.nodeId) ? (
                            <ChevronUpIcon width={20} height={20} />
                          ) : (
                            <ChevronDownIcon width={20} height={20} />
                          ))}
                        {menuItem.comingSoon && (
                          <StyledComingSoonLabel
                            label={t("comming_soon")}
                            size="small"
                          />
                        )}
                      </Stack>
                    ) : null
                  }
                  icon={menuItem.icon}
                >
                  {menuItem.children?.map(
                    (subMenuItem) =>
                      subMenuItem && (
                        <TreeItem
                          key={subMenuItem.nodeId}
                          nodeId={subMenuItem.nodeId}
                          onClick={() => onNavigateMenu(subMenuItem.route)}
                          label={
                            isOpenMenu ? (
                              <Stack
                                pl={1}
                                direction={"row"}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                                color={"#6B7280"}
                              >
                                {subMenuItem.label}
                              </Stack>
                            ) : null
                          }
                          icon={subMenuItem.icon}
                        />
                      )
                  )}
                </TreeItem>
              )
            )}
          </TreeView>
          {!isCandidate && (
            <>
              {isOpenMenu ? (
                <Button
                  fullWidth
                  variant="contained"
                  onClick={onOpenJobEditor}
                  sx={{ marginTop: "auto" }}
                >
                  {t("navigation.create_job_ad")}
                </Button>
              ) : (
                <Tooltip
                  title={t("navigation.create_job_ad")}
                  PopperProps={{ style: { zIndex: 1299 } }}
                >
                  <Button
                    variant="contained"
                    onClick={onOpenJobEditor}
                    sx={{ marginTop: "auto", minWidth: 48 }}
                  >
                    <b>+</b>
                  </Button>
                </Tooltip>
              )}
            </>
          )}
        </StyledDrawerContent>
      )}
    </StyledDrawer>
  </>
);

export default SidebarComponent;
