import React, { useEffect, useMemo, useRef, useState } from "react";
import moment from "moment";
import { useParams } from "react-router";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DIALOG_TYPE } from "../../models/common";
import {
  IJobListState,
  JOB_STATUSES,
  TJobListItem,
} from "../../models/JobList";
import { setSingleJobUrlKey } from "../../slices/Candidates/candidatesSlice";
import { setJobHeader } from "../../slices/Dashboard/dashboardSlice";
import {
  deleteJobs,
  fetchJobs,
  resetJobsFilters,
  setIsDraftImagesLoading,
  setPageNum,
  setSelectedJobs,
  setSelectedJobStatus,
} from "../../store_new/reducers/JobList";
import {
  getCurrentUserData,
  getCurrentUserIsCandidate,
} from "../../store_new/selectors/CurrentUser";
import {
  getCurrentStatusJobListFiltersState,
  getJobListFilteredPaginationState,
  getJobListIsListLoading,
  getJobListIsLoading,
  getJobListJobsState,
  getJobListNavigationState,
} from "../../store_new/selectors/JobList";
import JobListComponent from "./component";
import { JOB_TABS_KEY, TJobDetails } from "../../models/SingleJob";
import {
  setTopHeaderSubtitle,
  setTopHeaderTitle,
} from "../../store_new/reducers/TopHeader";
import {
  fetchDraftData,
  openJobEditor,
  setJobDescripitonData,
  setJobEditorStep,
  setUpdateDraftInProgress,
  updateCompletedStep,
  updateJobApplicationProcess,
  updateJobDescription,
  updateJobDetails,
} from "../../store_new/reducers/JobEditor";
import { TJob } from "../../models/CompanyInfo";
import { getJobEditorState } from "../../store_new/selectors/JobEditor";
import { downloadTemplateMedia, formatLogoBlobForRedux } from "../../utils";
import {
  setArchiveRestoreDialogOpen,
  setDuplicateJobUrlKey,
  setIsDuplicateDialogOpen,
  setIsInviteMemberDialogOpen,
  setIsLimitDialogOpen,
  setIsSelectCustomerDialogOpen,
} from "../../store_new/reducers/dialogs";
import { getCompanySettingsCompanyState } from "../../store_new/selectors/Company";
import { setTempJobDetails } from "../../store_new/reducers/SingleJob";

const JobList_new = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { tab } = useParams();
  const isCandidate = useSelector(getCurrentUserIsCandidate);
  const { isAgency, applicationsAllowed } = useSelector(getCurrentUserData);
  const { jobsStatuses, selectedJobStatus }: IJobListState["navigation"] =
    useSelector(getJobListNavigationState);
  const { list: jobList, totalCount: jobCount } =
    useSelector(getJobListJobsState);
  const { jobType, seniority, locations, quickSearch, companyName } =
    useSelector(getCurrentStatusJobListFiltersState);
  const isLoading = useSelector(getJobListIsLoading);
  const isListLoading = useSelector(getJobListIsListLoading);
  const { draftData, updateDraftInProgress, jobDescription } =
    useSelector(getJobEditorState);
  const { pageSize, pageNum } = useSelector(getJobListFilteredPaginationState);

  const { company } = useSelector(getCompanySettingsCompanyState);

  const CSVLinkRef = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);
  const [isApplyJobDialogOpen, setIsApplyJobDialogOpen] = useState(false);
  const [jobUrlKeys, setJobUrlKeys] = useState<string[]>([]);
  const [photosBlob, setPhotosBlob] = useState({});
  const [templatePhotos, setTemplatePhotos] = useState<any>({});
  const [csvData, setCsvData] = useState<
    (Omit<TJobListItem, "locations"> & { locations: string })[] | null
  >(null);
  const [openedDialogType, setOpenedDialogType] = useState<DIALOG_TYPE | null>(
    null
  );

  const photoUrls = useMemo(
    () => ({
      logoFile: draftData?.jobTemplate?.logo_original_name ?? null,
      header1: draftData?.jobTemplate?.header_1_original_name ?? null,
      header2: draftData?.jobTemplate?.header_2_original_name ?? null,
      header3: draftData?.jobTemplate?.header_3_original_name ?? null,
      backgroundFile: draftData?.jobTemplate?.background_original_name ?? null,
    }),
    [draftData]
  );

  const modifiedJobList = useMemo(
    () => jobList.map((job) => ({ ...job, status: selectedJobStatus })),
    [jobList]
  );

  const handleOnChangeTab = (
    _: React.SyntheticEvent,
    jobStatus: JOB_STATUSES
  ) => {
    dispatch(setSelectedJobStatus(jobStatus));
    navigate(`/jobs/${jobStatus}`);
  };

  const handleOnClick = ({
    id,
    title,
    created_at,
    url_key,
  }: {
    readonly id: TJobListItem["id"];
    readonly title: TJobListItem["title"];
    readonly created_at: TJobListItem["created_at"];
    readonly url_key: TJobListItem["url_key"];
  }) => {
    // TODO refactor dispatches
    dispatch(
      setJobHeader({
        title,
        created_at,
      })
    );
    dispatch(setSingleJobUrlKey(url_key));
    dispatch(setTopHeaderTitle(title));
    dispatch(
      setTopHeaderSubtitle(
        `${t("single_job.date_created")} ${moment(created_at).format(
          "DD.MM.YYYY"
        )}`
      )
    );
    if (isAgency && !applicationsAllowed) {
      navigate(`/jobs/${id}/job_details`);
    } else {
      navigate(`/jobs/${id}/applications`);
    }
  };

  const handleOnClickApplicationsNumber = ({
    id,
    title,
    created_at,
    url_key,
  }: {
    readonly id: TJobListItem["id"];
    readonly title: TJobListItem["title"];
    readonly created_at: TJobListItem["created_at"];
    readonly url_key: TJobListItem["url_key"];
  }) => {
    if (applicationsAllowed || !isAgency) {
      dispatch(
        setJobHeader({
          title,
          created_at,
        })
      );
      dispatch(setSingleJobUrlKey(url_key));
      dispatch(setTopHeaderTitle(title));
      dispatch(
        setTopHeaderSubtitle(
          `${t("single_job.date_created")} ${moment(created_at).format(
            "DD.MM.YYYY"
          )}`
        )
      );

      navigate(`/jobs/${id}/applications`);
    }
  };

  const handleOnEdit = ({
    id,
    title,
    created_at,
    url_key,
  }: {
    readonly id: TJobListItem["id"];
    readonly title: TJobListItem["title"];
    readonly created_at: TJobListItem["created_at"];
    readonly url_key: TJobListItem["url_key"];
  }) => {
    dispatch(
      setJobHeader({
        title,
        created_at,
      })
    );
    dispatch(setSingleJobUrlKey(url_key));
    const formattedTab = JOB_TABS_KEY.JOB_DETAILS.split(".")[1];
    navigate(`/jobs/${parseInt(id)}/${formattedTab}`);
  };

  const handleOnPromote = ({
    id,
    title,
    created_at,
    url_key,
  }: {
    readonly id: TJobListItem["id"];
    readonly title: TJobListItem["title"];
    readonly created_at: TJobListItem["created_at"];
    readonly url_key: TJobListItem["url_key"];
  }) => {
    dispatch(
      setJobHeader({
        title,
        created_at,
      })
    );
    dispatch(setSingleJobUrlKey(url_key));
    const formattedTab = JOB_TABS_KEY.PREMIUM_JOB_BOARDS.split(".")[1];
    navigate(`/jobs/${parseInt(id)}/${formattedTab}`);
  };

  const handleOnPreview = (urlKey: TJobListItem["url_key"]) => {
    window.open(`/job/${urlKey}`, "_blank");
  };

  const handleOnExport = (jobs: TJobListItem | TJobListItem[]) => {
    let csvData = [];
    if (Array.isArray(jobs))
      csvData = jobs.map((job) => ({
        ...job,
        locations: Array.isArray(job.locations) ? job.locations[0]?.city : "",
      }));
    else
      csvData = [
        {
          ...jobs,
          locations: Array.isArray(jobs.locations)
            ? jobs.locations[0].city
            : "",
        },
      ];

    setCsvData(csvData);
    setTimeout(() => {
      CSVLinkRef.current && CSVLinkRef.current.link.click();
    });
  };

  const handleOnCloseDialog = () => {
    setOpenedDialogType(null);
    setJobUrlKeys([]);
  };

  const handleOnArchiveRestore = (jobDetails: TJobListItem) => {
    dispatch(
      setTempJobDetails({
        ...jobDetails,
        is_archived: selectedJobStatus === JOB_STATUSES.ARCHIVED,
      } as unknown as TJobDetails)
    );
    dispatch(setArchiveRestoreDialogOpen(true));
  };

  const handleOnDeleteJob = ({ url_key }: TJobListItem) => {
    setOpenedDialogType(DIALOG_TYPE.DELETE_JOB);
    setJobUrlKeys([url_key]);
  };

  const handleOnConfirmDeleteJob = () => {
    const [jobUrlKey] = jobUrlKeys;
    dispatch(deleteJobs({ jobUrlKey }));
    setJobUrlKeys([]);
  };

  const handleOnPaginate = (pageNum: number) => {
    dispatch(setPageNum(pageNum));
  };

  const handleOnSelect = (job: TJobListItem) => {
    dispatch(setSelectedJobs(job));
  };

  const handleOpenSelectCustomerDialog = () => {
    dispatch(setIsSelectCustomerDialogOpen(true));
  };

  const handleCloseSelectCustomerDialog = () => {
    dispatch(setIsSelectCustomerDialogOpen(false));
  };

  const handleOpenApplyJobDialog = () => {
    setIsApplyJobDialogOpen(true);
  };

  const handleCloseApplyJobDialog = () => {
    setIsApplyJobDialogOpen(false);
  };

  const onFetchDraftDataSuccess = () => {
    dispatch(setUpdateDraftInProgress(true));
  };

  const handleContinueEditing = (job: TJob) => {
    dispatch(setIsDraftImagesLoading(true));
    dispatch(
      fetchDraftData({
        jobUrlKey: job.url_key,
        callback: () => onFetchDraftDataSuccess(),
      })
    );
  };

  const handleOnDuplicate = (job: TJobListItem) => {
    dispatch(setIsDuplicateDialogOpen(true));
    dispatch(setDuplicateJobUrlKey(job.url_key));
  };

  useEffect(() => {
    if (isCandidate !== undefined && isCandidate === false) {
      dispatch(fetchJobs(selectedJobStatus));
    }
  }, [
    selectedJobStatus,
    jobType.value,
    seniority.value,
    quickSearch,
    locations,
    pageNum,
    isCandidate,
    companyName,
  ]);

  useEffect(() => {
    if (!isLoading) {
      handleOnCloseDialog();
      dispatch(setSelectedJobs(null));
    }
  }, [isLoading]);

  useEffect(() => {
    dispatch(setSelectedJobStatus(`${tab}` as JOB_STATUSES));
  }, [tab]);

  useEffect(() => {
    if (pageNum !== 1 && jobCount !== "0" && !modifiedJobList.length) {
      dispatch(setPageNum(pageNum - 1));
    }
  }, [modifiedJobList]);

  const fetchTemplatePhotos = async () => {
    const blobObject = {};
    for (const [key, value] of Object.entries(photoUrls)) {
      if (value) {
        const blob = await downloadTemplateMedia(
          draftData.job.template_id,
          key
        );
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        blobObject[key] = blob;
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        blobObject[key] = null;
      }
    }
    setPhotosBlob(blobObject);
  };

  const handleFormatImagesForRedux = async () => {
    const fileObject = {};
    for (const [key, value] of Object.entries(photosBlob)) {
      if (value) {
        const file = await formatLogoBlobForRedux(value);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const imgBlob = file as Blob;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const imageFile = new File([imgBlob], photoUrls[key]);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        fileObject[key] = imageFile;
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        fileObject[key] = null;
      }
    }
    setTemplatePhotos(fileObject);
  };

  useEffect(() => {
    if (draftData && updateDraftInProgress) {
      const jobDescription = {
        header_1: templatePhotos.header1,
        header_2: templatePhotos.header2,
        header_3: templatePhotos.header3,
        logo: templatePhotos.logoFile,
        background: templatePhotos.backgroundFile,
        company_color: draftData.job.template_title_color,
        background_color: draftData.job.template_background_color,
        job_title: draftData.job.title,
        introduction_title: draftData.job.template_introduction_title,
        introduction: draftData.job.template_introduction,
        tasks_title: draftData.job.template_tasks_title,
        tasks: draftData.job.template_tasks,
        offer_title: draftData.job.template_offer_title,
        offer: draftData.job.template_offer,
        profile_title: draftData.job.template_profile_title,
        profile: draftData.job.template_profile,
        contact_title: draftData.job.template_contact_title,
        contact: draftData.job.template_contact,
        video_url: draftData.job.template_video_url,
        jobUrlKey: draftData.job.url_key,
      };
      dispatch(updateJobDescription({ jobDescription }));

      const filteredJobOwners = draftData.companyMembers.filter((item: any) =>
        draftData.jobOwners.includes(item.id)
      );

      const formattedJobOwners = filteredJobOwners.map((item: any) => ({
        label: `${item.firstname} ${item.lastname}`,
        value: item.id,
      }));

      const emptyLocation = [
        {
          country: "",
          city: "",
          zip: "",
        },
      ];

      const jobDetails = {
        locations: draftData.jobLocations.length
          ? draftData.jobLocations
          : emptyLocation,
        contract_type: draftData.job.contract_type_id,
        field_of_work: draftData.job.work_id,
        seniority: draftData.job.seniority_id,
        position_type: draftData.job.position_id,
        industry: draftData.job.industry_id,
        qualification: draftData.job.qualification_id,
        reference_number: draftData.job.reference_number,
        date_of_publication: new Date(),
        assign_job_to: formattedJobOwners,
        keywords: draftData.job.keywords,
        min_salary: draftData.job.min_salary,
        max_salary: draftData.job.max_salary,
        salary_currency: draftData.job.salary_currency,
        salary_type: draftData.job.salary_type,
      };

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatch(updateJobDetails({ jobDetails }));

      const jobApplicationProcess = {
        phone_required: draftData.job.phone_required,
        location_required: draftData.job.location_required,
        salary_expectation_required: draftData.job.salary_expectation_required,
        earliest_start_date_required:
          draftData.job.earliest_start_date_required,
        driver_license_required: draftData.job.driver_license_required,
        current_professional_status_required:
          draftData.job.current_professional_status_required,
        highest_degree_required: draftData.job.highest_degree_required,
        langs_required: draftData.job.langs_required,
        skills_required: draftData.job.skills_required,
        other_documents_required: draftData.job.other_documents_required,
        questions: draftData.jobQuestions,
      };

      dispatch(updateJobApplicationProcess({ jobApplicationProcess }));

      dispatch(setJobEditorStep(parseInt(draftData.job.draft_stage_step)));
      const completedSteps: number[] =
        draftData.job.draft_stage_step === "3"
          ? [1, 2]
          : draftData.job.draft_stage_step === "2"
          ? [1]
          : [];

      dispatch(updateCompletedStep(completedSteps));
    }
  }, [draftData, updateDraftInProgress, templatePhotos]);

  useEffect(() => {
    if (draftData && updateDraftInProgress) {
      fetchTemplatePhotos();
    }
  }, [draftData, updateDraftInProgress]);

  useEffect(() => {
    if (draftData) {
      dispatch(setIsDraftImagesLoading(false));
      handleFormatImagesForRedux();
    }
  }, [photosBlob]);

  useEffect(() => {
    if (Object.keys(templatePhotos).length) {
      dispatch(setJobDescripitonData(jobDescription));
      if (isAgency) {
        dispatch(setIsSelectCustomerDialogOpen(true));
      } else if (company?.jobs_creation_allowed) {
        dispatch(openJobEditor());
      } else {
        dispatch(setIsLimitDialogOpen(true));
      }
    }
  }, [templatePhotos]);

  const handleOnInviteTeamMember = (data: TJobListItem) => {
    dispatch(setTempJobDetails(data as unknown as TJobDetails));
    dispatch(setIsInviteMemberDialogOpen(true));
  };

  useEffect(
    () => () => {
      dispatch(resetJobsFilters());
    },
    []
  );

  return (
    <>
      <CSVLink
        data={csvData || []}
        className="hidden"
        ref={CSVLinkRef}
        filename={csvData?.[0]?.title || "Job"}
      />

      <JobListComponent
        t={t}
        jobCount={jobCount}
        jobList={modifiedJobList}
        jobStatuses={jobsStatuses}
        selectedJobStatus={selectedJobStatus}
        openedDialogType={openedDialogType}
        isLoading={isLoading}
        isListLoading={isListLoading}
        pageNum={pageNum}
        pageSize={pageSize}
        isAgency={isAgency as any}
        isApplyJobDialogOpen={isApplyJobDialogOpen}
        onPaginate={handleOnPaginate}
        onCloseDialog={handleOnCloseDialog}
        onConfirmDeleteJob={handleOnConfirmDeleteJob}
        onOpenCustomerDialog={handleOpenSelectCustomerDialog}
        onCloseCustomerDialog={handleCloseSelectCustomerDialog}
        onTabChange={handleOnChangeTab}
        onOpenApplyJobDialog={handleOpenApplyJobDialog}
        onCloseApplyJobDialog={handleCloseApplyJobDialog}
        onSelect={
          selectedJobStatus === JOB_STATUSES.ACTIVE ? handleOnSelect : undefined
        }
        actions={{
          onArchiveRestore: handleOnArchiveRestore,
          onClick: handleOnClick,
          onContinueEditing: handleContinueEditing,
          onClickApplicationsNumber: handleOnClickApplicationsNumber,
          onDelete:
            selectedJobStatus === JOB_STATUSES.ARCHIVED
              ? handleOnDeleteJob
              : undefined,
          onDuplicate: handleOnDuplicate,
          onInvite: handleOnInviteTeamMember,
          onEdit: handleOnEdit,
          onExport: handleOnExport,
          onPreview: handleOnPreview,
          onPromote: handleOnPromote,
        }}
      />
    </>
  );
};

export default JobList_new;
