import { TJobPreviewJob } from "../models/JobPreview";
import { employmentTypes, getTextBetweenBrackets } from "./index";
import moment from "moment/moment";
import { TJobLocation } from "../models/SingleJob";
import { TCompanySettingsCompany } from "../models/CompanySettings";

const formattedSalaryType = (jobTemplate: TJobPreviewJob | null) =>
  jobTemplate?.salary_type === "monthly"
    ? "MONTH"
    : jobTemplate?.salary_type === "annually"
    ? "YEAR"
    : "";

export const formatPositionType = (positionTypeId: string) => {
  const job = jobTypeSchema.find((item) => item.id === positionTypeId);
  if (job) return job.type;
  return employmentTypes.OTHER;
};

export const jobTypeSchema = [
  {
    id: "7",
    title: "Aufsichtsratmitglied",
    type: employmentTypes.OTHER
  },
  {
    id: "14",
    title: "Ausbildung",
    type: employmentTypes.OTHER
  },
  {
    id: "11",
    title: "Aushilfe",
    type: employmentTypes.PART_TIME
  },
  {
    id: "17",
    title: "Befristet/Saisonal",
    type: employmentTypes.TEMPORARY
  },
  {
    id: "18",
    title: "Duales Studium",
    type: employmentTypes.OTHER
  },
  {
    id: "8",
    title: "Ehrenamtlich",
    type: employmentTypes.OTHER
  },
  {
    id: "5",
    title: "Eigentümer",
    type: employmentTypes.OTHER
  },
  {
    id: "4",
    title: "Freelancer",
    type: employmentTypes.CONTRACTOR
  },
  {
    id: "15",
    title: "Geringfügig",
    type: employmentTypes.PART_TIME
  },
  {
    id: "6",
    title: "Partner",
    type: employmentTypes.OTHER
  },
  {
    id: "3",
    title: "Praktikum",
    type: employmentTypes.INTERN
  },
  {
    id: "12",
    title: "Soziales Engagement",
    type: employmentTypes.VOLUNTEER
  },
  {
    id: "2",
    title: "Teilzeit",
    type: employmentTypes.PART_TIME
  },
  {
    id: "9",
    title: "Teilzeit / Vollzeit",
    type: [employmentTypes.FULL_TIME, employmentTypes.PART_TIME]
  },
  {
    id: "10",
    title: "Traineeship",
    type: employmentTypes.OTHER
  },
  {
    id: "1",
    title: "Vollzeit",
    type: employmentTypes.FULL_TIME
  },
  {
    id: "16",
    title: "Werkstudent",
    type: employmentTypes.INTERN
  }
];

const googleDescription = (jobTemplate: TJobPreviewJob) =>
  jobTemplate?.template_introduction_title +
  " " +
  jobTemplate?.template_introduction +
  " " +
  jobTemplate?.template_tasks_title +
  " " +
  jobTemplate?.template_tasks +
  " " +
  jobTemplate?.template_profile_title +
  " " +
  jobTemplate?.template_profile +
  " " +
  jobTemplate?.template_offer_title +
  " " +
  jobTemplate?.template_offer +
  " " +
  jobTemplate?.template_contact_title +
  " " +
  jobTemplate?.template_contact;

export const getJobPostingFormat = ({
  jobTemplate,
  jobLocations,
  company
}: {
  jobTemplate: TJobPreviewJob | null;
  jobLocations: TJobLocation[];
  company: TCompanySettingsCompany | null;
}) => ({
  "@context": "https://schema.org/",
  "@type": "JobPosting",
  title: jobTemplate?.title || "",
  description: googleDescription || "",
  identifier: {
    "@type": "PropertyValue",
    name: jobTemplate?.company,
    value: jobTemplate?.id
  },
  datePosted: moment(jobTemplate?.created_at).format("YYYY-MM-DD"),
  validThrough: moment(jobTemplate?.created_at)
    .add(30, "days")
    .calendar(),
  employmentType: jobTemplate?.position_id
    ? formatPositionType(jobTemplate?.position_id)
    : "OTHER",
  hiringOrganization: {
    "@type": "Organization",
    name: jobTemplate?.company || "",
    sameAs: company?.website || "",
    logo: company?.logo || ""
  },
  jobLocation: {
    "@type": "Place",
    address: {
      "@type": "PostalAddress",
      streetAddress: jobLocations ? jobLocations[0]?.city : "",
      addressLocality: jobLocations ? jobLocations[0]?.city : "",
      addressRegion: jobLocations ? jobLocations[0]?.city : "",
      postalCode: jobLocations ? jobLocations[0]?.zip : "",
      addressCountry: jobLocations ? jobLocations[0]?.country : ""
    }
  },
  baseSalary: {
    "@type": "MonetaryAmount",
    currency: getTextBetweenBrackets(jobTemplate?.salary_currency) || "",
    value: {
      "@type": "QuantitativeValue",
      minValue: jobTemplate?.min_salary,
      maxValue: jobTemplate?.max_salary,
      unitText: formattedSalaryType(jobTemplate)
    }
  }
}); // TODO review/refactor jobPostingFormat
