import { Box, Stack } from "@mui/material";
import { styled } from "@mui/system";

export const StyledTeamsContainer = styled(Box)({
  marginTop: 24,
  display: "flex",
  flexDirection: "column",
  height: "100%",
  button: { border: "none" },
});

export const StyledTeamsTabsContainer = styled(Box)({
  borderBottom: "1px solid #E5E7EB",
  display: "flex",
  maxHeight: 41,
  button: {
    textTransform: "capitalize",
  },
});

export const StyledTeamsToolbarContainer = styled(Stack)({
  padding: "16px 0",
  justifyContent: "space-between",
  ".MuiFormControl-root": {
    maxWidth: "30%",
  },
});

export const StyledNoResultContainer = styled(Stack)({
  height: "100%",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  img: {
    width: 205,
    height: 200,
  },
});
