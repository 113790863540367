import React, { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useFieldArray, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import JobDetailsFormComponent from "./component";
import {
  IJobDetailsForm,
  TJobDetailsFormFields,
} from "../../../models/JobEditor";
import { SELECTED_EDIT_SECTIONS } from "../../../models/SingleJob";
import { getJobEditorJobDataState } from "../../../store_new/selectors/JobEditor";
import {
  getJobDetailsFormFields,
  jobDetailsFormDefaultValues,
} from "../config";

const JobDetailsForm = ({
  t,
  defaultValues,
  formRef,
  selectedSections = [
    SELECTED_EDIT_SECTIONS.JOB_DETAILS_LOCATIONS,
    SELECTED_EDIT_SECTIONS.JOB_DETAILS_GENERAL_INFO,
    SELECTED_EDIT_SECTIONS.JOB_DETAILS_ADDITIONAL_INFO,
    SELECTED_EDIT_SECTIONS.JOB_DETAILS_SALARY,
  ],
  onSubmit,
  onChangeForm,
}: IJobDetailsForm) => {
  const {
    control,
    watch,
    register,
    getValues,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(getJobDetailsFormFields(t)),
    defaultValues: defaultValues || jobDetailsFormDefaultValues,
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "locations",
  });
  const {
    countries,
    contractTypes,
    workFields,
    positionTypes,
    seniorities: seniority,
    industriesList: industries,
    qualificationsList: qualifications,
    salaryCurrencies,
    salaryTypes,
    companyMembers,
  } = useSelector(getJobEditorJobDataState);

  const formatedCountries = countries.map((item: any) => ({
    label: item.label.Label,
    value: item.label.Id,
  }));

  useEffect(() => {
    if (onChangeForm) {
      const subscription = watch((value) => {
        onChangeForm(value as TJobDetailsFormFields);
      });
      return () => subscription.unsubscribe();
    }
  }, [watch]);

  const handleOnSubmit = (data: TJobDetailsFormFields) => {
    onSubmit(data);
  };

  const handleOnAddLocation = () => {
    append({ city: "", country: "", zip: "" });
  };

  const handleOnRemoveLocation = (index: number) => {
    remove(index);
  };

  useEffect(() => {
    if (!getValues().min_salary) {
      setValue("min_salary", "");
    }
    if (!getValues().max_salary) {
      setValue("max_salary", "");
    }
  }, []);

  useEffect(() => {
    // TODO fix for location issue, will remove after fix
    if (!getValues().locations.length) {
      append({ city: "", country: "", zip: "" });
    }
  }, []);

  return (
    <JobDetailsFormComponent
      t={t}
      register={register}
      errors={errors}
      control={control}
      locations={fields}
      countries={formatedCountries}
      contractTypes={contractTypes}
      workFields={workFields}
      positionTypes={positionTypes}
      seniority={seniority}
      industries={industries}
      qualifications={qualifications}
      salaryCurrencies={salaryCurrencies}
      salaryTypes={salaryTypes}
      companyMembers={companyMembers}
      formRef={formRef}
      selectedSections={selectedSections}
      onAddLocation={handleOnAddLocation}
      onRemoveLocation={handleOnRemoveLocation}
      onSubmit={handleOnSubmit}
      handleSubmit={handleSubmit}
    />
  );
};

export default JobDetailsForm;
