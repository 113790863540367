import React, { useEffect } from "react";
import DashboardComponent from "./component";
import { useDispatch, useSelector } from "react-redux";
import { fetchJobs } from "../../store_new/reducers/JobList";
import { JOB_STATUSES } from "../../models/JobList";
import { fetchApplications } from "../../store_new/reducers/Applications";
import { getCurrentUserData } from "../../store_new/selectors/CurrentUser";

const Dashboard = () => {
  const dispatch = useDispatch();

  const { isAgency } = useSelector(getCurrentUserData);

  useEffect(() => {
    dispatch(fetchJobs(JOB_STATUSES.ACTIVE));
    dispatch(fetchApplications());
  }, []);

  return <DashboardComponent isAgency={Boolean(isAgency)} />;
};

export default Dashboard;
