import React, { useEffect, useState } from "react";
import moment from "moment";
import { DragDropContext } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import Columns from "./Columns";
import FloatingMenu from "../FloatingMenu";
import { IKanbanComponent, ICandidateItem } from "../../models/Kanban";
import {
  getColumnItem,
  removeSelectedItemsFromColumn,
  generateStatusFromColumnId,
  onDragEnd,
  generateGridTemplateColumns,
} from "../../utils";
import { StyledColumns } from "./style";
import { deleteCandidate } from "../../store_new/reducers/TalentPools";
import { getTalentPoolState } from "../../store_new/selectors/Talentpool";
import { ITalentPool } from "../../models/TalentPools";
import { fetchApplications } from "../../store_new/reducers/Applications";

const KanbanBoard = ({
  t,
  initialColumns,
  statuses,
  variant,
  onChangeStatus,
  onItemClick,
}: IKanbanComponent) => {
  const dispatch = useDispatch();

  const [columns, setColumns] = useState([]);
  const [selectedItems, setSelectedItems] = useState<ICandidateItem[] | []>([]);
  const { talentPools } = useSelector(getTalentPoolState);

  const changeStatus = (
    draggableId: string,
    droppableId: string,
    index: string
  ) => {
    const newStatus = generateStatusFromColumnId(droppableId);
    onChangeStatus(draggableId, newStatus, index);
  };

  const handleMoveSelectedItems = (status: string) => {
    const removedItemsFromColumns = removeSelectedItemsFromColumn(
      columns,
      selectedItems
    );
    const key = getColumnItem(status, columns) as keyof typeof columns;
    const formatedItemsStatus = selectedItems.map((item) => ({
      ...item,
      status,
      updated_at: moment(),
    }));
    setColumns((prevColumns) => {
      const updatedColumns: any = { ...prevColumns };
      const finishedColumns = {
        ...removedItemsFromColumns,
        [key]: {
          color: updatedColumns[key]?.color,
          columnId: status,
          title: updatedColumns[key]?.title,
          items: updatedColumns[key]?.items
            ? [...formatedItemsStatus, ...updatedColumns[key].items]
            : formatedItemsStatus,
        },
      };
      return finishedColumns;
    });
    selectedItems.map((item) => {
      onChangeStatus(item.id, status, "0");
    });

    handleDeselectAll();
  };

  const handleSelectItem = (
    event: React.MouseEvent<HTMLButtonElement>,
    newItem: ICandidateItem
  ) => {
    let updatedItems: ICandidateItem[] = [];
    if (selectedItems.findIndex((item) => item.id === newItem.id) > -1) {
      updatedItems = selectedItems?.filter((item) => item.id !== newItem.id);
    } else updatedItems = [...selectedItems, newItem];
    setSelectedItems(updatedItems);
  };

  const handleDeselectAll = () => {
    setSelectedItems([]);
  };

  const gridTemplateColumns = generateGridTemplateColumns(columns);

  useEffect(() => {
    setColumns(initialColumns as any);
  }, [initialColumns]);

  useEffect(() => {
    const allTalentPoolItems = [];
    for (const key in columns) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const items = columns[key].items;
      const filteredItems = items.filter(
        (item: any) => item.is_talentpooled == 1 && item.status !== "rejected"
      );
      allTalentPoolItems.push(...filteredItems);
    }
    allTalentPoolItems.forEach((candidate: ICandidateItem) => {
      const findTalentPool = talentPools.find(
        (item: ITalentPool) => item.title === candidate?.talentpool_title
      );
      if (findTalentPool?.id) {
        dispatch(
          deleteCandidate({
            id: findTalentPool?.id as number,
            applicationId: candidate.application_id as unknown as number,
            callback: () => {
              dispatch(fetchApplications({ withoutLoader: true }));
            },
          })
        );
      }
    });
  }, [columns]);

  return (
    <>
      <StyledColumns gridTemplateColumns={gridTemplateColumns}>
        <DragDropContext
          onDragEnd={(result) =>
            onDragEnd(result, columns, setColumns, changeStatus)
          }
        >
          <Columns
            t={t}
            columns={Object.values(columns)}
            variant={variant}
            selectedItems={selectedItems as []}
            handleSelectItem={handleSelectItem}
            onItemClick={onItemClick}
          />
        </DragDropContext>
      </StyledColumns>
      {selectedItems.length > 0 && (
        <FloatingMenu
          t={t}
          items={selectedItems}
          statuses={statuses}
          handleDeselectAll={handleDeselectAll}
          handleMoveAll={handleMoveSelectedItems}
        />
      )}
    </>
  );
};

export default KanbanBoard;
