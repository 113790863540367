import { InputLabel, Stack } from "@mui/material";
import React from "react";
import { IApplicationAdditionalInfoQuestions } from "../../../models/ApplicationPage";
import { StyledQuestionTitle, StyledTextField } from "../styles";
import { TJobDetails } from "../../../models/SingleJob";
import IconWithTooltip from "./IconWithTooltip";

const Questions = ({
  t,
  questions,
  jobDetails,
}: IApplicationAdditionalInfoQuestions) => (
  <Stack spacing={2}>
    {questions.map((item) => (
      <Stack key={item.label ? item.label : (item.labelKey as string)}>
        <StyledQuestionTitle direction={"row"}>
          <InputLabel>
            {item.label ? item.label : t(item.labelKey as string)}
          </InputLabel>
          {jobDetails?.[`${item.type}_required` as keyof TJobDetails] ? (
            <IconWithTooltip />
          ) : null}
        </StyledQuestionTitle>
        <StyledTextField
          value={
            item.isTypeYesNo
              ? t(item.value.toString().toLowerCase()) ||
                t("candidate_questions.no_answer")
              : item.value && item.value != "Invalid date"
              ? item.value
              : t("candidate_questions.no_answer")
          }
          disabled
        />
      </Stack>
    ))}
  </Stack>
);

export default Questions;
