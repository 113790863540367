import React, { MouseEvent, useMemo, useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import { useParams } from "react-router-dom";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { IDataGridStatusSelectorCell } from "../../../models/DataGrid";
import { StyledStatusChip } from "../styles";
import {
  getCurrentUserData,
  getStatuses,
} from "../../../store_new/selectors/CurrentUser";
import { getTalentPoolState } from "../../../store_new/selectors/Talentpool";
import {
  addCandidateInTalentPool,
  fetchTalentPoolCandidates,
} from "../../../store_new/reducers/TalentPools";

import { getCurrentApplication } from "../../../store_new/selectors/Applications";
import { addMessage } from "../../../slices/NotificationSlice/GlobalNotificationSlice";
import TalentPoolPopover from "../../TalentPoolPopover";
import {
  setIsLimitDialogOpen,
  setIsTalentPoolsDialogOpen,
} from "../../../store_new/reducers/dialogs";
import { DIALOG_TYPE } from "../../../models/common";
import {
  fetchApplications,
  fetchSelectedJobApplications,
  updateApplicationStatus,
} from "../../../store_new/reducers/Applications";
import { getCompanySettingsCompanyState } from "../../../store_new/selectors/Company";
import { TCandidateApplication } from "../../../models/ApplicationPage";

const StatusSelectorCell = ({
  label,
  type,
  params,
  onSelect,
}: IDataGridStatusSelectorCell) => {
  const dispatch = useDispatch();
  const { job_id, key } = useParams();
  const { t } = useTranslation();
  const statuses = useSelector(getStatuses);
  const currentApplication = useSelector(getCurrentApplication);
  const { isAgency } = useSelector(getCurrentUserData);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [talentPoolsAnchorEl, setTalentPoolsAnchorEl] =
    useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const talentPoolMenuOpen = Boolean(talentPoolsAnchorEl);
  const id = talentPoolMenuOpen ? "simple-popover" : undefined;
  const [isTalentPoolMenuHovered, setIsTalentPoolMenuHovered] =
    useState<boolean>(false);

  const { talentPools } = useSelector(getTalentPoolState);
  const { company } = useSelector(getCompanySettingsCompanyState);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleTalentPoolClick = (event: MouseEvent<HTMLLIElement>) => {
    setTalentPoolsAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setTalentPoolsAnchorEl(null);
  };

  const selectedStatus = useMemo(
    () =>
      statuses.find(({ value }) => {
        return value === label;
      })?.label || "",
    [statuses, label]
  );

  const handleOnClickMenuItem = (newStatus: string) => {
    onSelect && onSelect(newStatus);
    handleClose();
  };

  const onSuccessChangeApplicationStatus = () => {
    if (key && job_id) {
      dispatch(
        fetchSelectedJobApplications({
          candidateUrlKey: key,
          job_id: job_id,
        })
      );
    } else {
      dispatch(
        fetchApplications({
          withoutLoader: true,
        })
      );
    }
  };

  const onSuccessAddCandidateInTalentPool = (
    id: number,
    applications: TCandidateApplication
  ) => {
    dispatch(fetchTalentPoolCandidates(id));
    dispatch(
      addMessage({
        type: "success",
        title: t("talentPools.successCandidateAddedInTalentPool"),
      })
    );
    dispatch(
      updateApplicationStatus({
        applicationId: applications?.application_id as string,
        newStatus: "rejected",
        index: "0",
        callback: () => onSuccessChangeApplicationStatus(),
      })
    );
  };

  const handleOnClickTalentPoolMenuItem = (
    id: number,
    applications: TCandidateApplication
  ) => {
    dispatch(
      addCandidateInTalentPool({
        id,
        applications,
        callback: () => onSuccessAddCandidateInTalentPool(id, applications),
      })
    );
    setTalentPoolsAnchorEl(null);
  };

  const handleCloseTalentPoolMenu = () => {
    if (!isTalentPoolMenuHovered) {
      setTalentPoolsAnchorEl(null);
    }
  };

  const handleOnTalentPoolHoverLeave = (hover: boolean) => {
    setIsTalentPoolMenuHovered(hover);
  };

  const handleOnOpenTalentPoolDialog = () => {
    if (company?.talentpools_creation_allowed) {
      dispatch(setIsTalentPoolsDialogOpen(DIALOG_TYPE.CREATE_EDIT_TALENT_POOL));
    } else {
      dispatch(setIsLimitDialogOpen(true));
    }
  };

  return (
    <>
      <StyledStatusChip
        label={selectedStatus === "Hired" ? "Hire" : selectedStatus}
        type={type}
        onDelete={onSelect ? handleClick : undefined}
        className={"status-selector"}
        deleteIcon={<ChevronDownIcon className={"status-selector"} />}
      />
      <Menu
        id="actions-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "actions-button",
        }}
      >
        {statuses.map(
          (status: { value: string; label: string }) =>
            type !== status.value && (
              <MenuItem
                key={status.value}
                onClick={() => {
                  handleOnClickMenuItem(status.value);
                }}
              >
                {status.label === "Hired" ? "Hire" : status.label}
              </MenuItem>
            )
        )}
        {!isAgency && (
          <MenuItem
            aria-controls={talentPoolMenuOpen ? "actions-menu" : undefined}
            onClick={handleTalentPoolClick}
          >
            {t("talentPools.addToTalentPool")}
          </MenuItem>
        )}
      </Menu>
      <TalentPoolPopover
        t={t}
        id={id}
        onCloseTalentPoolMenu={handleCloseTalentPoolMenu}
        open={talentPoolMenuOpen}
        anchorEl={talentPoolsAnchorEl}
        jobId={job_id}
        talentPools={talentPools}
        params={params}
        currentApplication={currentApplication}
        onTalentPoolHoverLeave={handleOnTalentPoolHoverLeave}
        onClickTalentPoolMenuItem={handleOnClickTalentPoolMenuItem}
        onOpenCreateTalentPoolDialog={handleOnOpenTalentPoolDialog}
      />
    </>
  );
};

export default StatusSelectorCell;
