import React from "react";
import { MenuItem, Typography } from "@mui/material";
import { IStatusMenuComponent } from "../../models/Kanban";
import { StyledStatusMenu } from "./style";

const StatusMenu = ({
  t,
  anchorEl,
  items,
  talentPoolMenuOpen,
  isAgency,
  onTalentPoolMenuClick,
  handleMoveAll,
  closeStatusMenu,
}: IStatusMenuComponent) => (
  <StyledStatusMenu
    anchorEl={anchorEl}
    keepMounted
    open={Boolean(anchorEl)}
    onClose={closeStatusMenu}
    transformOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
  >
    {items.map((item: any, index: number) => (
      <MenuItem key={index} onClick={() => handleMoveAll(item.const)}>
        <Typography variant="subtitle2">{item.title}</Typography>
      </MenuItem>
    ))}
    {!isAgency && (
      <MenuItem
        aria-controls={talentPoolMenuOpen ? "actions-menu" : undefined}
        onClick={onTalentPoolMenuClick}
      >
        {t("talentPools.addToTalentPool")}
      </MenuItem>
    )}
  </StyledStatusMenu>
);

export default StatusMenu;
