import React, { useEffect } from "react";
import { TFunction } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  IApplicationsState,
  TApplicationsFilters,
} from "../../../models/Applications";
import {
  fetchAllApplications,
  resetApplicationsFilters,
  setApplicationFilters,
  setView,
} from "../../../store_new/reducers/Applications";
import {
  getAllApplications,
  getApplicationsFilters,
} from "../../../store_new/selectors/Applications";
import ApplicationsFiltersComponent from "./component";
import {
  getCurrentUserData,
  getStatuses,
} from "../../../store_new/selectors/CurrentUser";
import { getAllActiveJobs } from "../../../store_new/selectors/JobList";
import { TListOption } from "../../../models/common";
import { getCustomersListState } from "../../../store_new/selectors/Customers";
import { fetchCustomers } from "../../../store_new/reducers/Customers";

const ApplicationsFilters = ({
  t,
  view,
  jobId,
  onOpenApplyJobDialog,
}: {
  readonly t: TFunction;
  readonly view: IApplicationsState["view"];
  readonly jobId: string | null;
  readonly onOpenApplyJobDialog: () => void;
}) => {
  const dispatch = useDispatch();

  const allApplications = useSelector(getAllApplications);
  const statuses = useSelector(getStatuses);
  const allJobs = useSelector(getAllActiveJobs);

  const { appliedTo, status, quickSearch, location, company } = useSelector(
    getApplicationsFilters
  );
  const { isAgency } = useSelector(getCurrentUserData);
  const { list } = useSelector(getCustomersListState);

  const formattedAllActiveJobs = allJobs?.map((item) => ({
    label: item.title,
    value: item.id,
  }));

  const singleJobLocations = allApplications?.filter(
    (item) => item.job_id == jobId
  );

  const formattedSingleJobLocations = singleJobLocations?.map((item) => {
    if (item.location) {
      return {
        label: item.location,
        value: item.location,
      };
    }
  });

  const formattedAllLocations = allApplications?.map((item) => {
    if (item.location) {
      return {
        label: item.location,
        value: item.location,
      };
    }
  });

  const formattedLocations = jobId
    ? formattedSingleJobLocations
    : formattedAllLocations;

  const formattedAllLocationsWithoutDuplicate = formattedLocations?.filter(
    (v, i, a) =>
      v !== undefined &&
      a.findIndex((v2) => JSON.stringify(v2) === JSON.stringify(v)) === i
  );

  const formattedStatus = {
    value: status.value,
    options: statuses,
  };

  const formattedCustomers = list?.map((item) => ({
    label: item.companyName,
    value: item.id,
  }));

  const handleOnChangeFilter = ({
    value,
    filterType,
  }: {
    readonly value: string;
    readonly filterType: keyof TApplicationsFilters;
  }) => {
    dispatch(setApplicationFilters({ filterType, value }));
  };

  const handleOnChangeView = (view: IApplicationsState["view"]) => {
    dispatch(setView(view));
    localStorage.setItem("view", view);
  };

  useEffect(() => {
    if (!allApplications) {
      dispatch(fetchAllApplications());
    }
    if (isAgency) {
      dispatch(fetchCustomers());
    }
    return () => {
      dispatch(resetApplicationsFilters());
    };
  }, []);

  return (
    <ApplicationsFiltersComponent
      jobTypeFilter={appliedTo}
      stateFilter={formattedStatus}
      t={t}
      jobId={jobId}
      quickSearch={quickSearch}
      location={location}
      allJobs={formattedAllActiveJobs}
      isAgency={isAgency}
      companyFilter={company}
      allLocations={formattedAllLocationsWithoutDuplicate as TListOption[]}
      customers={formattedCustomers as TListOption[]}
      view={view}
      onChangeFilters={handleOnChangeFilter}
      onOpenApplyJobDialog={onOpenApplyJobDialog}
      onChangeView={handleOnChangeView}
    />
  );
};

export default ApplicationsFilters;
