import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import SingleJobComponent from "./component";
import Loading from "../../components/Loading";
import { JOB_TABS_KEY } from "../../models/SingleJob";
import { setCartOpen } from "../../slices/Job/jobSlice";
import {
  fetchJobDetails,
  resetJobDetailsAndBookings,
  resetSingleJobOwners,
  setJobId,
  setSelectedJobTab,
} from "../../store_new/reducers/SingleJob";
import {
  getSingleJobNavigationState,
  getSingleJobState,
} from "../../store_new/selectors/SingleJob";
import { getCurrentUserData } from "../../store_new/selectors/CurrentUser";
import { setJoinedPaginationSize } from "../../store_new/reducers/Team";
import { getSingleJobUrlKey } from "../../store_new/selectors/Applications";
import { resetSingleJobUrlKey } from "../../store_new/reducers/Applications";

const SingleJob_new = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const jobUrlKey = useSelector(getSingleJobUrlKey);
  const { applicationsAllowed, isAgency } = useSelector(getCurrentUserData);
  const { jobId, jobDetails } = useSelector(getSingleJobState);
  const { jobTabs, selectedJobTab } = useSelector(getSingleJobNavigationState);
  const { key: id, tab } = useParams();

  const agencyTabs = jobTabs.filter(
    (item) => item !== JOB_TABS_KEY.APPLICATIONS
  );

  const tabs = applicationsAllowed || !isAgency ? jobTabs : agencyTabs;

  const handleOnChangeTab = (_: React.SyntheticEvent, value: JOB_TABS_KEY) => {
    dispatch(setSelectedJobTab(value));
    const formattedTab = value.split(".")[1];
    if (id) navigate(`/jobs/${parseInt(id)}/${formattedTab}`);
  };

  const handleOnOpenCart = () => {
    dispatch(setCartOpen(true));
  };

  useEffect(() => {
    if (!jobId && id) {
      dispatch(setJobId({ jobId: id }));
    }
  }, []);

  useEffect(() => {
    dispatch(setSelectedJobTab(`single_job.${tab}` as JOB_TABS_KEY));
  }, [tab]);

  useEffect(() => {
    return () => {
      dispatch(setJobId({ jobId: null }));
      dispatch(resetJobDetailsAndBookings());
      dispatch(resetSingleJobUrlKey());
    };
  }, []);

  useEffect(() => {
    return () => {
      dispatch(setJoinedPaginationSize(10));
      dispatch(resetSingleJobOwners());
    };
  }, []);

  useEffect(() => {
    if (jobUrlKey && !jobDetails) {
      dispatch(
        fetchJobDetails({
          jobUrlKey,
        })
      );
    }
  }, [jobUrlKey]);

  return jobId ? (
    <SingleJobComponent
      t={t}
      jobTabs={tabs}
      selectedJobTab={selectedJobTab}
      onTabChange={handleOnChangeTab}
      onOpenCart={handleOnOpenCart}
    />
  ) : (
    <Loading />
  );
};

export default SingleJob_new;
