import moment from "moment";
import { JOB_STATUSES } from "../models/JobList";
import {
  TBasicJobBoard,
  TJobDetails,
  TSingleJobCompany,
} from "../models/SingleJob";

const daysLimit = 30;

const getRemainingDays = ({
  company,
  jobDetails,
  jobBoardTitle,
}: {
  company: TSingleJobCompany | null;
  jobDetails: TJobDetails | null;
  jobBoardTitle: string;
}) => {
  const daysToNow = moment().diff(jobDetails?.created_at, "days");
  const remainingDays = daysLimit - daysToNow;

  if (jobBoardTitle === "Linkedin" && !company?.linkedin_url) return 0;
  if (jobBoardTitle === "Onlyfy" && !company?.xing_url) return 0;
  if (jobBoardTitle === "Kleinanzeigen.de" && !jobDetails?.header_1) return 0;
  if (remainingDays && remainingDays > 0) return remainingDays;

  return 0;
};

const getStatus = ({
  company,
  jobDetails,
  jobBoardTitle,
}: {
  company: TSingleJobCompany | null;
  jobDetails: TJobDetails | null;
  jobBoardTitle: string;
}) => {
  const remainingDays = getRemainingDays({
    company,
    jobDetails,
    jobBoardTitle,
  });
  if (jobBoardTitle === "Linkedin" && !company?.linkedin_url)
    return JOB_STATUSES.INACTIVE;
  if (jobBoardTitle === "Onlyfy" && !company?.xing_url)
    return JOB_STATUSES.INACTIVE;
  if (jobBoardTitle === "Kleinanzeigen.de" && !jobDetails?.header_1)
    return JOB_STATUSES.INACTIVE;
  if (remainingDays > 0) return JOB_STATUSES.ACTIVE;

  return JOB_STATUSES.INACTIVE;
};

const getHasConnectAction = ({
  company,
  jobBoardTitle,
}: {
  company: TSingleJobCompany | null;
  jobBoardTitle: string;
}) => {
  if (jobBoardTitle === "Linkedin" && !company?.linkedin_url) return true;
  return jobBoardTitle === "Onlyfy" && !company?.xing_url;
};

const getHasUploadAction = ({
  jobDetails,
  jobBoardTitle,
}: {
  jobDetails: TJobDetails | null;
  jobBoardTitle: string;
}) => {
  return jobBoardTitle === "Kleinanzeigen.de" && !jobDetails?.header_1;
};

export const getModifiedJobBoards = ({
  company,
  jobDetails,
  basicJobBoards,
}: {
  company: TSingleJobCompany | null;
  jobDetails: TJobDetails | null;
  basicJobBoards: TBasicJobBoard[];
}) =>
  basicJobBoards.map((jobBoard: TBasicJobBoard) => ({
    ...jobBoard,
    remainingDays: getRemainingDays({
      company,
      jobDetails,
      jobBoardTitle: jobBoard.title,
    }),
    status: getStatus({ company, jobDetails, jobBoardTitle: jobBoard.title }),
    hasConnectAction: getHasConnectAction({
      company,
      jobBoardTitle: jobBoard.title,
    }),
    hasUploadAction: getHasUploadAction({
      jobDetails,
      jobBoardTitle: jobBoard.title,
    }),
  }));
