import React from "react";
import {
  StyledHeaderSubtitle,
  StyledHeaderTitle,
  StyledHeaderTitleContainer,
  StyledStatusContainer,
} from "../styles";
import { Box, Skeleton, Stack } from "@mui/material";
import { ArrowLeftIcon, EyeIcon } from "@heroicons/react/24/outline";
import DeleteDialog from "../../dialogs/DeleteDialog";
import { StyledStatusChip } from "../../DataGrid_new/styles";
import { ISingleApplicationCandidateHeaderComponent } from "../../../models/TopHeader";
import { openUrlnNewTab } from "../../../utils";

const SingleApplicationHeaderComponent = ({
  t,
  title,
  subtitle,
  status,
  targetJob,
  isLoading,
  isOpen,
  desktop,
  company,
  onOpenCloseDialog,
  onDeleteApplication,
  onNavigateBack,
}: ISingleApplicationCandidateHeaderComponent) => (
  <Stack width={desktop ? "auto" : "85vw"} pt={desktop ? 0 : 2}>
    <StyledHeaderTitleContainer
      direction={"row"}
      spacing={1}
      maxHeight={desktop ? 30 : "auto !important"}
    >
      {desktop && <ArrowLeftIcon onClick={onNavigateBack} />}
      {isLoading ? (
        <Skeleton variant={"rounded"} width={200} height={30} />
      ) : desktop ? (
        <StyledHeaderTitle desktop={desktop} direction={"row"} spacing={2}>
          <Box width="100%" overflow="hidden" textOverflow="ellipsis">
            {title}
          </Box>
          <Stack
            height={28}
            width={28}
            p={0.5}
            alignItems="center"
            justifyContent="center"
            border="1px solid #178CF2"
            borderRadius="6px"
            sx={{ cursor: "pointer" }}
            onClick={() => openUrlnNewTab(`/job/${targetJob}`)}
          >
            <EyeIcon stroke="#178CF2" />
          </Stack>
          {isLoading ? (
            <Skeleton variant={"rounded"} width={60} height={30} />
          ) : (
            <StyledStatusContainer direction={"row"}>
              {status && <StyledStatusChip type={status} label={status} />}
            </StyledStatusContainer>
          )}
        </StyledHeaderTitle>
      ) : (
        <Stack width="100%" spacing={1.5}>
          <Stack width="100%" direction="row">
            <StyledHeaderTitle
              desktop={desktop}
              direction={"row"}
              height={"auto"}
              spacing={3}
              alignItems="flex-start !important"
            >
              {!desktop && (
                <Stack>
                  <ArrowLeftIcon
                    width={24}
                    height={24}
                    stroke="#131E30"
                    onClick={onNavigateBack}
                  />
                </Stack>
              )}
              <span className="two-line-title">{title}</span>
            </StyledHeaderTitle>
          </Stack>
          <Stack direction="row" spacing={1}>
            <Stack
              height={28}
              width={28}
              p={0.5}
              alignItems="center"
              justifyContent="center"
              border="1px solid #178CF2"
              borderRadius="6px"
              sx={{ cursor: "pointer" }}
              onClick={() => openUrlnNewTab(`/job/${targetJob}`)}
            >
              <EyeIcon stroke="#178CF2" />
            </Stack>
            {isLoading ? (
              <Skeleton variant={"rounded"} width={60} height={30} />
            ) : (
              <StyledStatusContainer direction={"row"}>
                {status && <StyledStatusChip type={status} label={status} />}
              </StyledStatusContainer>
            )}
          </Stack>
        </Stack>
      )}
    </StyledHeaderTitleContainer>
    <Stack pl={desktop ? 5 : 0} pt={desktop ? 0.25 : 4.5}>
      {isLoading ? (
        <Skeleton variant={"rounded"} width={100} height={20} />
      ) : desktop ? (
        <StyledHeaderSubtitle direction="row" spacing={1} alignItems="center">
          <span>{subtitle}</span>
          <span
            className="withdraw-application"
            onClick={() => onOpenCloseDialog(true)}
          >
            {t("applications.withdraw_application")}
          </span>
        </StyledHeaderSubtitle>
      ) : (
        <Stack spacing={0.5}>
          <StyledHeaderSubtitle direction="row" spacing={1} alignItems="center">
            <span>{subtitle}</span>
          </StyledHeaderSubtitle>
          <StyledHeaderSubtitle
            direction="row"
            spacing={1}
            alignItems="center"
            fontWeight="500 !important"
          >
            <span>{company}</span>
          </StyledHeaderSubtitle>
          <StyledHeaderSubtitle direction="row" spacing={1} alignItems="center">
            <span
              className="withdraw-application"
              onClick={() => onOpenCloseDialog(true)}
            >
              {t("applications.withdraw_application")}
            </span>
          </StyledHeaderSubtitle>
        </Stack>
      )}
    </Stack>
    <DeleteDialog
      t={t}
      isOpen={isOpen}
      title={t("applications.withdraw_application")}
      body={t("applications.delete_candidate_application_text")}
      note={t("applications.delete_application_note") as string}
      customDeleteButtonText={t("applications.withdraw_application") as string}
      onCancel={() => onOpenCloseDialog(false)}
      onDelete={onDeleteApplication}
    />
  </Stack>
);

export default SingleApplicationHeaderComponent;
