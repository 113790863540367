import { Box, TextField } from "@mui/material";
import { styled } from "@mui/system";

export const StyledSimpleFilterSearchField = styled(TextField)({
  svg: {
    width: 20,
  },
});

export const StyledInputContainer = styled(Box)(
  ({ flags }: { flags?: boolean }) => ({
    width: "100%",
    textDecoration: "none",
    textTransform: "none",
    height: 40,
    "& .MuiOutlinedInput-root .MuiAutocomplete-input": {
      position: "relative",
      bottom: 16.5,
      width: "100%",
      ...(flags && { left: 20 }),
    },
  }),
);

export const StyledInputContainerMultipleValues = styled(Box)({
  "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
    paddingTop: "4px!important",
    paddingBottom: "4px!important",
    height: "auto!important",
    "& .MuiInputBase-inputMultiline": {
      height: "26px!important",
    },
  },
});

export const StyledOptionContainer = styled("li")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
  lineHeight: "100%",
  img: {
    width: 20,
    height: 20,
    display: "flex",
    marginRight: 8,
  },
});

export const StyledInputFlagContainer = styled(Box)({
  display: "flex",
  position: "relative",
  width: 18,
  height: 16,
  top: 10,
});

export const StyledPlaceholder = styled(Box)({
  color: "#9CA3AF",
  fontWeight: 400,
  opacity: 0.5,
});
