import React from "react";
import {
  Button,
  Divider,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { StyledDataContainer } from "./style";
import { IConnectProfileComponent } from "../../models/CurrentUser";

const ConnectProfile = ({
  t,
  desktop,
  oldValue,
  newValue,
  isConnectAccountButtonDisabled,
  onChangeValue,
  onUpdateData,
}: IConnectProfileComponent) => (
  <StyledDataContainer p={3} border="1px solid #E5E7EB" borderRadius={1.5}>
    <Typography>{t("userSettings.connect_account")}</Typography>
    <Divider className="divider" />
    <Stack spacing={1.5}>
      <Stack>
        <InputLabel>LinkedIn Account</InputLabel>
        <TextField
          placeholder="https://www.linkedin.com/in/yourprofile/"
          defaultValue={oldValue.linkedin_url}
          value={newValue.linkedin_url}
          onChange={(e) => onChangeValue("linkedin_url", e.target.value)}
          fullWidth
        />
      </Stack>
      <Stack>
        <InputLabel>Xing Account</InputLabel>
        <TextField
          placeholder="https://www.xing.com/profile/yourprofile/cv"
          defaultValue={oldValue.xing_url}
          value={newValue.xing_url}
          onChange={(e) => onChangeValue("xing_url", e.target.value)}
          fullWidth
        />
      </Stack>
    </Stack>
    <Stack mt={desktop ? 3 : 1.5} alignItems="flex-end">
      <Button
        disabled={isConnectAccountButtonDisabled}
        variant="contained"
        onClick={() => onUpdateData(true)}
      >
        {t("button.save")}
      </Button>
    </Stack>
  </StyledDataContainer>
);

export default ConnectProfile;
