import React from "react";
import { TabContext } from "@mui/lab";
import { Tab, Tabs } from "@mui/material";
import Applications_new from "../Applications_new";
import BasicJobBoard_new from "./BasicJobBoard";
import PremiumJobBoards from "./PremiumJobBoards";
import JobDetails_new from "./JobDetails";
import Bookings_new from "./Bookings";
import { StyledSingleJobContainer, StyledTabPanel } from "./styles";
import { StyledTeamsTabsContainer } from "../TeamSettings_new/styles";
import { ISingleJobComponent, JOB_TABS_KEY } from "../../models/SingleJob";

const SingleJobComponent = ({
  t,
  jobTabs,
  selectedJobTab,
  onTabChange,
  onOpenCart,
}: ISingleJobComponent) => (
  <StyledSingleJobContainer
    mt={2.5}
    overflow={
      selectedJobTab === JOB_TABS_KEY.APPLICATIONS ? "hidden" : "visible"
    }
    mb={-4}
  >
    <TabContext value={selectedJobTab}>
      <StyledTeamsTabsContainer>
        <Tabs value={selectedJobTab} onChange={onTabChange}>
          {jobTabs.map((tab: JOB_TABS_KEY, index: number) => (
            <Tab key={tab + index} label={t(tab)} value={tab} />
          ))}
        </Tabs>
      </StyledTeamsTabsContainer>
      <StyledTabPanel value={JOB_TABS_KEY.APPLICATIONS}>
        <Applications_new containerMargin={0} showAddApplicationButton />
      </StyledTabPanel>
      <StyledTabPanel value={JOB_TABS_KEY.BASIC_JOB_BOARDS}>
        <BasicJobBoard_new t={t} />
      </StyledTabPanel>
      <StyledTabPanel value={JOB_TABS_KEY.PREMIUM_JOB_BOARDS}>
        <PremiumJobBoards onOpenCart={onOpenCart} />
      </StyledTabPanel>
      <StyledTabPanel value={JOB_TABS_KEY.JOB_DETAILS}>
        <JobDetails_new t={t} />
      </StyledTabPanel>
      <StyledTabPanel value={JOB_TABS_KEY.BOOKINGS}>
        <Bookings_new t={t} />
      </StyledTabPanel>
    </TabContext>
  </StyledSingleJobContainer>
);

export default SingleJobComponent;
