import React from "react";
import { Stack, Typography } from "@mui/material";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getActiveProduct } from "../../../store_new/selectors/CurrentUser";
import { RenderDot } from "../../JobLocations";

const ProductHeader = () => {
  const navigate = useNavigate();

  const activeProduct: any = useSelector(getActiveProduct);

  const handleOnNavigateBack = () => {
    navigate("/discover");
  };

  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      justifyContent="center"
    >
      <ArrowLeftIcon className="menu-icon" onClick={handleOnNavigateBack} />
      {activeProduct?.products[0]?.image_url && (
        <img
          className="supplier-logo"
          src={activeProduct?.products[0]?.image_url}
          alt="supplier-logo"
        />
      )}
      {activeProduct?.products[0]?.image_url && (
        <RenderDot customColor="#131E30" alwaysShow />
      )}
      <Typography color="#131E30" fontSize={16} fontWeight={500}>
        {activeProduct?.name}
      </Typography>
    </Stack>
  );
};

export default ProductHeader;
