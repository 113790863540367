import React, { useState } from "react";
import { Stack, Checkbox, Avatar } from "@mui/material";
import { Typography } from "@mui/material";
import {
  DocumentTextIcon,
  MapPinIcon,
  CalendarIcon,
} from "@heroicons/react/24/outline";
import CandidateItemElement from "./CandidateItemElement";
import CandidateInTalentPoolIcon from "../CandidateInTalentPoolIcon";
import { generateCandidateDate } from "../../utils";
import { StyledCandidateItem, StyledStatusInColumnText } from "./style";
import { ICandidateItemComponent, ICandidateItem } from "../../models/Kanban";
import { daysBeetwen } from "../../utils/singleJob";

const CandidateItem = ({
  t,
  data,
  provided,
  selectedItems,
  handleSelectItem,
  onItemClick,
}: ICandidateItemComponent) => {
  const [isCheckboxHovered, setIsCheckboxHovered] = useState<boolean>(false);
  const cv = data?.cv?.substring(74);
  const findItem = selectedItems?.find(
    (item: ICandidateItem) => item.id === data.id
  );

  const showInStatusText = data.status === "new" || data.status === "review";
  const numberOfDaysInStatus = daysBeetwen(data.updated_at);
  const statusType =
    numberOfDaysInStatus < 4
      ? "normal"
      : numberOfDaysInStatus < 8
      ? "orange"
      : "red";

  return (
    <StyledCandidateItem
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      sx={{
        backgroundColor: findItem ? "#EEF2FF" : "#fff",
        boxShadow: findItem
          ? `0px 0px 0px 0px rgba(37, 46, 55, 0.06),
                0px 7px 15px 0px rgba(37, 46, 55, 0.06),
                0px 27px 27px 0px rgba(37, 46, 55, 0.05),
                0px 60px 36px 0px rgba(37, 46, 55, 0.03),
                0px 106px 43px 0px rgba(37, 46, 55, 0.01),
                0px 166px 47px 0px rgba(37, 46, 55, 0.00)`
          : "none",
      }}
      onClick={() => {
        if (!isCheckboxHovered) {
          onItemClick(data.candidateUrlKey, data.jobId);
        }
      }}
    >
      <Stack borderBottom="1px solid #E5E7EB" spacing={1.5} height={57}>
        <Stack direction="row">
          <Checkbox
            checked={findItem ? true : false}
            color="secondary"
            onMouseOver={() => setIsCheckboxHovered(true)}
            onMouseLeave={() => setIsCheckboxHovered(false)}
            onClick={(e) => handleSelectItem(e, data)}
          />
          <Stack
            height={showInStatusText ? 51 : "auto"}
            justifyContent={showInStatusText ? "flex-end" : "center"}
          >
            <Typography
              variant="body2"
              color="#131E30"
              fontWeight={500}
              overflow="hidden"
              textOverflow="hidden"
              maxWidth={180}
              noWrap
            >
              {data.name}
            </Typography>
            {showInStatusText && (
              <StyledStatusInColumnText type={statusType}>
                {t("single_job.in_status_for", { numberOfDaysInStatus })}
              </StyledStatusInColumnText>
            )}
          </Stack>
        </Stack>
      </Stack>
      <Stack direction="row" p={1.5} spacing={2} height={92}>
        <Stack spacing={1} alignItems="center">
          <Avatar
            src={data.photo}
            sx={{
              width: 32,
              height: 32,
            }}
          />
          {data.is_talentpooled == 1 && (
            <CandidateInTalentPoolIcon
              firstname={data.firstname}
              lastname={`${data.lastname} ${t(
                "talentPools.isInYourTalentPool"
              )}`}
              photo={data.photo}
            />
          )}
        </Stack>
        <Stack spacing={0.25}>
          {!!data?.cv?.length && (
            <CandidateItemElement icon={<DocumentTextIcon />} text={cv} />
          )}
          <CandidateItemElement
            icon={<CalendarIcon />}
            text={generateCandidateDate(data?.date)}
          />
          {!!data?.location?.length && (
            <CandidateItemElement icon={<MapPinIcon />} text={data.location} />
          )}
        </Stack>{" "}
      </Stack>
    </StyledCandidateItem>
  );
};

export default CandidateItem;
