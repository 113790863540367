import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import RegisterResendLinkComponent from "./component";
import { resendLink } from "../../../store_new/reducers/Registration";

const RegisterResendLink_new = ({ desktop }: { readonly desktop: boolean }) => {
  const { t } = useTranslation();
  const [isDisabled, setIsDisabled] = useState(false);
  const dispatch = useDispatch();

  const handleOnResendEmail = () => {
    dispatch(resendLink({ callback: onResendSuccess }));
  };

  const onResendSuccess = () => {
    setIsDisabled(true);
    setTimeout(() => {
      setIsDisabled(false);
    }, 60000);
  };

  return (
    <RegisterResendLinkComponent
      t={t}
      desktop={desktop}
      isLinkDisabled={isDisabled}
      onResend={handleOnResendEmail}
    />
  );
};

export default RegisterResendLink_new;
