import React from "react";
import {
  Autocomplete,
  Box,
  Chip,
  InputAdornment,
  TextField
} from "@mui/material";
import Flag from "react-country-flag";
import { TAgencyListOption, TListOption } from "../../../models/common";
import {
  StyledInputContainer,
  StyledInputContainerMultipleValues,
  StyledInputFlagContainer,
  StyledOptionContainer
} from "../styles";

const AutocompleteFilter = ({
  value,
  options,
  placeholder,
  hasFlags,
  onChange,
  errors,
  multiple,
  size,
  filterOptions,
  listBoxProps
}: {
  value: string | string[] | TListOption[];
  options: TListOption[] | TAgencyListOption[];
  placeholder: string;
  hasFlags?: boolean;
  onChange: (data: string | string[]) => void;
  errors?: { message?: string };
  multiple?: boolean;
  size?: "small" | "medium";
  filterOptions?: any;
  listBoxProps?: any;
}) => {
  return (
    <Autocomplete
      isOptionEqualToValue={(option, value) => option.value === value.value}
      ListboxProps={listBoxProps}
      multiple={multiple}
      fullWidth
      size={size}
      value={value as unknown as TListOption}
      options={options}
      filterOptions={
        filterOptions
          ? filterOptions
          : (options, state) =>
              options.filter(
                (option) =>
                  option?.label
                    ?.toLowerCase()
                    .includes(state.inputValue.toLowerCase())
              )
      }
      getOptionLabel={(option) =>
        options.find(
          (opt: TListOption) => opt.value == (option as unknown as string)
        )?.label || ""
      }
      renderOption={(props, option) => (
        <StyledOptionContainer {...props}>
          {hasFlags && (
            <Box>
              <Flag countryCode={option.value} svg />
            </Box>
          )}
          {option.label}
        </StyledOptionContainer>
      )}
      onChange={(_, option) => {
        if (Array.isArray(option)) {
          onChange(option as any);
        } else if (typeof option === "object")
          onChange(option ? (option.value ? option.value : "") : "");
      }}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          return (
            <Chip
              size={"small"}
              {...getTagProps({ index })}
              key={index}
              label={option.label}
            ></Chip>
          );
        })
      }
      renderInput={(params) => {
        return multiple ? (
          <StyledInputContainerMultipleValues>
            <TextField
              multiline
              {...params}
              placeholder={placeholder}
              error={Boolean(errors)}
              helperText={errors?.message}
            />
          </StyledInputContainerMultipleValues>
        ) : (
          <StyledInputContainer
            flags={Boolean(hasFlags && value)}
            mb={errors ? 1 : 0}
          >
            <TextField
              placeholder={placeholder}
              {...params}
              error={Boolean(errors)}
              helperText={errors?.message}
              InputProps={{
                ...params.InputProps,
                ...(hasFlags &&
                  value && {
                    startAdornment: (
                      <InputAdornment position="start">
                        <StyledInputFlagContainer>
                          <Flag countryCode={value as string} svg />
                        </StyledInputFlagContainer>
                      </InputAdornment>
                    )
                  })
              }}
            />
          </StyledInputContainer>
        );
      }}
    />
  );
};

export default AutocompleteFilter;
