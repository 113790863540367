import React from "react";
import { Skeleton, Stack, Typography, Menu } from "@mui/material";
import {
  ArrowLeftIcon,
  EllipsisVerticalIcon,
} from "@heroicons/react/24/outline";
import {
  StyledHeaderSubtitle,
  StyledHeaderTitleContainer,
  StyledMenuItem,
} from "../styles";
import { DIALOG_TYPE } from "../../../models/common";
import { generateCandidateDate } from "../../../utils";
import { ITalentPoolTopHeaderComponent } from "../../../models/TalentPools";

const TalentPoolHeaderComponent = ({
  t,
  talentPool,
  anchorEl,
  onNavigateBack,
  onOpenMenu,
  onCloseMenu,
  onOpenTalentPoolDialog,
}: ITalentPoolTopHeaderComponent) => (
  <Stack>
    <StyledHeaderTitleContainer direction={"row"} spacing={2}>
      <ArrowLeftIcon onClick={onNavigateBack} />
      {!talentPool ? (
        <Skeleton variant={"rounded"} width={60} height={30} />
      ) : (
        <Stack direction="row" spacing={2}>
          <Typography variant="h6" data-testid="talent-pool-title">
            {talentPool?.title}
          </Typography>
          <EllipsisVerticalIcon
            data-testid="talent-pool-menu-dropdown-icon"
            className="menu-icon"
            onClick={(e) => onOpenMenu(e)}
          />
        </Stack>
      )}
    </StyledHeaderTitleContainer>
    <Stack pl={5} pt={0.25}>
      {!talentPool ? (
        <Skeleton variant={"rounded"} width={100} height={20} />
      ) : (
        <StyledHeaderSubtitle>
          {t("single_job.date_created")}{" "}
          {generateCandidateDate(talentPool?.created_at)}
        </StyledHeaderSubtitle>
      )}
    </Stack>
    <Menu
      data-testid="talentpool-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={onCloseMenu}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <StyledMenuItem
        onClick={() => {
          onOpenTalentPoolDialog(DIALOG_TYPE.CREATE_EDIT_TALENT_POOL);
        }}
      >
        {t("single_job.edit")}
      </StyledMenuItem>
      <StyledMenuItem
        onClick={() => onOpenTalentPoolDialog(DIALOG_TYPE.DELETE_TALENT_POOL)}
      >
        {t("talentPools.delete")}
      </StyledMenuItem>
    </Menu>
  </Stack>
);

export default TalentPoolHeaderComponent;
