import { Button, Stack } from "@mui/material";
import { styled } from "@mui/system";

export const StyledApplicationsContainer = styled(Stack)({
  height: "100%",
  button: { border: "none" },
});

export const StyledApplicationsFilterContainer = styled(Stack)(
  ({ isAgency, jobId }: { isAgency?: number; jobId?: string | null }) => ({
    padding: 0,
    ".quick-search-container": {
      width: isAgency && !jobId ? "50%" : "35%",
      gap: 24,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      ".MuiFormControl-root": {
        maxWidth: isAgency && !jobId ? "90%" : "60%",
      },
    },
    ".location-search-container": {
      width: "100%",
      ".MuiFormControl-root": {
        maxWidth: "100%",
      },
    },
    ".applications-search-autocomplete": {
      width: isAgency && !jobId ? "57.5%" : "100%",
      ".MuiFormControl-root": {
        maxWidth: "100%",
      },
    },
    ".advanced-filters-container": {
      width: isAgency ? "60%" : "65%",
      justifyContent: "flex-end",
      ".MuiFormControl-root": {
        width: "100%",
      },
    },
    ".clear-icon": {
      cursor: "pointer",
      marginRight: 6,
      height: 18,
      width: 18,
    },
    ".input-filter": { width: 180 },
    ".input-selector-filter": {
      width: "100%",
      textDecoration: "none",
      textTransform: "none",
      "& .MuiInputBase-input": {
        width: "100%",
        position: "relative",
        top: 8,
      },
    },
  })
);

export const StyledApplicationsToolbarContainer = styled(Stack)({
  flexDirection: "row",
  gap: 12,
  margin: "12px 0",
  button: {
    width: "fit-content",
    background: "#818CF8",
    color: "#fff",
    boxShadow: "none",
    "&.Mui-disabled": {
      opacity: "30%",
      color: "#fff",
    },
    "&:hover": {
      background: "#6366F1",
      color: "#fff",
      boxShadow: "none",
    },
  },
  ".btn-icon": {
    width: 20,
    height: 20,
    stroke: "#fff",
  },
});

export const StyledAddApplicantsButton = styled(Button)({
  minWidth: 160,
  maxWidth: 220,
  background: "#818CF8",
  color: "#fff",
  boxShadow: "none",
  ".MuiButton-startIcon": {
    width: 20,
    height: 20,
    strokeWidth: 1.5,
    stroke: "#fff",
  },
  "&.Mui-disabled": {
    opacity: "30%",
    color: "#fff",
  },
  "&:hover": {
    background: "#6366F1",
    color: "#fff",
    boxShadow: "none",
  },
});

export const StyledNoResultContainer = styled(Stack)({
  height: "100%",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  img: {
    width: 205,
    height: 200,
  },
});
