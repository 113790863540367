import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CandidateDashboardComponent from "./component";
import {
  getCandidateApplications,
  getCandidateState
} from "../../store_new/selectors/Candidate";
import { setCandidateJob } from "../../slices/Candidate";
import { TCandidateApplication } from "../../models/ApplicationPage";
import { fetchCandidateApplications } from "../../store_new/reducers/Candidate";
import {
  getCurrentUserIsCandidate,
  getCurrentUserState
} from "../../store_new/selectors/CurrentUser";

const CandidateDashboard_new = ({ desktop }: { desktop: boolean }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const applications = useSelector(getCandidateApplications);
  const isCandidate = useSelector(getCurrentUserIsCandidate);
  const { isLoading } = useSelector(getCandidateState);
  const { statuses } = useSelector(getCurrentUserState);

  const handleOnSelectJob = (application: TCandidateApplication) => {
    dispatch(
      setCandidateJob({
        timestamp: application.timestamp,
        title: application.job.title,
        company_title: application.job.company,
        job_id: application.job.id
      })
    ); // TODO refactor later within refactoring TOP Header
    navigate(`/candidate-dashboard/job/${application.job.url_key}`);
  };

  useEffect(() => {
    if (isCandidate !== undefined) {
      if (!isCandidate) {
        navigate("/dashboard");
      } else {
        dispatch(fetchCandidateApplications());
      }
    }
  }, [isCandidate]);

  const formattedStatus = (status: string) => {
    const findStatus = statuses?.find((item) => item.value === status);
    return findStatus?.label;
  };

  return (
    <CandidateDashboardComponent
      t={t}
      applications={applications}
      desktop={desktop}
      isLoading={isLoading}
      isCandidate={isCandidate}
      formattedStatus={formattedStatus}
      onSelectJob={handleOnSelectJob}
    />
  );
};

export default CandidateDashboard_new;
