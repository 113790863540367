import * as yup from "yup";
import { TFunction } from "i18next";
import {
  TJobDescriptionFormFields,
  TJobDetailsFormFields,
} from "../../models/JobEditor";
import { isEmptyHtmlString } from "../../utils/singleJob";

export const getJobDescriptionFormFields = (t: TFunction) =>
  yup.object().shape({
    header_1: yup.mixed().nullable(),
    header_2: yup.mixed().nullable(),
    header_3: yup.mixed().nullable(),
    logo: yup.mixed().nullable(),
    background: yup.mixed().nullable(),
    company_color: yup.string(),
    background_color: yup.string(),
    job_title: yup
      .string()
      .required(t("register.message") as string)
      .max(200, t("field_max_length_text") as string),
    introduction_title: yup
      .string()
      .required(t("register.message") as string)
      .max(200, t("field_max_length_text") as string),
    introduction: yup
      .string()
      .transform((htmlString: string) =>
        isEmptyHtmlString(htmlString) ? null : htmlString
      )
      .required(t("register.message") as string),
    tasks_title: yup
      .string()
      .required(t("register.message") as string)
      .max(200, t("field_max_length_text") as string),
    tasks: yup
      .string()
      .transform((htmlString: string) =>
        isEmptyHtmlString(htmlString) ? null : htmlString
      )
      .required(t("register.message") as string),
    offer_title: yup
      .string()
      .required(t("register.message") as string)
      .max(200, t("field_max_length_text") as string),
    offer: yup
      .string()
      .transform((htmlString: string) =>
        isEmptyHtmlString(htmlString) ? null : htmlString
      )
      .required(t("register.message") as string),
    profile_title: yup
      .string()
      .required(t("register.message") as string)
      .max(200, t("field_max_length_text") as string),
    profile: yup
      .string()
      .transform((htmlString: string) =>
        isEmptyHtmlString(htmlString) ? null : htmlString
      )
      .required(t("register.message") as string),
    contact_title: yup.string().max(200, t("field_max_length_text") as string),
    contact: yup.string(),
    video_url: yup.string(),
  });

export const jobDescriptionFormDefaultValues: TJobDescriptionFormFields = {
  header_1: undefined,
  header_2: undefined,
  header_3: undefined,
  logo: undefined,
  background: undefined,
  company_color: "#178CF2",
  background_color: "#FFFFFF",
  job_title: "",
  introduction_title: "",
  introduction: "",
  tasks_title: "",
  tasks: "",
  profile_title: "",
  profile: "",
  offer_title: "",
  offer: "",
  contact_title: "",
  contact: "",
  video_url: "",
};

export const getJobDetailsFormFields = (t: TFunction) =>
  yup.object().shape({
    locations: yup.array().of(
      yup.object().shape({
        country: yup.string().required(t("register.message") as string),
        city: yup.string().required(t("register.message") as string),
        zip: yup.string().required(t("register.message") as string),
      })
    ),
    contract_type: yup.string().required(t("register.message") as string),
    field_of_work: yup.string().required(t("register.message") as string),
    seniority: yup.string().required(t("register.message") as string),
    position_type: yup.string().required(t("register.message") as string),
    industry: yup.string().required(t("register.message") as string),
    qualification: yup.string().required(t("register.message") as string),
    reference_number: yup.string().nullable(),
    date_of_publication: yup.string().nullable(),
    assign_job_to: yup
      .array()
      .of(
        yup.object().shape({
          label: yup.string(),
          value: yup.string(),
        })
      )
      .nullable()
      .transform((val) => (!val ? [] : val)),
    keywords: yup.string().nullable(),
    notes: yup.string().nullable(),
    min_salary: yup.string(),
    max_salary: yup.string(),
    salary_currency: yup.string().nullable(),
    salary_type: yup.string().nullable(),
  });

export const jobDetailsFormDefaultValues: TJobDetailsFormFields = {
  locations: [{ country: "", city: "", zip: "" }],
  contract_type: "",
  field_of_work: "",
  seniority: "",
  position_type: "",
  industry: "",
  qualification: "",
  reference_number: "",
  date_of_publication: undefined,
  assign_job_to: "",
  keywords: "",
  notes: "",
  min_salary: "",
  max_salary: "",
  salary_currency: "",
  salary_type: "",
};

export const getJobApplicationProcessFormFields = (t: TFunction) =>
  yup.object().shape({
    phone_required: yup.string(),
    location_required: yup.string(),
    salary_expectation_required: yup.string(),
    earliest_start_date_required: yup.string(),
    driver_license_required: yup.string(),
    current_professional_status_required: yup.string(),
    highest_degree_required: yup.string(),
    langs_required: yup.string(),
    skills_required: yup.string(),
    cv_required: yup.string(),
    additional_documents: yup.string(),
    questions: yup.array().of(
      yup.object().shape({
        question: yup.string().required(t("register.message") as string),
        is_answer_type_yesno: yup.string(),
      })
    ),
  });

export const jobApplicationProcessFormDefaultValues: any = {
  phone_required: "1",
  location_required: "1",
  salary_expectation_required: "0",
  earliest_start_date_required: "0",
  driver_license_required: "0",
  current_professional_status_required: "0",
  highest_degree_required: "0",
  langs_required: "0",
  skills_required: "0",
  cv_required: "1",
  other_documents_required: "0",
  questions: [{ question: "", is_answer_type_yesno: 1 }],
};
