import React from "react";
import { Button, Typography } from "@mui/material";
import { IJobListNoResultComponent } from "../../../models/JobList";
import { StyledNoResultContainer } from "../styles";

const JobListNoResultComponent = ({
  actionText,
  bodyText,
  titleText,
  hasFilters,
  imgSrc,
  showButton,
  onResetFilters,
  onToggleDialog,
}: IJobListNoResultComponent) => (
  <>
    <StyledNoResultContainer>
      <img src={imgSrc} alt="no-jobs" />
      <Typography variant="h6" paddingTop={4.5}>
        {titleText}
      </Typography>
      <Typography
        variant="body2"
        color="textSecondary"
        paddingTop={1}
        paddingBottom={3}
      >
        {bodyText}
      </Typography>
      {showButton && (
        <Button
          variant="contained"
          onClick={hasFilters ? onResetFilters : onToggleDialog}
        >
          {actionText}
        </Button>
      )}
    </StyledNoResultContainer>
  </>
);

export default JobListNoResultComponent;
