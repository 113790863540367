import React, { useEffect, useMemo, useState } from "react";
import { TFunction } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import BasicJobBoardComponent from "./component";
import ConnectDialog from "./ConnectDialog";
import { DIALOG_TYPE } from "../../../models/common";
import { TBasicJobBoard } from "../../../models/SingleJob";
import { updateCompanyData } from "../../../store_new/reducers/CompanySettings";
import {
  fetchBasicJobBoards,
  updateHeaderImage,
} from "../../../store_new/reducers/SingleJob";
import { getSingleJobState } from "../../../store_new/selectors/SingleJob";
import { getModifiedJobBoards } from "../../../utils/basicJobBoards";
import { fetchCompanyInfo } from "../../../store_new/reducers/CompanyInfo";

const BasicJobBoard_new = ({ t }: { t: TFunction }) => {
  const dispatch = useDispatch();
  const { jobDetails, company, basicJobBoards, isLoading, isListLoading } =
    useSelector(getSingleJobState);
  const [selectedJobBoard, setSelectedJobBoard] =
    useState<TBasicJobBoard | null>(null);
  const [companyProfileUrl, setCompanyProfileUrl] = useState("");
  const [openedDialogType, setOpenedDialogType] = useState<DIALOG_TYPE | null>(
    null
  );
  useEffect(() => {
    dispatch(fetchBasicJobBoards());
  }, []);
  useEffect(() => {
    if (jobDetails)
      dispatch(fetchCompanyInfo({ companyKey: jobDetails.company_url_key }));
  }, [jobDetails]);

  const isDataLoading = useMemo(
    () => isListLoading || isLoading,
    [isLoading, isListLoading]
  );

  const jobList = useMemo(
    () => getModifiedJobBoards({ company, jobDetails, basicJobBoards }),
    [basicJobBoards, company, jobDetails]
  );

  const handleOnUploadFile = (file?: File) => {
    if (file && jobDetails) {
      const {
        title,
        template: template_name,
        url_key: jobUrlKey,
        company_id,
      } = jobDetails;
      dispatch(
        updateHeaderImage({
          title,
          template_name,
          jobUrlKey,
          company_id,
          template_header_1: file,
        })
      );
    }
  };

  const handleOnOpenConnectDialog = (item: TBasicJobBoard) => {
    setSelectedJobBoard(item);
    setOpenedDialogType(DIALOG_TYPE.CONNECTED);
  };

  const handleOnChangeCompanyProfileUrl = (url: string) => {
    setCompanyProfileUrl(url);
  };

  const handleOnSaveCompanyProfileUrl = () => {
    const data =
      selectedJobBoard?.title === "Linkedin"
        ? { linkedin_url: companyProfileUrl }
        : { xing_url: companyProfileUrl };
    dispatch(
      updateCompanyData({
        type: "form",
        data,
        callback: onSaveCompanyProfileUrlSuccess,
      })
    );
  };

  const onSaveCompanyProfileUrlSuccess = () => {
    if (jobDetails) {
      dispatch(fetchCompanyInfo({ companyKey: jobDetails.company_url_key }));
      handleOnCloseDialog();
    }
  };

  useEffect(() => {
    // TODO refactor later, switch to callback
    if (isLoading) {
      handleOnCloseDialog();
    }
  }, [isLoading]);

  const handleOnCloseDialog = () => {
    setOpenedDialogType(null);
    setCompanyProfileUrl("");
  };

  return (
    <>
      <BasicJobBoardComponent
        t={t}
        isListLoading={isDataLoading}
        jobList={jobList}
        actions={{
          onConnect: handleOnOpenConnectDialog,
          onUpload: handleOnUploadFile,
        }}
      />
      <ConnectDialog
        t={t}
        companyProfileUrl={companyProfileUrl}
        jobBoard={selectedJobBoard}
        isLoading={isLoading}
        isOpen={openedDialogType === DIALOG_TYPE.CONNECTED}
        onClose={handleOnCloseDialog}
        onSave={handleOnSaveCompanyProfileUrl}
        onChangeUrl={handleOnChangeCompanyProfileUrl}
      />
    </>
  );
};

export default BasicJobBoard_new;
