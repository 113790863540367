import React from "react";
import { PAGE_TYPES } from "../../models/TopHeader";
import { Stack } from "@mui/material";
import { Bars3Icon } from "@heroicons/react/24/outline";
import { useSelector, useDispatch } from "react-redux";
import { useMatches } from "react-router-dom";
import SingleJobHeader from "./SingleJobHeader";
import SingleApplicationHeader from "./SingleApplicationHeader";
import UserMenu from "./UserMenu";
import SingleApplicationCandidateHeader from "./SingleApplicationCandidateHeader";
import MobileChatTopHeader from "./MobileChatTopHeader";
import CustomerDetailsHeader from "./CustomerDetailsHeader";
import TalentPoolHeader from "./TalentPoolHeader";
import ProductHeader from "./ProductHeader";
import PackageHeader from "./PackageHeader";
import { StyledHeaderContainer, StyledHeaderTitle } from "./styles";
import { setMobileDrawerOpen } from "../../slices/Navigation/navigationSlice";
import { getMessagesState } from "../../store_new/selectors/Messages";

const TopHeader_new = ({ desktop }: { readonly desktop: boolean }) => {
  const matches = useMatches();
  const { handle } = matches?.[1] || {};
  const { pageType, title } =
    (handle as { pageType: PAGE_TYPES; title: string }) || {};
  const dispatch = useDispatch();
  const { messagesInfo } = useSelector(getMessagesState);

  const showUserAvatar = desktop
    ? true
    : messagesInfo && !desktop
    ? false
    : !desktop && PAGE_TYPES.SINGLE_APPLICATION_CANDIDATE
    ? false
    : true;

  return (
    <StyledHeaderContainer>
      <Stack
        direction="row"
        alignItems={"center"}
        justifyContent={"flex-start"}
        spacing={1.5}
      >
        {!desktop && !pageType && (
          <Bars3Icon
            className="menu-icon"
            onClick={() => dispatch(setMobileDrawerOpen(true))}
          />
        )}
        {pageType === PAGE_TYPES.SINGLE_JOB && (
          <SingleJobHeader desktop={desktop} />
        )}
        {pageType === PAGE_TYPES.SINGLE_APPLICATION && (
          <SingleApplicationHeader desktop={desktop} />
        )}
        {pageType === PAGE_TYPES.TALENT_POOL && <TalentPoolHeader />}
        {pageType === PAGE_TYPES.SINGLE_APPLICATION_CANDIDATE && (
          <SingleApplicationCandidateHeader desktop={desktop} />
        )}
        {pageType === PAGE_TYPES.MESSAGES_MOBILE && (
          <MobileChatTopHeader desktop={desktop} />
        )}
        {pageType === PAGE_TYPES.CUSTOMER_DETAILS && (
          <CustomerDetailsHeader desktop={desktop} />
        )}
        {pageType === PAGE_TYPES.PRODUCT && <ProductHeader />}
        {pageType === PAGE_TYPES.PACKAGE && <PackageHeader />}
        {!pageType && (
          <StyledHeaderTitle desktop={desktop}>{title}</StyledHeaderTitle>
        )}
      </Stack>
      {showUserAvatar && <UserMenu desktop={desktop} />}
    </StyledHeaderContainer>
  );
};

export default TopHeader_new;
