import React from "react";
import { Stack, Typography } from "@mui/material";
import { Controller, FieldArrayWithId } from "react-hook-form";
import { XCircleIcon } from "@heroicons/react/24/outline";
import AutocompleteFilter from "../../../../components/filters_new/AutocompleteFilter";
import SelectorFilter from "../../../../components/filters_new/SelectorFilter";
import {
  StyledAdditionalQuestionsFieldContainer,
  StyledAddLanguageButton,
  StyledRemoveLanguageButtonContainer,
} from "../../styles";
import { TListOption } from "../../../../models/common";
import { IAdditionalQuestionsLanguagesComponent } from "../../../../models/JobPreview";

const AdditionalQuestionsLanguagesComponent = ({
  t,
  control,
  desktop,
  fields,
  languages,
  languageLevels,
  companyColor,
  boldHeading,
  isEditDialog,
  onAdd,
  onRemove,
}: IAdditionalQuestionsLanguagesComponent) => (
  <StyledAdditionalQuestionsFieldContainer
    desktop={desktop}
    borderBottom={boldHeading ? "0px !important" : "1px solid #E5E7EB"}
    py={boldHeading ? "0px !important" : "24px !important"}
  >
    <Stack
      mt={isEditDialog ? 1.5 : 0}
      className={"title"}
      height={boldHeading ? "auto !important" : "30px !important"}
    >
      <Typography variant="subtitle2" fontWeight={boldHeading ? 500 : 400}>
        {t("additional_questions.language")}
      </Typography>
    </Stack>
    <Stack className={"input-field"} mt={isEditDialog ? 1.5 : 0} spacing={2}>
      {fields.map((field: FieldArrayWithId<TListOption>, index: number) => (
        <Stack direction={"row"} key={field.id} spacing={2}>
          <Controller
            name={`languages.${index}`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Stack direction={"row"} spacing={2} sx={{ width: "100%" }}>
                <Stack sx={{ width: "48%" }}>
                  <AutocompleteFilter
                    value={value.language}
                    options={languages}
                    placeholder={t("additional_questions.language_placeholder")}
                    onChange={(val) => {
                      onChange({ ...value, language: val as string });
                    }}
                  />
                </Stack>
                <Stack
                  sx={{
                    width: "48%",
                    "& .MuiInputBase-input": {
                      width: "100%",
                      position: "relative",
                      top: isEditDialog ? 8 : 0,
                    },
                  }}
                >
                  <SelectorFilter
                    isDisabled={false}
                    value={value.level}
                    onChange={(val) => {
                      onChange({ ...value, level: val as string });
                    }}
                    label={""}
                    placeholder={"Level"}
                    options={languageLevels}
                    className={"input-selector-filter"}
                    customMaxWidth="100%"
                  />
                </Stack>
              </Stack>
            )}
          />
          {fields.length > 1 && (
            <StyledRemoveLanguageButtonContainer>
              <XCircleIcon onClick={() => onRemove(index)} />
            </StyledRemoveLanguageButtonContainer>
          )}
        </Stack>
      ))}
      <StyledAddLanguageButton
        companyColor={companyColor}
        variant={"outlined"}
        onClick={onAdd}
      >
        {t("additional_questions.add_language")}
      </StyledAddLanguageButton>
    </Stack>
  </StyledAdditionalQuestionsFieldContainer>
);

export default AdditionalQuestionsLanguagesComponent;
