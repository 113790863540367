import React from "react";
import { Stack, Divider } from "@mui/material";
import { TFunction } from "i18next";
import FilterComponent from "./Filter";
import Orders from "./Orders";
import Loading from "../../../components/Loading";
import ApplicationsNoResultOverlay from "../../Applications_new/NoResultOverlay";
import { IJobOrder } from "../../../models/SingleJob";

const Bookings_NewComponent = ({
  t,
  orders,
  isLoading,
}: {
  readonly t: TFunction;
  readonly orders?: IJobOrder[];
  readonly isLoading: boolean;
}) =>
  isLoading ? (
    <Loading />
  ) : (
    <Stack
      p={3}
      minHeight="100%"
      border="1px solid #E5E7EB"
      borderRadius="6px"
      spacing={3}
      sx={{ background: "#fff" }}
    >
      <FilterComponent t={t} />
      <Divider />

      {orders && orders.length > 0 ? (
        <Orders t={t} orders={orders} />
      ) : (
        <Stack
          height={window.innerHeight - 316}
          alignItems="center"
          justifyContent="center"
          maxHeight="100%"
        >
          <ApplicationsNoResultOverlay
            t={t}
            customTitle={t("applications.no_bookings_title") as string}
            customBodyText=" "
          />
        </Stack>
      )}
    </Stack>
  );

export default Bookings_NewComponent;
