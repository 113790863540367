import React from "react";
import { StyledDataContainer } from "./style";
import {
  Divider,
  InputLabel,
  Stack,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import ProfilePictureNew from "../../components/ProfilePicture_new";
import SelectorFilter from "../../components/filters_new/SelectorFilter";
import { DIALOG_TYPE } from "../../models/common";
import { isValidPhoneNumber } from "../../utils/common";
import { IMainInfoComponent } from "../../models/CurrentUser";

const MainInfo = ({
  t,
  desktop,
  photo,
  oldValue,
  newValue,
  isCandidate,
  language,
  languages,
  isSaveUserDataButtonDisabled,
  onChangeValue,
  onOpenCloseDialog,
  onUpdateData,
  onRemovePhoto,
}: IMainInfoComponent) => (
  <StyledDataContainer>
    <Typography>{t("userSettings.personal_information")}</Typography>
    <Divider className="divider" />
    <Stack
      direction={desktop ? "row" : "column"}
      spacing={desktop ? 3 : 1.5}
      alignItems={desktop ? "flex-end" : "center"}
    >
      <Stack>
        <ProfilePictureNew
          imgSrc={photo}
          onOpenUploadDialog={() => onOpenCloseDialog(DIALOG_TYPE.UPLOAD_IMAGE)}
          onDelete={onRemovePhoto}
        />
      </Stack>
      <Stack width="100%" spacing={1.5}>
        <Stack
          direction={desktop ? "row" : "column"}
          width="100%"
          spacing={desktop ? 3 : 1.5}
        >
          <Stack width="100%">
            <InputLabel>{t("userSettings.firstname")}</InputLabel>
            <TextField
              defaultValue={oldValue.firstname}
              value={newValue.firstname}
              fullWidth
              onChange={(e) => onChangeValue("firstname", e.target.value)}
              placeholder={t("userSettings.firstname") as string}
            />
          </Stack>
          <Stack width="100%">
            <InputLabel>{t("userSettings.lastname")}</InputLabel>
            <TextField
              defaultValue={oldValue.lastname}
              value={newValue.lastname}
              onChange={(e) => onChangeValue("lastname", e.target.value)}
              fullWidth
              placeholder={t("userSettings.lastname") as string}
            />
          </Stack>
          <Stack width="100%">
            <InputLabel>{t("userSettings.email")}</InputLabel>
            <TextField fullWidth defaultValue={oldValue.username} disabled />
          </Stack>
        </Stack>
        <Stack
          direction={desktop ? "row" : "column"}
          width="100%"
          alignItems="flex-end"
          spacing={desktop ? 3 : 1.5}
        >
          <Stack width="100%">
            <InputLabel>{t("userSettings.phone_number")}</InputLabel>
            <TextField
              defaultValue={oldValue.phonenumber}
              value={newValue.phonenumber}
              onChange={(e) => onChangeValue("phonenumber", e.target.value)}
              onKeyPress={(e) =>
                isValidPhoneNumber(e as unknown as KeyboardEvent)
              }
              fullWidth
              placeholder="123 456 789 12"
              disabled={isCandidate}
            />
          </Stack>
          <Stack width="100%">
            <InputLabel>{t("userSettings.language")}</InputLabel>
            <SelectorFilter
              className={"input-selector-filter"}
              label={t("userSettings.language")}
              value={language}
              options={languages}
              onChange={(value: string) => onChangeValue("lang", value)}
              hideCloseIcon
            />
          </Stack>
          <Stack width="100%" height="100%">
            <Button
              variant="contained"
              onClick={() => onOpenCloseDialog(DIALOG_TYPE.CHANGE_PASSWORD)}
            >
              {t("userSettings.change_password")}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
    <Stack
      mt={desktop ? 3 : 1.5}
      direction={desktop ? "row" : "column"}
      justifyContent="flex-end"
      width="100%"
    >
      <Button
        disabled={isSaveUserDataButtonDisabled}
        variant="contained"
        onClick={() => onUpdateData()}
      >
        {t("button.save")}
      </Button>
    </Stack>
  </StyledDataContainer>
);

export default MainInfo;
