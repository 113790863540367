import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { apiGetApplications } from "../../api/Applications";
import {
  apiDuplicateJob,
  apiGetBasicJobBoards,
  apiGetBookings,
  apiGetJobDetails,
  apiGetJobTemplate,
  apiUpdateHeaderImage,
} from "../../api/SingleJob";
import { PayloadActionWithCallback } from "../../models/common";
import {
  getConvertedProducts,
  getPackagesWithDiscount,
} from "../../utils/singleJob";
import { fetchApplicationsSuccess } from "../reducers/Applications";
import {
  duplicateJob,
  duplicateJobFailed,
  duplicateJobSuccess,
  fetchBasicJobBoards,
  fetchBasicJobBoardsFailed,
  fetchBasicJobBoardsSuccess,
  fetchBookings,
  fetchBookingsFailed,
  fetchBookingsSuccess,
  fetchJobApplications,
  fetchJobApplicationsFailed,
  fetchJobApplicationsSuccess,
  fetchJobData,
  fetchJobDataFailed,
  fetchJobDataSuccess,
  fetchJobDetails,
  fetchJobDetailsFailed,
  fetchJobDetailsSuccess,
  fetchJobTemplate,
  fetchJobTemplateFailed,
  fetchJobTemplateSuccess,
  fetchProductsAndPackages,
  fetchProductsAndPackagesFailed,
  fetchProductsAndPackagesSuccess,
  updateHeaderImage,
  updateHeaderImageFailed,
  updateHeaderImageSuccess,
} from "../reducers/SingleJob";
import { ICandidateItem } from "../../models/Kanban";
import { getApplicationsViewState } from "../selectors/Applications";

function* handleOnFetchBasicJobBoards() {
  const url = `/job-boards`;

  try {
    const { data } = yield call(apiGetBasicJobBoards, { url });
    yield put(fetchBasicJobBoardsSuccess({ list: data.job_boards }));
  } catch (e) {
    yield put(fetchBasicJobBoardsFailed(e)); // TODO handle error
  }
}

function* handleOnFetchProductsAndPackages({
  payload,
}: PayloadAction<{ urlKey: string }>) {
  const { urlKey } = payload;
  const url = urlKey
    ? `/company/jobs/publish-prepare/${urlKey}`
    : `/company/companies/prices`;

  try {
    const { data } = yield call(apiGetBasicJobBoards, { url });
    const { packages = [], products = [] } = data;
    yield put(
      fetchProductsAndPackagesSuccess({
        packages: getPackagesWithDiscount(packages),
        products: getConvertedProducts(products),
        shopPackages: !urlKey ? getPackagesWithDiscount(packages) : undefined,
        shopProducts: !urlKey ? getConvertedProducts(products) : undefined,
        countries: Object.keys(data.countries).map((itemKey: string) => ({
          value: itemKey,
          label: data.countries[itemKey],
        })),
        industries: Object.keys(data.industries).map((itemKey: string) => ({
          value: itemKey,
          label: data.industries[itemKey],
        })),
        countriesToIndustries: data.countriesToIndustries,
        industriesToCountries: data.industriesToCountries,
      })
    );
  } catch (e) {
    yield put(fetchProductsAndPackagesFailed(e)); // TODO handle error
  }
}

function* handleOnFetchJobDetails({
  payload,
}: PayloadAction<{ jobUrlKey: string }>) {
  const { jobUrlKey } = payload;
  const url = `/company/jobs/details/${jobUrlKey}`;

  try {
    const { data } = yield call(apiGetJobDetails, { url });
    const { job: jobDetails, locations, questions, owners } = data;
    yield put(
      fetchJobDetailsSuccess({ jobDetails, locations, questions, owners })
    );
  } catch (e) {
    yield put(fetchJobDetailsFailed(e)); // TODO handle error
  }
}

function* handleOnFetchApplications({
  payload,
}: PayloadActionWithCallback<{ jobId: string; isApplyDialog?: boolean }>) {
  const { isApplyDialog, jobId, callback } = payload;
  const view: string = yield select(getApplicationsViewState);
  const url = `/company/candidates/applications/${jobId}`;
  try {
    const { data } = yield call(apiGetApplications, {
      url,
      apiPayload: {
        pagesize: view === "cards" ? -1 : undefined,
      },
    });
    const { applications: list, job, totalCount } = data;
    const newApplicants: ICandidateItem[] = [];
    const reviewApplicants: ICandidateItem[] = [];
    const hiredApplicants: ICandidateItem[] = [];
    const rejectedApplicants: ICandidateItem[] = [];

    list.forEach((item: ICandidateItem) => {
      const findCV = item?.documents?.find((document: any) => document.isCV);
      const formatedItem: ICandidateItem = {
        ...item,
        id: item.application_id,
        name: `${item.firstname} ${item.lastname}`,
        photo: item.photo,
        cv: findCV?.link as string,
        jobTitle: item.title,
        date: item.timestamp,
        location: item.location,
        appIndex: item.app_index,
        candidateUrlKey: item.candidate_url_key,
        jobId: item.job_id,
      };

      switch (item.status) {
        case "new":
          newApplicants.push({ ...formatedItem, status: "new" });
          break;
        case "review":
          reviewApplicants.push({ ...formatedItem, status: "review" });
          break;
        case "hired":
          hiredApplicants.push({ ...formatedItem, status: "hired" });
          break;
        case "rejected":
          rejectedApplicants.push({ ...formatedItem, status: "rejected" });
          break;
      }
    });

    newApplicants.sort((a, b) => parseInt(a.appIndex) - parseInt(b.appIndex));
    reviewApplicants.sort(
      (a, b) => parseInt(a.appIndex) - parseInt(b.appIndex)
    );
    hiredApplicants.sort((a, b) => parseInt(a.appIndex) - parseInt(b.appIndex));
    rejectedApplicants.sort(
      (a, b) => parseInt(a.appIndex) - parseInt(b.appIndex)
    );

    if (view === "cards" || isApplyDialog) {
      yield put(
        fetchApplicationsSuccess({
          urlKey: job.url_key,
          list,
          totalCount,
          newApplicants,
          reviewApplicants,
          hiredApplicants,
          rejectedApplicants,
        })
      );
    }

    yield put(fetchJobApplicationsSuccess);
    yield call(callback, job.url_key);
  } catch (e) {
    yield put(fetchJobApplicationsFailed(e)); // TODO handle error
  }
}

function* handleOnUpdateHeaderImage({
  payload,
}: PayloadAction<{
  jobUrlKey: string;
  title: string;
  template_name: string;
  template_header_1: File;
  company_id: string;
}>) {
  const { jobUrlKey, title, template_name, company_id, template_header_1 } =
    payload;
  const url = `/company/jobs/save/${jobUrlKey}`;

  try {
    yield call(apiUpdateHeaderImage, {
      url,
      apiPayload: { title, template_name, template_header_1, company_id },
    });
    yield put(updateHeaderImageSuccess());
    yield put(fetchJobDetails({ jobUrlKey }));
  } catch (e) {
    yield put(updateHeaderImageFailed(e)); // TODO handle error
  }
}

function* handleOnFetchJobTemplate({
  payload,
}: PayloadAction<{
  templateId: string;
}>) {
  const { templateId } = payload;
  const url = `/company/templates/get-raw/${templateId}`;

  try {
    const { data } = yield call(apiGetJobTemplate, {
      url,
      apiPayload: { templateId },
    });

    const { raw_template: template } = data;
    if (!template.company_color) template.company_color = "#178CF2"; // default title color
    if (!template.background_color) template.background_color = "#FFFFFF"; // default background color
    yield put(fetchJobTemplateSuccess({ template }));
  } catch (e) {
    yield put(fetchJobTemplateFailed(e)); // TODO handle error
  }
}

function* handleOnFetchBookings({
  payload,
}: PayloadAction<{ jobUrlKey: string }>) {
  const { jobUrlKey } = payload;
  const url = `/company/jobs/order-statistics/${jobUrlKey}`;
  try {
    const { data } = yield call(apiGetBookings, { url });
    yield put(fetchBookingsSuccess(data));
  } catch (e) {
    yield put(fetchBookingsFailed(e)); // TODO handle error
  }
}

function* handleOnFetchJobData({
  payload,
}: PayloadActionWithCallback<{ jobId: string }>) {
  const { jobId, callback } = payload;
  const url = `/company/jobs/info/${jobId}`;
  try {
    const { data } = yield call(apiGetApplications, { url });
    const { job } = data;
    yield put(fetchJobDataSuccess);
    yield call(callback, job.url_key);
  } catch (e) {
    yield put(fetchJobDataFailed(e)); // TODO handle error
  }
}

function* handleOnDuplicateJob({
  payload,
}: PayloadActionWithCallback<{ urlKey: string }>) {
  const { urlKey, callback } = payload;
  const url = `/company/jobs/duplicate/${urlKey}`;
  try {
    yield call(apiDuplicateJob, { url });
    yield put(duplicateJobSuccess());
    yield call(callback);
  } catch (e) {
    yield put(duplicateJobFailed(e)); // TODO handle error
  }
}

function* SingleJobSaga() {
  yield takeLatest(fetchBasicJobBoards, handleOnFetchBasicJobBoards);
  yield takeLatest(fetchProductsAndPackages, handleOnFetchProductsAndPackages);
  yield takeLatest(fetchJobDetails, handleOnFetchJobDetails);
  yield takeLatest(fetchJobApplications, handleOnFetchApplications);
  yield takeLatest(updateHeaderImage, handleOnUpdateHeaderImage);
  yield takeLatest(fetchJobTemplate, handleOnFetchJobTemplate);
  yield takeLatest(fetchBookings, handleOnFetchBookings);
  yield takeLatest(fetchJobData, handleOnFetchJobData);
  yield takeLatest(duplicateJob, handleOnDuplicateJob);
}

export default SingleJobSaga;
