// TODO REFACTOR (js -> TS)

import React, { useEffect, useMemo, useState } from "react";
import { Stack, Avatar, Typography } from "@mui/material";
import { MentionsInput, Mention } from "react-mentions";
import { EyeIcon, PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import ContentBox from "../ContentBox";
import Note from "../Note";
import { axiosInstance } from "../../services";
import { getSelectedJobOwners } from "../../store_new/selectors/Applications";
import { getCurrentUserData } from "../../store_new/selectors/CurrentUser";
import { fetchActivityLog } from "../../store_new/reducers/Applications";

const CandidateMessages = ({ info, displayAdditionalInfo }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const selectedJobOwners = useSelector(getSelectedJobOwners);
  const { memberId } = useSelector(getCurrentUserData);
  const [edit, setEdit] = useState({ note_id: null, text: "", active: false });
  const [jobOwners, setJobOwners] = useState([]);
  const [textAreaVal, setTextAreaVal] = useState("");
  const [action, setAction] = useState(false);
  const [notes, setNotes] = useState();
  const [newNote, setNewNote] = useState();
  const { application_id } = info;

  useEffect(() => {
    if (selectedJobOwners) {
      const formattedOwners = selectedJobOwners.map((item) => ({
        display: `@${item.firstname} ${item.lastname}`,
        name: `${item.firstname} ${item.lastname}`,
        id: item.id,
        image: item.profile_photo
      }));
      setJobOwners(formattedOwners);
    }
  }, [selectedJobOwners]);

  useEffect(() => {
    const getNotes = async () => {
      await axiosInstance
        .post(`/company/candidates/notes/${application_id}`, {})
        .then((res) => {
          const data = res?.data?.data.application_notes;
          setNotes(data);
          setAction(false);
        })
        .catch((err) => {
          if (err.name === "AbortError") {
            return console.error("Request Aborted");
          }
          console.error(err);
        });
    };

    if (application_id) {
      getNotes();
    }
  }, [application_id, action]);

  const handleCancel = () => {
    setNewNote("");
    setEdit();
  };

  const handleSave = async (e) => {
    e.preventDefault();

    await axiosInstance
      .post(`/company/candidates/add-note/${application_id}`, {
        note: formattedTextAreaText,
        tagged_ids: taggedIds
      })
      .then(() => {
        setTextAreaVal("");
        setAction(true);
        dispatch(fetchActivityLog(application_id));
        setTimeout(() => {
          const noteWraperContainer =
            document.querySelector("#message-wrapper");
          noteWraperContainer.scrollTop = noteWraperContainer.scrollHeight;
        }, 1000);
      })
      .catch((err) => console.error(err));
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    await axiosInstance
      .post(`/company/candidates/update-note/${application_id}`, {
        note_id: edit.note_id,
        note: formattedTextAreaText,
        tagged_ids: taggedIds
      })
      .then(() => {
        setNewNote("");
        setEdit("");
        setTextAreaVal("");
        setAction(true);
      })
      .catch((err) => console.error(err));
  };

  const reformatText = (string) => {
    const pattern = /@\[@(.*?)\]\((\d+)\)/g;
    return string.replace(pattern, "@$1");
  };

  const taggedIds = useMemo(() => {
    const ID_REGEX = /\[@(.*?)\]\((.*?)\)/g;
    const matches = textAreaVal.matchAll(ID_REGEX);
    const uniqueIds = new Set(Array.from(matches, (match) => match[2]));
    return Array.from(uniqueIds);
  }, [textAreaVal]);

  const formattedTextAreaText = useMemo(
    () => reformatText(textAreaVal),
    [textAreaVal]
  );

  const handleScroll = () => {
    const textarea = document.querySelector("textarea");

    textarea.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "start"
    });
    textarea.focus();
  };

  useEffect(() => {
    if (edit.note_id) {
      setTextAreaVal(edit.text);
    }
  }, [edit]);

  useEffect(() => {
    const mentionContainer = document.querySelector(".mentions--multiLine");
    if (displayAdditionalInfo) {
      mentionContainer.style.width = "78%";
    } else {
      mentionContainer.style.width = "94%";
    }
  }, [displayAdditionalInfo]);

  return (
    <ContentBox section="candidateMessages" handleScroll={handleScroll}>
      <div
        className={classes.wrapper}
        id="message-wrapper"
        style={{
          maxHeight: "798px",
          justifyContent: notes ? "flex-start" : "center",
          transition: "all 0.5s ease-in-out"
        }}
      >
        {notes?.length > 0 ? (
          notes.map((note, index) => (
            <Note
              handleScroll={handleScroll}
              selectedJobOwners={selectedJobOwners}
              data={note}
              key={index}
              currentUserId={memberId}
              application_id={application_id}
              setNewNote={setNewNote}
              setAction={setAction}
              setEdit={setEdit}
            />
          ))
        ) : (
          <div className={classes.noNotes}>
            <EyeIcon />
            <p>{t("candidate.no_notes")}</p>
          </div>
        )}
      </div>
      <div className={classes.writeComment}>
        <MentionsInput
          value={textAreaVal}
          onChange={(e) => {
            setTextAreaVal(e.target.value);
          }}
          markup="@{{__type__||__id__||__display__}}"
          placeholder={t("candidate.write_a_note")}
          className="mentions"
        >
          <Mention
            type="user"
            trigger="@"
            data={jobOwners}
            className="mentions__mention"
            renderSuggestion={(suggestion) => (
              <Stack direction="row" alignItems="center" spacing={1.5}>
                <Avatar
                  src={suggestion.image}
                  alt={suggestion.display}
                  sx={{ width: 32, height: 32 }}
                />
                <Typography
                  variant="subtitle2"
                  color="#131E30"
                  fontWeight={500}
                >
                  {suggestion.name}
                </Typography>
              </Stack>
            )}
          />
        </MentionsInput>
        {newNote && (
          <button className={classes.clear} onClick={handleCancel}>
            <XMarkIcon />
          </button>
        )}

        <button
          className={classes.button}
          onClick={(e) => {
            edit?.note_id ? handleUpdate(e) : handleSave(e);
          }}
        >
          <PlusIcon />
        </button>
      </div>
    </ContentBox>
  );
};

export default CandidateMessages;
