import React, { CSSProperties, useEffect, useState } from "react";
import ClickAwayListener from "react-click-away-listener";
import { Stack, Typography } from "@mui/material";
import { ChromePicker, ColorResult } from "react-color";
import { useSelector, useDispatch } from "react-redux";
import {
  StyledColorBlock,
  StyledColorBlockContainer,
  StyledColorPickerContainer,
  StyledPickerContainer,
} from "./styles";
import { updateJobDescription } from "../../store_new/reducers/JobEditor";
import { getJobEditorState } from "../../store_new/selectors/JobEditor";

const ColorPicker = ({
  color,
  title,
  value,
  customStyle,
  onChange,
}: {
  readonly color: string;
  readonly title: string;
  readonly value?: string;
  readonly customStyle: CSSProperties;
  readonly onChange?: (data: string) => void;
}) => {
  const dispatch = useDispatch();
  const [isDisplayed, setIsDisplayed] = useState(false);
  const [currentColor, setCurrentColor] = useState(color);

  const { jobDescription } = useSelector(getJobEditorState);

  const handleOnChange = (color: ColorResult) => {
    setCurrentColor(color.hex);
    onChange && onChange(color.hex);
    if (value) {
      dispatch(
        updateJobDescription({
          jobDescription: {
            ...jobDescription,
            [value]: color.hex,
          },
        })
      );
    }
  };

  useEffect(() => {
    setCurrentColor(color);
  }, [color]);

  return (
    <StyledColorPickerContainer direction={"row"} spacing={1}>
      <StyledColorBlockContainer direction={"row"} spacing={0.75} flex={2}>
        <StyledColorBlock
          bgColor={currentColor}
          onClick={() => {
            onChange && setIsDisplayed(true);
          }}
          isEditMode={Boolean(onChange)}
        ></StyledColorBlock>
        <Typography variant="subtitle2" color="textSecondary">
          {title}
        </Typography>
      </StyledColorBlockContainer>
      <StyledColorBlockContainer flex={1}>
        <Typography variant="subtitle2" color="textSecondary">
          {currentColor}
        </Typography>
      </StyledColorBlockContainer>
      <StyledPickerContainer style={customStyle}>
        {isDisplayed && (
          <ClickAwayListener
            onClickAway={() => {
              setIsDisplayed(false);
            }}
          >
            <Stack>
              <ChromePicker color={currentColor} onChange={handleOnChange} />
            </Stack>
          </ClickAwayListener>
        )}
      </StyledPickerContainer>
    </StyledColorPickerContainer>
  );
};

export default ColorPicker;
