import React, { useMemo, useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  MenuItem,
  Select,
} from "@mui/material";
import {
  ISelectorFilter,
  TSelectorFilterOption,
} from "../../../models/filters";
import { StyledPlaceholder } from "../styles";

const SelectorFilter = ({
  isDisabled,
  value,
  options,
  label,
  placeholder,
  error,
  className,
  hideCloseIcon = false,
  customMaxWidth,
  onChange,
}: ISelectorFilter) => {
  const formControlRef = useRef<any>();
  const showCloseIcon = value && !hideCloseIcon;
  const menuItemMaxWidth = useMemo(
    () => (formControlRef.current ? formControlRef.current.offsetWidth : 100),
    [formControlRef.current]
  );

  return (
    <FormControl size="small" ref={formControlRef}>
      <Select
        error={Boolean(error)}
        disabled={isDisabled}
        displayEmpty
        className={className}
        fullWidth
        id="select-filter"
        value={value}
        renderValue={
          value
            ? () =>
                options?.find(
                  (option: TSelectorFilterOption) => option.value === value
                )?.label
            : () =>
                placeholder ? (
                  <StyledPlaceholder>{placeholder}</StyledPlaceholder>
                ) : (
                  <StyledPlaceholder>{label}</StyledPlaceholder>
                )
        }
        onChange={(event) => onChange(event.target.value)}
        endAdornment={
          <InputAdornment position="start">
            {showCloseIcon && (
              <CloseIcon
                className={"clear-icon"}
                onClick={() => onChange("")}
              />
            )}
          </InputAdornment>
        }
      >
        {options?.map((option: TSelectorFilterOption, index: number) => (
          <MenuItem
            key={option.label + index}
            value={option.value}
            sx={{
              maxWidth: customMaxWidth ?? menuItemMaxWidth,
              whiteSpace: "break-spaces",
              wordBreak: "break-all",
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  );
};

export default SelectorFilter;
