import React, { ChangeEvent, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  IApplicationDocuments,
  TCandidateApplicationDocument,
} from "../../../models/ApplicationPage";
import {
  deleteCandidateDocument,
  downloadCandidateDocument,
  fetchCandidateApplications,
  uploadCandidateDocument,
} from "../../../store_new/reducers/Candidate";
import DocumentsComponent from "./component";
import CandidateDocumentsDeleteDialog from "./deleteDialog";
import { addMessage } from "../../../slices/NotificationSlice/GlobalNotificationSlice";
import { getCurrentUserIsCandidate } from "../../../store_new/selectors/CurrentUser";

const Documents_new = ({
  currentApplication,
  desktop,
}: IApplicationDocuments) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isCandidate = useSelector(getCurrentUserIsCandidate);
  const [docToDelete, setDocToDelete] =
    useState<TCandidateApplicationDocument | null>(null);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);

  const currentDocuments = useMemo(() => {
    if (currentApplication)
      return currentApplication.documents.map((doc) => ({
        ...doc,
        filename: doc.filename || (doc.link as string),
      }));
  }, [currentApplication]);

  const handleOnUpload = ({
    event,
    type,
  }: {
    event: ChangeEvent<HTMLInputElement>;
    type: "documents" | "cv";
  }) => {
    if (currentApplication && event.target?.files && event.target?.files[0]) {
      const docFile = event.target?.files[0];
      const jobUrlKey = currentApplication?.job.url_key;
      const maxFileSize = 5 * 1024 * 1024; // 5MB
      if (docFile.size > maxFileSize) {
        dispatch(
          addMessage({
            type: "error",
            title: t("candidate.document_upload_error"),
          })
        );
        return;
      }
      dispatch(
        uploadCandidateDocument({
          jobUrlKey,
          files: [docFile],
          type,
          callback: () => {
            handleOnUpdateDocumentsSuccess();
            dispatch(
              addMessage({
                type: "success",
                title: t("candidate.document_upload_success"),
              })
            );
          },
        })
      );
    }
  };

  const handleOnDownload = (doc: TCandidateApplicationDocument) => {
    if (currentApplication?.application_id || currentApplication?.id)
      dispatch(
        downloadCandidateDocument({
          doc: {
            ...doc,
            application_id:
              (parseInt(
                currentApplication.application_id as string
              ) as number) || (currentApplication.id as number), // TODO ask BE to make response the same for both sides
          },
        })
      );
  };

  const handleOnDelete = (doc: TCandidateApplicationDocument) => {
    setDocToDelete(doc);
    setIsOpenDeleteDialog(true);
  };

  const handleOnCloseDeleteDialog = () => {
    setIsOpenDeleteDialog(false);
  };

  const handleOnConfirmDelete = () => {
    if (docToDelete)
      dispatch(
        deleteCandidateDocument({
          doc: docToDelete,
          callback: () => {
            handleOnCloseDeleteDialog();
            handleOnUpdateDocumentsSuccess();
          },
        })
      );
  };

  const handleOnUpdateDocumentsSuccess = () => {
    dispatch(fetchCandidateApplications());
  };

  return (
    <>
      <DocumentsComponent
        t={t}
        isCandidate={Boolean(isCandidate)} // TODO refactor 'user' reducer/selector/saga
        documents={currentDocuments}
        desktop={desktop}
        onUpload={handleOnUpload}
        onDownload={handleOnDownload}
        onDelete={handleOnDelete}
      />
      <CandidateDocumentsDeleteDialog
        t={t}
        docName={
          docToDelete?.filename?.split("/")?.pop()?.split("_")?.pop() || ""
        }
        isOpen={isOpenDeleteDialog}
        onClose={handleOnCloseDeleteDialog}
        onConfirmDelete={handleOnConfirmDelete}
      />
    </>
  );
};

export default Documents_new;
