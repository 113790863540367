import React, { MouseEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import UserMenuComponent from "./component";
import {
  getCurrentUserData,
  getCurrentUserIsCandidate,
} from "../../../store_new/selectors/CurrentUser";
import { logOut } from "../../../store_new/reducers/Auth";
import { useNavigate } from "react-router-dom";

const UserMenu = ({ desktop }: { desktop: boolean }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isCandidate = useSelector(getCurrentUserIsCandidate);
  const { firstName, lastName, photo } = useSelector(getCurrentUserData);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleOnOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOnClose = () => {
    setAnchorEl(null);
  };

  const handleOnLogout = () => {
    dispatch(logOut());
  };

  return (
    <UserMenuComponent
      t={t}
      anchorEl={anchorEl}
      desktop={desktop}
      onOpen={handleOnOpen}
      isCandidate={isCandidate}
      navigate={navigate}
      firstName={firstName}
      lastName={lastName}
      avatarSrc={photo}
      onClose={handleOnClose}
      onLogout={handleOnLogout}
    />
  );
};

export default UserMenu;
