import React from "react";
import { Stack, CircularProgress } from "@mui/material";

export default function Loading({ height = "", size = 50 }) {
  return (
    <Stack
      width="100%"
      height={height || 500}
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress color="secondary" size={size} />
    </Stack>
  );
}
