import React from "react";
import { Checkbox } from "@mui/material";
import {
  CellClickedEvent,
  ColDef,
  ICellRendererParams,
  ValueGetterParams
} from "ag-grid-community";
import {
  DATA_GRID_LIST_TYPE,
  TDataGridGetColDef
} from "../../../models/DataGrid";
import ActionsCell from "../cellRenderers/actions";
import CVCell from "../cellRenderers/cv";
import StatusSelectorWithDaysInStatus from "../cellRenderers/statusSelectorWithDaysInStatus";
import StatusSelectorCell from "../cellRenderers/statusSelector";

export const getApplicationsColDef = ({
  t,
  actions,
  onSelect,
  type
}: Pick<
  TDataGridGetColDef,
  "t" | "actions" | "onSelect" | "type"
>): ColDef[] => [
  {
    field: "",
    width: 76,
    cellClass: "ag-grid-cell-checkbox",
    cellRenderer: (params: ICellRendererParams) => (
      <Checkbox
        onClick={() => onSelect && onSelect(params.data)}
        color="secondary"
      />
    )
  },
  {
    field: "name",
    headerName: t("applications.name") as string,
    valueGetter: (params: ValueGetterParams) => {
      const { firstname, lastname } = params.data;
      return `${firstname} ${lastname}`;
    },
    flex: 0.6,
    cellClass: "ag-grid-cell-clickable",
    onCellClicked: (params: CellClickedEvent) => {
      actions.onEdit && actions.onEdit(params.data);
    }
  },
  {
    field: "location",
    headerName: t("joblist.location_placeholder") as string,
    flex: 0.5,
    maxWidth: 200,
    valueGetter: (params: ValueGetterParams) => params.data["location"] || "-"
  },
  {
    field: "documents",
    headerName: "CV",
    flex: 0.7,
    maxWidth: 260,
    cellRenderer: (params: ICellRendererParams) => (
      <CVCell
        cv={params.data.documents[0]}
        onDownloadCV={actions.onDownloadCV}
      />
    )
  },
  {
    field: "title",
    headerName: t("applications.applied_to") as string,
    flex: 0.7,
    maxWidth: 260,
    hide: type === DATA_GRID_LIST_TYPE.APPLICATION_LIST_SINGLE_JOB
  },
  {
    field: "timestamp",
    headerName: t("applications.date_applied") as string,
    flex: 0.6,
    maxWidth: 150,
    valueGetter: (params: ValueGetterParams) =>
      new Date(params.data["timestamp"]).toLocaleDateString()
  },
  {
    field: "status",
    cellClass: "ag-grid-status-with-days",
    flex: 0.6,
    maxWidth: 200,
    cellRenderer: (params: ICellRendererParams) =>
      StatusSelectorWithDaysInStatus({
        params,
        actions
      })
  },
  {
    field: "actions",
    headerName: "",
    sortable: false,
    flex: 0.3,
    maxWidth: 70,
    cellStyle: { justifyContent: "center" },
    cellRenderer: (params: ICellRendererParams) =>
      ActionsCell({
        t,
        actions: {
          onDownloadCV: params.data.documents?.length
            ? () =>
                actions.onDownloadCV &&
                actions.onDownloadCV(params.data.documents[0].link)
            : undefined,
          onExport: () => actions.onExport && actions.onExport(params.data),
          onRemove: () => actions.onRemove && actions.onRemove(params.data)
        },
        type: params.data.status,
        forceMultiple: true
      })
  }
];

export const getSimpleApplicationsColDef = ({
  t
}: Pick<TDataGridGetColDef, "t">): ColDef[] => [
  {
    field: "name",
    cellStyle: { cursor: "pointer" },
    headerName: t("applications.name") as string,
    valueGetter: (params: ValueGetterParams) => {
      const { firstname, lastname } = params.data;
      return `${firstname} ${lastname}`;
    },
    flex: 1
  },
  {
    field: "title",
    cellStyle: { cursor: "pointer" },
    headerName: t("applications.applied_to") as string,
    flex: 1
  },
  {
    field: "timestamp",
    cellStyle: { cursor: "pointer" },
    headerName: t("applications.date_applied") as string,
    flex: 1,
    valueGetter: (params: ValueGetterParams) =>
      new Date(params.data["timestamp"]).toLocaleDateString()
  },
  {
    field: "status",
    cellStyle: { cursor: "pointer" },
    cellClass: "ag-grid-status-with-days",
    flex: 0.55,
    cellRenderer: (params: ICellRendererParams) =>
      StatusSelectorCell({
        label: params.value,
        type: params.value
      })
  }
];
