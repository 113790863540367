import React, { Suspense, useEffect, useMemo } from "react";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import { createTheme, StyledEngineProvider } from "@mui/material/styles";
import { CssBaseline, Stack, useMediaQuery } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Sidebar from "./Sidebar";
import JobEditor from "../../components/JobEditor";
import CompanyAddressDialog from "../../components/JobDialog/components/CompanyAdressDialog";
import InviteTeamMemberDialog from "../../components/dialogs/InviteTeamMemberDialog";
import TopHeader_new from "../../components/TopHeader_new";
import ChangePasswordDialog from "../../components/dialogs/ChangePasswordDialog";
import Checkout from "../../components/Checkout";
import Cart from "../../components/Cart";
import DeleteDialog from "../../components/dialogs/DeleteDialog";
import SelectCustomerDialog from "../../components/dialogs/SelectCustomerDialog";
import DuplicateJobDialog from "../../components/dialogs/DuplicateJobDialog";
import AddUpdateTalentPoolDialog from "../../components/dialogs/AddUpdateTalentPoolDialog";
import ExtendLimitDialog from "../../components/dialogs/ExtendLimitDialog";
import LimitConfirmationDialog from "../../components/dialogs/ExtendLimitConfirmationDialog";
import UploadPictureDialog from "../../components/dialogs/UploadPictureDialog";
import { GlobalToastNotificationsComponent } from "../../components/Snackbar";
import { Dark } from "../Default/mods/Dark";
import { StyledMainLayoutWrapper } from "./styles";
import {
  addMessage,
  globalNotificationSliceSelectors,
  setCreatePasswordSnackbar,
  setNotifications,
} from "../../slices/NotificationSlice/GlobalNotificationSlice";
import { ACTION_TYPE } from "../../components/Snackbar/RenderAction";
import { getCurrentUserData } from "../../store_new/selectors/CurrentUser";
import { translationDe } from "../../utils/translationDe";
import { translationEn } from "../../utils/translationEn";
import { RootState } from "../../store_new/config";
import {
  openJobEditor,
  updateCompanyId,
} from "../../store_new/reducers/JobEditor";
import {
  extendLimit,
  setIsLimitConfirmationDialogOpen,
  setIsLimitDialogOpen,
  setIsTalentPoolsDialogOpen,
  setIsSelectCustomerDialogOpen,
  setIsChangePasswordDialogOpen,
  setIsPasswordNotificationShowed,
  setIsUploadPictureDialogOpen,
} from "../../store_new/reducers/dialogs";
import { DIALOG_TYPE } from "../../models/common";
import { ITalentPool } from "../../models/TalentPools";
import { getDialogsState } from "../../store_new/selectors/Dialogs";
import { getTalentPoolState } from "../../store_new/selectors/Talentpool";
import {
  addUpdateTalentPool,
  deleteTalentPool,
  fetchTalentPools,
  setEditedTalentPool,
  setSelectedTalentPool,
} from "../../store_new/reducers/TalentPools";
import { fetchCompanyData } from "../../store_new/reducers/CompanySettings";
import { getCompanySettingsCompanyState } from "../../store_new/selectors/Company";
import ArchiveRestoreJobDialog from "../../components/dialogs/ArchiveRestoreJobDialog";

const colorPickerStyle = {
  bottom: "16.5%",
  marginLeft: -265,
};

const MainLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = useParams();
  const { t } = useTranslation();
  const { mobileDrawerOpen } = useSelector((state: any) => state.navigation);
  const { lang, noPassword } = useSelector(getCurrentUserData);
  const {
    talentPoolsDialogType,
    isLimitDialogOpen,
    isLimitConfirmationDialogOpen,
    isChangePasswordDialogOpen,
    isPasswordNotificationShowed,
  } = useSelector(getDialogsState);
  const { editedTalentPool, selectedTalentPool } =
    useSelector(getTalentPoolState);

  const appliedTheme = createTheme(Dark as any);
  const desktop = useMediaQuery(appliedTheme?.breakpoints.up("sm"));

  const { isCartOpen } = useSelector((state: RootState) => state.job); // refactor
  const notifications = useSelector(
    globalNotificationSliceSelectors.notifications // refactor
  );

  const { company } = useSelector(getCompanySettingsCompanyState);

  const noPasswordSnackbarTitle = useMemo(() => {
    const snackbarText =
      lang === "en_US"
        ? translationEn.userSettings.no_password_snackbar
        : translationDe.userSettings.no_password_snackbar;
    return snackbarText;
  }, [lang]);

  const noPasswordSnackbarButton = useMemo(() => {
    const snackbarButton =
      lang === "en_US"
        ? translationEn.userSettings.create_password
        : translationDe.userSettings.create_password;
    return snackbarButton;
  }, [lang]);

  const onCloseSelectCustomerDialog = () => {
    dispatch(setIsSelectCustomerDialogOpen(false));
  };

  const onSubmitSelectedJobDialog = (id: number) => {
    dispatch(updateCompanyId(id));
    if (company?.jobs_creation_allowed) {
      dispatch(openJobEditor());
    } else {
      dispatch(setIsLimitDialogOpen(true));
    }
  };

  const handleOnCloseTalentPoolDialog = () => {
    dispatch(setEditedTalentPool(null));
    dispatch(setIsTalentPoolsDialogOpen(null));
  };

  const onSuccessCreateUpdateTalentPool = () => {
    if (editedTalentPool?.id) {
      dispatch(
        addMessage({
          type: "success",
          title: t("talentPools.successTalentPoolEdited"),
        })
      );
    } else {
      dispatch(
        addMessage({
          type: "success",
          title: t("talentPools.successTalentPoolCreated"),
        })
      );
      dispatch(fetchCompanyData());
    }

    if (selectedTalentPool) {
      dispatch(setSelectedTalentPool(editedTalentPool as ITalentPool));
    }
    handleOnCloseTalentPoolDialog();
  };

  const handleOnCreateUpdateTalentPool = () => {
    const formattedTalentPool = {
      ...editedTalentPool,
      color: editedTalentPool?.color || "#178CF2",
    };
    dispatch(
      addUpdateTalentPool({
        talentPool: formattedTalentPool,
        callback: () => onSuccessCreateUpdateTalentPool(),
      })
    );
  };

  const handleOnChangeTalentPullValue = (type: string, value: string) => {
    dispatch(
      setEditedTalentPool({
        ...editedTalentPool,
        [type]: value,
      })
    );
  };

  const onSuccessDeleteTalentPool = () => {
    dispatch(fetchCompanyData());
    handleOnCloseTalentPoolDialog();
    dispatch(fetchTalentPools());
    dispatch(
      addMessage({
        title: t("talentPools.successTalentPoolDeleted"),
        type: "success",
      })
    );
    if (id) {
      navigate("/talentpools");
    }
  };

  const handleOnDeleteTalentPool = () => {
    dispatch(
      deleteTalentPool({
        id: editedTalentPool?.id || selectedTalentPool?.id,
        callback: () => onSuccessDeleteTalentPool(),
      })
    );
  };

  const handleOnCloseLimitDialog = () => dispatch(setIsLimitDialogOpen(false));

  const handleOnCloseConfirmationLimitDialog = () =>
    dispatch(setIsLimitConfirmationDialogOpen(false));

  const onSuccessExtendLimit = () => {
    handleOnCloseLimitDialog();
    dispatch(setIsLimitConfirmationDialogOpen(true));
  };

  const handleOnExtendLimit = () => {
    dispatch(
      extendLimit({
        callback: () => onSuccessExtendLimit(),
      })
    );
  };

  useEffect(() => {
    if (noPassword && !isPasswordNotificationShowed) {
      dispatch(
        addMessage({
          icon: <ReportProblemOutlinedIcon />,
          type: "secondary",
          title: noPasswordSnackbarTitle,
          visibilityTime: "allTime",
          action: ACTION_TYPE.CONTAINED_BUTTON_WITH_CLOSE_ICON,
          firstButtonText: noPasswordSnackbarButton,
          onFirstButtonClick: () =>
            dispatch(setIsChangePasswordDialogOpen(true)),
        })
      );
      dispatch(setIsPasswordNotificationShowed(true));
    } else {
      if (notifications.length > 1 && !isPasswordNotificationShowed) {
        dispatch(setNotifications());
      }
    }
  }, [noPassword]);

  useEffect(() => {
    if (notifications && isPasswordNotificationShowed) {
      dispatch(
        setCreatePasswordSnackbar({
          title: noPasswordSnackbarTitle,
          firstButtonText: noPasswordSnackbarButton,
        })
      );
    }
  }, [noPasswordSnackbarTitle, noPasswordSnackbarButton]);

  useEffect(() => {
    const redirectionUrl = sessionStorage.getItem("redirection-url");
    if (redirectionUrl) {
      navigate(redirectionUrl);
      sessionStorage.removeItem("redirection-url");
    }
  }, []);

  useEffect(() => {
    const discoverPageScrollRoutes = ["/discover", "/package", "/product"];
    if (!discoverPageScrollRoutes.includes(location.pathname)) {
      sessionStorage.removeItem("discover-scroll");
    }
  }, [location]);

  return (
    <StyledEngineProvider injectFirst>
      <Suspense fallback={"Loading..."}>
        <CssBaseline />
        <StyledMainLayoutWrapper direction="row" position="relative">
          <GlobalToastNotificationsComponent desktop={desktop} />
          <Stack
            height="100%"
            display={mobileDrawerOpen || desktop ? "block" : "none"}
          >
            <Sidebar desktop={desktop} mobileDrawerOpen={mobileDrawerOpen} />
          </Stack>
          <Stack
            margin={desktop ? "0 auto" : 0}
            padding={desktop ? 4 : 3}
            width={"100%"}
            minHeight={"100%"}
          >
            <TopHeader_new desktop={desktop} />
            <Outlet />
          </Stack>
        </StyledMainLayoutWrapper>
        <Checkout />
        <JobEditor />
        <CompanyAddressDialog />
        <ExtendLimitDialog
          t={t}
          isOpen={isLimitDialogOpen}
          onClose={handleOnCloseLimitDialog}
          onExtendLimit={handleOnExtendLimit}
        />
        <LimitConfirmationDialog
          t={t}
          isOpen={isLimitConfirmationDialogOpen}
          onClose={handleOnCloseConfirmationLimitDialog}
        />
        <InviteTeamMemberDialog />
        <SelectCustomerDialog
          t={t}
          onClose={onCloseSelectCustomerDialog}
          onSave={onSubmitSelectedJobDialog}
        />
        <DuplicateJobDialog />
        <ChangePasswordDialog
          t={t}
          isOpen={isChangePasswordDialogOpen}
          desktop={desktop}
        />
        <AddUpdateTalentPoolDialog
          t={t}
          colorPickerStyle={colorPickerStyle}
          isOpen={talentPoolsDialogType === DIALOG_TYPE.CREATE_EDIT_TALENT_POOL}
          talentPool={editedTalentPool}
          onClose={handleOnCloseTalentPoolDialog}
          onChangeTalentPullValue={handleOnChangeTalentPullValue}
          onCreateUpdateTalentPool={handleOnCreateUpdateTalentPool}
        />
        <DeleteDialog
          t={t}
          isOpen={talentPoolsDialogType === DIALOG_TYPE.DELETE_TALENT_POOL}
          title={t("talentPools.deleteTalentPoolTitle")}
          body={t("talentPools.deleteTalentPoolFirstSubTitle")}
          customDeleteButtonText={t("button.save") as string}
          secondBody={t("talentPools.deleteTalentPoolSecondSubTitle") as string}
          onCancel={handleOnCloseTalentPoolDialog}
          onDelete={handleOnDeleteTalentPool}
        />
        <UploadPictureDialog
          t={t}
          desktop={desktop}
          onClose={() => dispatch(setIsUploadPictureDialogOpen(false))}
        />
        <ArchiveRestoreJobDialog />
        {isCartOpen && <Cart t={t} isCartOpen={isCartOpen} />}
      </Suspense>
    </StyledEngineProvider>
  );
};

export default MainLayout;
