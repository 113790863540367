import React, { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { TApplication } from "../../models/Applications";
import {
  deleteApplication,
  fetchApplications,
  resetJobOwnersAndActivities,
  resetSelectedApplications,
  setApplicationFilters,
  setPageNum,
  setSelectedApplications,
  setSelectedJobApplications,
  setView,
  updateApplicationStatus,
} from "../../store_new/reducers/Applications";
import {
  getApplicationListState,
  getApplicationsFiltersState,
  getApplicationsIsListLoading,
  getApplicationsPaginationState,
  getApplicationsViewState,
  getSelectedApplications,
} from "../../store_new/selectors/Applications";
import { getSingleJobState } from "../../store_new/selectors/SingleJob";
import ApplicationsComponent from "./component";
import { TSelectedJobCandidateApplication } from "../../models/ApplicationPage";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getCurrentUserData } from "../../store_new/selectors/CurrentUser";
import { fetchJobApplications } from "../../store_new/reducers/SingleJob";
import { getAllActiveJobs } from "../../store_new/selectors/JobList";
import { fetchAllActiveJobs } from "../../store_new/reducers/JobList";
import { DIALOG_TYPE } from "../../models/common";
import { addMessage } from "../../slices/NotificationSlice/GlobalNotificationSlice";
import { deleteCandidate } from "../../store_new/reducers/TalentPools";
import { getTalentPoolState } from "../../store_new/selectors/Talentpool";
import { ITalentPool } from "../../models/TalentPools";

const Applications_new = ({
  containerMargin = 4,
  showAddApplicationButton,
}: {
  readonly containerMargin?: number;
  readonly showAddApplicationButton?: boolean;
  readonly showPremiumButton?: boolean;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { list, totalCount } = useSelector(getApplicationListState);
  const isListLoading = useSelector(getApplicationsIsListLoading);
  const { pageNum, pageSize } = useSelector(getApplicationsPaginationState);
  const { quickSearch, status, appliedTo, location, company } = useSelector(
    getApplicationsFiltersState
  );
  const { jobId } = useSelector(getSingleJobState);
  const allActiveJobs = useSelector(getAllActiveJobs);
  const selectedApplications = useSelector(getSelectedApplications);
  const view = useSelector(getApplicationsViewState);
  const localView: string | null = localStorage.getItem("view");
  const { isAgency, applicationsAllowed } = useSelector(getCurrentUserData);
  const { talentPools } = useSelector(getTalentPoolState);

  const CSVLinkRef = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);

  const [csvData, setCsvData] = useState<TApplication[] | null>(null);
  const [dialogType, setDialogType] = useState<DIALOG_TYPE | null>(null);
  const [deleteApplicationId, setDeleteApplicationId] = useState<
    string | number | null
  >(null);

  const handleOnPaginate = (pageNum: number) => {
    dispatch(setPageNum(pageNum));
  };

  const handleOnEdit = (application: TSelectedJobCandidateApplication) => {
    dispatch(setSelectedJobApplications([application]));
    if (jobId) {
      navigate(
        `/jobs/applications/candidate/${application.candidate_url_key}/${application.job_id}`
      );
    } else {
      navigate(
        `/applications/candidate/${application.candidate_url_key}/${application.job_id}`
      );
    }
  };

  const handleOnExport = (applications: TApplication | TApplication[]) => {
    setCsvData(Array.isArray(applications) ? applications : [applications]);
    setTimeout(() => {
      CSVLinkRef.current && CSVLinkRef.current.link.click();
    });
  };

  const onSuccessChangeApplicationStatus = ({
    isTalentPooled,
    talentPoolTitle,
    applicationId,
  }: {
    readonly isTalentPooled: 0 | 1;
    readonly talentPoolTitle: string | null;
    readonly applicationId: string | number;
  }) => {
    if (isTalentPooled) {
      const findTalentPool = talentPools.find(
        (item: ITalentPool) => item.title === talentPoolTitle
      );
      dispatch(
        deleteCandidate({
          id: findTalentPool?.id as number,
          applicationId: applicationId as number,
          callback: () => dispatch(fetchApplications({ withoutLoader: true })),
        })
      );
    } else {
      dispatch(fetchApplications({ withoutLoader: true }));
    }
  };

  const handleOnChangeStatus = ({
    isTalentPooled,
    talentPoolTitle,
    applicationId,
    newStatus,
    index,
  }: {
    readonly isTalentPooled: 0 | 1;
    readonly talentPoolTitle: string | null;
    readonly applicationId: string;
    readonly newStatus: string;
    readonly index: string;
  }) => {
    dispatch(
      updateApplicationStatus({
        applicationId,
        newStatus,
        index: index ?? "0",
        callback: () =>
          onSuccessChangeApplicationStatus({
            applicationId,
            isTalentPooled,
            talentPoolTitle,
          }),
      })
    );
  };

  const handleOnDownloadCV = (cvLink: string) => {
    window.open(cvLink, "_blank");
  };

  const handleOnSelect = (application: TApplication) => {
    dispatch(setSelectedApplications(application));
  };

  const handleOnOpenDialog = (
    params: TApplication | null,
    type: DIALOG_TYPE | null
  ) => {
    if (params?.application_id) {
      setDeleteApplicationId(params.application_id);
    }
    setDialogType(type);
  };

  const handleOnCloseDialog = () => {
    setDialogType(null);
    setDeleteApplicationId(null);
  };

  const onSuccessDeleteAppliccation = () => {
    dispatch(fetchApplications());
    handleOnCloseDialog();
    dispatch(
      addMessage({
        type: "success",
        title: t("applications.delete_application_snackbar"),
      })
    );
  };

  const handleOnDeleteApplication = () => {
    dispatch(
      deleteApplication({
        applicationId: deleteApplicationId as number,
        callback: () => onSuccessDeleteAppliccation(),
      })
    );
  };

  useEffect(() => {
    if (isAgency && !applicationsAllowed) {
      navigate("/jobs/active");
    }
  }, [isAgency]);

  useEffect(() => {
    if (view === "cards") {
      dispatch(
        setApplicationFilters({
          filterType: "status",
          value: "",
        })
      );
    }
  }, [view]);

  useEffect(() => {
    dispatch(resetSelectedApplications());
  }, [list]);

  useEffect(() => {
    if (!localView) {
      localStorage.setItem("view", view);
    } else if (localView && localView !== view && jobId) {
      dispatch(setView(localView));
    }
    dispatch(resetJobOwnersAndActivities());
  }, []);

  useEffect(() => {
    if (view === "table") {
      dispatch(fetchApplications());
    }
  }, [
    quickSearch,
    location,
    status.value,
    appliedTo.value,
    pageNum,
    isAgency,
    company.value,
  ]);

  useEffect(() => {
    if (localView && localView === "cards" && jobId) {
      dispatch(
        fetchJobApplications({ jobId: jobId as string, callback: () => {} })
      );
    } else {
      dispatch(fetchApplications());
    }
  }, [view, isAgency]);

  useEffect(() => {
    if (!allActiveJobs) {
      dispatch(fetchAllActiveJobs());
    }
  }, []);

  return (
    <>
      <CSVLink
        data={csvData || []}
        className="hidden"
        ref={CSVLinkRef}
        filename={csvData?.[0]?.title || "Job"}
      />
      <ApplicationsComponent
        t={t}
        containerMargin={containerMargin}
        showAddApplicationButton={showAddApplicationButton}
        showPremiumButton={!!jobId}
        applicationsCount={totalCount}
        list={list}
        isListLoading={isListLoading}
        selectedApplications={selectedApplications}
        pageNum={pageNum}
        pageSize={pageSize}
        view={view}
        jobId={jobId}
        dialogType={dialogType}
        onPaginate={handleOnPaginate}
        onSelect={handleOnSelect}
        onDeleteApplication={handleOnDeleteApplication}
        onOpenDialog={handleOnOpenDialog}
        onCloseDialog={handleOnCloseDialog}
        actions={{
          onEdit: handleOnEdit,
          onExport: handleOnExport,
          onChangeStatus: handleOnChangeStatus,
          onDownloadCV: handleOnDownloadCV,
          onRemove: (params: TApplication) =>
            handleOnOpenDialog(params, DIALOG_TYPE.DELETE),
        }}
      />
    </>
  );
};

export default Applications_new;
