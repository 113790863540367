import { Stack, Menu } from "@mui/material";
import { styled } from "@mui/system";

export const StyledFloatingMenu = styled(Stack)({
  zIndex: 2,
  padding: 8,
  flexDirection: "row",
  position: "fixed",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bottom: 0,
  background: "#131E30",
  gap: 8,
  borderRadius: 6,
});

export const StyledFloatingMenuItem = styled(Stack)({
  width: 124,
  height: 64,
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  gap: 6,
  borderRadius: 6,
  fontSize: 12,
  color: "#fff",
  "& svg": {
    width: 20,
    height: 20,
    stroke: "#fff",
    strokeWidth: 1,
  },
  "&:hover": {
    backgroundColor: "#374151",
  },
});

export const StyledStatusMenu = styled(Menu)({
  marginTop: -73,
  marginLeft: -6,
  "& .MuiMenu-paper ": {
    padding: 0,
    border: "1px solid #E5E7EB",
    borderRadius: 6,
    boxShadow:
      "0px 2px 4px -2px rgba(16, 24, 40, 0.10), 0px 4px 6px -1px rgba(16, 24, 40, 0.10)",
  },
  "& .MuiList-root": {
    margin: 0,
    padding: 0,
    cursor: "pointer",
  },
  "& .MuiMenuItem-root": {
    height: 40,
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
});
