import {
  TApiGetApplicationsPayload,
  TApiGetAllApplicationsPayload,
  TApiUpdateApplicationsStatusPayload,
  TApiGetApplicationAnswers,
  TApiGetAllApplicationsByJobId,
} from "../models/Applications";
import { apiRequest } from "./config";

export const apiGetApplications = ({
  url,
  apiPayload,
}: {
  url: string;
  apiPayload?: TApiGetApplicationsPayload | TApiGetAllApplicationsByJobId;
}) => apiRequest({ type: "post", url, apiPayload });

export const apiGetAllApplications = ({
  url,
  apiPayload,
}: {
  url: string;
  apiPayload: TApiGetAllApplicationsPayload;
}) => apiRequest({ type: "post", url, apiPayload });

export const apiGetSelectedJobApplications = ({
  url,
  apiPayload,
}: {
  url: string;
  apiPayload: { job_id: string };
}) => apiRequest({ type: "post", url, apiPayload });

export const apiUpdateApplicationStatus = ({
  url,
  apiPayload,
}: {
  url: string;
  apiPayload: TApiUpdateApplicationsStatusPayload;
}) => apiRequest({ type: "post", url, apiPayload });

export const apiGetCandidateAnswers = ({
  url,
  apiPayload,
}: {
  url: string;
  apiPayload: TApiGetApplicationAnswers;
}) => apiRequest({ type: "post", url, apiPayload });

export const apiFetchSelectedJobOwners = ({ url }: { url: string }) =>
  apiRequest({ type: "post", url });

export const apiFetchActivityLog = ({ url }: { url: string }) =>
  apiRequest({ type: "post", url });

export const apiDeleteApplication = ({ url }: { url: string }) =>
  apiRequest({ type: "post", url });

export const apiUpdateApplicationAnswers = ({
  apiPayload,
  applicationId,
}: {
  apiPayload: any;
  applicationId: string;
}) =>
  apiRequest({
    type: "post",
    url: `/company/candidates/update-application/${applicationId}`,
    apiPayload,
  });

export const apiGetJobApplicationFromTalentPool = ({
  applicationId,
}: {
  applicationId: number;
}) =>
  apiRequest({
    type: "post",
    url: `/company/candidates/application/${applicationId}`,
  });
