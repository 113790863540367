import React from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import Header from "./Header";
import RenderItem from "./RenderItem";
import { StyledColumn, StyledList } from "./style";
import { IColumnComponent } from "../../models/Kanban";

const Column = ({
  t,
  columnId,
  column,
  color,
  title,
  length,
  variant,
  selectedItems,
  handleSelectItem,
  onItemClick,
}: IColumnComponent) => {
  return (
    <StyledColumn key={columnId}>
      <Header title={title} length={length} color={color} />
      <Droppable droppableId={columnId} key={columnId}>
        {(provided) => (
          <StyledList {...provided.droppableProps} ref={provided.innerRef}>
            {column.items.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided) => (
                  <RenderItem
                    t={t}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    variant={variant}
                    ref={provided.innerRef}
                    provided={provided}
                    data={item}
                    selectedItems={selectedItems}
                    handleSelectItem={handleSelectItem}
                    onItemClick={onItemClick}
                  />
                )}
              </Draggable>
            ))}
          </StyledList>
        )}
      </Droppable>
    </StyledColumn>
  );
};

export default Column;
