import { Stack, Typography } from "@mui/material";
import { styled } from "@mui/system";

export const StyledList = styled(Stack)({
  borderRadius: 8,
  padding: "10px 8.18px",
  height: "100%",
  flexGrow: 1,
  gap: 8,
  overflow: "scroll",
  "&::-webkit-scrollbar ": {
    width: "0px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "transparent",
  },
});

export const StyledColumns = styled(Stack)({
  display: "grid",
  height: "100%",
  width: "100%",
  gap: "16px",
});

export const StyledColumn = styled(Stack)({
  background: "#F3F4F6",
  height: window.innerHeight - 220,
  borderRadius: 6,
  overflowY: "auto",
  "&::-webkit-scrollbar ": {
    width: "4px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#E5E7EB",
    borderRadius: "4px",
  },
});

export const StyledCandidateItem = styled(Stack)({
  cursor: "pointer !important",
  borderRadius: 6,
  "&:hover": {
    boxShadow: `0px 0px 0px 0px rgba(37, 46, 55, 0.06),
                0px 7px 15px 0px rgba(37, 46, 55, 0.06),
                0px 27px 27px 0px rgba(37, 46, 55, 0.05),
                0px 60px 36px 0px rgba(37, 46, 55, 0.03),
                0px 106px 43px 0px rgba(37, 46, 55, 0.01),
                0px 166px 47px 0px rgba(37, 46, 55, 0.00)`,
  },
});

export const StyledCandidateItemElement = styled(Stack)({
  flexDirection: "row",
  gap: 8,
  "& svg": {
    position: "relative",
    top: 1.5,
    width: 16,
    height: 16,
    stroke: "#178CF2",
    strokeWidth: 1,
  },
});

export const StyledStatusInColumnText = styled(Typography)(({
  type,
}: {
  type: string;
}) => {
  const baseStyle = {
    fontSize: 12,
    lineHeight: "20px",
  };
  switch (type) {
    case "normal":
      return {
        ...baseStyle,
        color: "#6B7280",
      };
    case "orange":
      return {
        ...baseStyle,
        color: "#D97706",
      };
    case "red":
      return {
        ...baseStyle,
        color: "#DC2626",
      };
    default:
  }
  return {
    ...baseStyle,
    color: "#6B7280",
  };
});
