import { PayloadAction } from "@reduxjs/toolkit";
import { TFunction } from "i18next";
import { ReactNode, RefObject } from "react";

export interface IChipsManager {
  readonly chipList?: string[];
  readonly placeholder?: ReactNode;
  readonly validation?: {
    readonly validationFunc: (val: string) => boolean;
    readonly errorMessage: string;
  };
  readonly onChange: (chipList: string[]) => void;
}

export interface IChipsManagerComponent {
  readonly chip: string;
  readonly chipList?: string[];
  readonly placeholder?: ReactNode;
  readonly errorMessage?: string;
  readonly onAdd: () => void;
  readonly onChange: (value: string) => void;
  readonly onDelete: (chipIndex: number) => void;
}

export interface IExpandedBlock {
  readonly t: TFunction;
  readonly title?: string;
  readonly body?: string;
}

export interface IExpandedBlockComponent {
  t: TFunction;
  title?: string;
  body?: string;
  bodyRef: RefObject<HTMLElement>;
  isLongText: boolean;
  isExpanded: boolean;
  isRendered: boolean;
  onExpand: () => void;
}

export type TListOption = {
  readonly label: string;
  readonly value: string;
};

export type TCountryListOption = {
  readonly label: string;
  readonly value: string;
  readonly iso: string;
};

export type TAgencyListOption = {
  readonly label: string;
  readonly value: string;
  readonly companyId: number;
};

export enum DIALOG_TYPE {
  CONNECTED = "connected",
  DELETE = "delete",
  DELETE_JOB = "deleteJob",
  INVITE = "invite",
  ARCHIVE_RESTORE = "archiveRestore",
  UPLOAD_IMAGE = "uploadImage",
  DISCONNECT = "disconnect",
  SYNC_CUSTOMERS = "syncCustomers",
  CREATE_EDIT_TALENT_POOL = "createEditTalentPool",
  DELETE_TALENT_POOL = "deleteTalentPool",
  ADD_CANDIDATE_TO_JOB = "addCandidateToJob",
  APPLY_JOB = "applyJob",
  CHANGE_PASSWORD = "changePassword",
  ADD_PROFILE = "addProfile"
}

export type PayloadActionWithCallback<T> = T extends undefined
  ? PayloadAction<{
      callback: (data?: any) => void;
    }>
  : PayloadAction<T & { callback: (data?: any) => void }>;
