import { Box, Stack } from "@mui/material";
import { styled } from "@mui/system";

export const StyledColorPickerContainer = styled(Stack)({
  height: 40,
  wdith: "100%",
});

export const StyledColorBlockContainer = styled(Stack)({
  alignItems: "center",
  padding: 8,
  borderRadius: 6,
  border: "1px solid #E5E7EB",
  h6: { lineHeight: "24px" },
});

export const StyledColorBlock = styled(Box)(
  ({ bgColor, isEditMode }: { bgColor: string; isEditMode?: boolean }) => ({
    width: 24,
    height: 24,
    border: "1px solid #E5E7EB",
    borderRadius: 6,
    background: bgColor,
    ...(isEditMode && { cursor: "pointer" }),
  })
);

export const StyledPickerContainer = styled(Stack)({
  position: "fixed",
  zIndex: 2,
});
