import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useFieldArray } from "react-hook-form";
import AdditionalQuestionsLanguagesComponent from "./component";
import { IAdditionalQuestionSimpleSection } from "../../../../models/JobPreview";
import { getJobPreviewState } from "../../../../store_new/selectors/JobPreview";

const AdditionalQuestionsLanguages = ({
  control,
  desktop,
  companyColor,
  boldHeading,
  isEditDialog,
}: IAdditionalQuestionSimpleSection) => {
  const { t } = useTranslation();
  const {
    jobData: { languages, languageLevels },
  } = useSelector(getJobPreviewState);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "languages",
  });

  const handleOnAddLanguage = () => {
    append({ language: "", level: "" });
  };
  const handleOnRemoveLanguage = (index: number) => {
    remove(index);
  };

  const { modifiedLanguages, modifiedLanguageLevels } = useMemo(() => {
    let modifiedLanguages;
    let modifiedLanguageLevels;
    if (languages)
      modifiedLanguages = languages.map(
        (lang: { id: string; name: string }) => ({
          label: lang.name,
          value: lang.name,
        })
      );
    if (languageLevels)
      modifiedLanguageLevels = languageLevels.map(
        (lang: { id: string; name: string }) => ({
          label: lang.name,
          value: lang.name,
        })
      );

    return { modifiedLanguages, modifiedLanguageLevels };
  }, [languages, languageLevels]);

  return (
    <AdditionalQuestionsLanguagesComponent
      t={t}
      control={control}
      desktop={desktop}
      fields={fields}
      languages={modifiedLanguages || []}
      companyColor={companyColor}
      languageLevels={modifiedLanguageLevels}
      boldHeading={boldHeading}
      isEditDialog={isEditDialog}
      onAdd={handleOnAddLanguage}
      onRemove={handleOnRemoveLanguage}
    />
  );
};

export default AdditionalQuestionsLanguages;
