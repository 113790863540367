import React from "react";
import { PlusCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { Button, InputLabel, MenuItem, Stack, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import { IJobAdditionalQuestionsSection } from "../../../models/JobEditor";
import { StyledSelect } from "../../../pages/CompanySettings_new/styles";
import SectionTitle from "../../SectionTitle";
import {
  StyledBasicQuestionsContainer,
  StyledLocationsRowFieldsContainer
} from "../styles";

const AdditionalQuestionsSection = ({
  t,
  questions,
  register,
  errors,
  control,
  questionTypes,
  onAddQuestion,
  onRemoveQuestion
}: IJobAdditionalQuestionsSection) => (
  <>
    <SectionTitle title={t("create_job_third_step.additional_questions")} />
    <StyledBasicQuestionsContainer spacing={1}>
      {questions.map(({ id }: { id: string }, index: number) => (
        <StyledLocationsRowFieldsContainer key={id} direction={"row"}>
          <Stack sx={{ flex: 5 }}>
            <InputLabel>{t("create_job_third_step.question")}</InputLabel>
            <TextField
              placeholder={t(`create_job_third_step.question`) as string}
              fullWidth
              {...register(`questions.${index}.question`)}
              error={Boolean(errors["questions"]?.[index]?.["question"])}
              helperText={errors["questions"]?.[index]?.["question"]?.message}
            />
          </Stack>
          <Stack sx={{ flex: 1 }}>
            <InputLabel>{t("type")}</InputLabel>
            <Controller
              name={`questions.${index}.is_answer_type_yesno`}
              control={control}
              render={({ field: { onChange, value } }) => (
                <StyledSelect
                  disabled={false}
                  fullWidth
                  value={value}
                  displayEmpty
                  variant="outlined"
                  onChange={onChange}
                >
                  {questionTypes.map(
                    ({ value, label }: { value: number; label: string }) => (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    )
                  )}
                </StyledSelect>
              )}
            />
          </Stack>
          <Stack
            alignSelf={"center"}
            mt={errors["questions"]?.[index] ? 0 : 2.5}
          >
            <XCircleIcon
              width={24}
              height={24}
              className={"remove-icon-button"}
              onClick={() => onRemoveQuestion(index)}
            />
          </Stack>
        </StyledLocationsRowFieldsContainer>
      ))}
      <Stack my={1}>
        <Button
          variant="text"
          startIcon={<PlusCircleIcon width={24} height={24} />}
          onClick={onAddQuestion}
        >
          {t("create_job_third_step.add_question")}
        </Button>
      </Stack>
    </StyledBasicQuestionsContainer>
  </>
);

export default AdditionalQuestionsSection;
