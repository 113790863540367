import { TFunction } from "i18next";
import { ICellRendererParams, RowClickedEvent } from "ag-grid-community";
import { APPLICATION_STATUSES, TApplication } from "./Applications";
import { JOB_STATUSES, TJobListItem } from "./JobList";
import { IJobOrderProduct, TBasicJobBoard } from "./SingleJob";
import {
  TEAM_USER_ROLES,
  TEAM_USER_STATUSES,
  TTeamsInvitation,
  TTeamsMember,
} from "./Team";
import { CUSTOMER_STATUSES, TCustomer, TJassCustomer } from "./Agency";
import { TJob } from "./CompanyInfo";
import { ITalentPool } from "./TalentPools";

export interface IDataGrid {
  readonly t: TFunction;
  readonly list: TDataGridList;
  readonly type: DATA_GRID_LIST_TYPE;
  readonly actions: TDataGridActions;
  readonly onSelect?: (
    val: TJobListItem | TApplication | TJassCustomer
  ) => void;
  readonly onRowClick?: (rowData: RowClickedEvent["data"]) => void;
  readonly headerHeight?: number;
}

export interface IDataGridActionCell {
  readonly t: TFunction;
  readonly actions: TDataGridActions;
  readonly type?: JOB_STATUSES;
  readonly connectType?: string;
  readonly forceMultiple?: boolean;
}

export interface IDataGridActionCellRenderButtons {
  readonly t: TFunction;
  readonly actions: TDataGridActions;
  readonly hasMultipleActions?: boolean;
  readonly type?: JOB_STATUSES;
  readonly connectType?: string;
  readonly onClick: (action: () => void) => void;
  readonly isDraftImagesLoading: boolean;
  readonly continueEditingButtonWidth: number;
}

export type TDataGridList = (
  | TTeamsMember
  | TTeamsInvitation
  | TJobListItem
  | TApplication
  | TBasicJobBoard
  | IJobOrderProduct
  | TCustomer
  | TJassCustomer
  | ITalentPool
)[]; // add more list types here

export type TDataGridActions = {
  readonly onDelete?: (
    data: TTeamsMember | TTeamsInvitation | TJobListItem
  ) => void;
  readonly onClickApplicationsNumber?: (
    data:
      | ITalentPool
      | TJobListItem
      | {
          candidateUrlKey: string;
          candidateJobId: string;
        },
    type?: string
  ) => void;
  readonly onClick?: (
    data:
      | ITalentPool
      | TJobListItem
      | {
          candidateUrlKey: string;
          candidateJobId: string;
        },
    type?: string
  ) => void;
  readonly onClickItem?: (id: string, type?: string) => void;
  readonly onEdit?: (
    data:
      | TJobListItem
      | ITalentPool
      | {
          candidateUrlKey: string;
          candidateJobId: string;
        },
    type?: string
  ) => void;
  readonly onRemove?: (data: unknown) => void;
  readonly onAddCandidateToActiveJob?: (data: unknown) => void;
  readonly onPreview?: (data: TJobListItem["url_key"]) => void;
  readonly onArchiveRestore?: (data: TJobListItem) => void;
  readonly onExport?: (data: TJobListItem | TJobListItem[]) => void;
  readonly onDuplicate?: (data: TJobListItem | TJobListItem[]) => void;
  readonly onPromote?: (data: TJobListItem) => void;
  readonly onContinueEditing?: (job: TJob) => void;
  readonly onConnect?: (data: TBasicJobBoard) => void;
  readonly onUpload?: (file?: File) => void;
  readonly onChangeStatus?: (data: {
    readonly isTalentPooled: boolean;
    readonly talentPoolTitle: string | null;
    readonly applicationId: TApplication["application_id"];
    readonly newStatus: string;
  }) => void;
  readonly onDownloadCV?: (cvLink: string) => void;
  readonly onReInviteTeamMember?: (email: string) => void;
  readonly onInvite?: (data: TJobListItem) => void;
};

export interface IDataGridStatusCell {
  readonly params?: ICellRendererParams;
  readonly label: string;
  readonly type:
    | TEAM_USER_STATUSES
    | TEAM_USER_ROLES
    | JOB_STATUSES
    | APPLICATION_STATUSES
    | CUSTOMER_STATUSES;
}

export interface IDataGridStatusSelectorCell extends IDataGridStatusCell {
  readonly onSelect?: (newStatus: string) => void;
}

export interface IDataGridActionsCell {
  readonly onDelete?: () => void;
  readonly onPromote?: () => void;
}

export type TDataGridGetColDef = {
  readonly type: DATA_GRID_LIST_TYPE;
  readonly actions: TDataGridActions;
  readonly t: TFunction;
  readonly multipleSelection?: boolean;
  readonly onSelect?: (val: TJobListItem | TJassCustomer) => void;
};

export enum DATA_GRID_LIST_TYPE {
  APPLICATION_LIST = "applicationList",
  APPLICATION_LIST_SINGLE_JOB = "applicationListSingleJob",
  APPLICATION_LIST_DASHBOARD = "applicationListDashboard",
  BASIC_JOB_BOARDS = "basicJobBoards",
  JOB_LIST_ACTIVE = "jobListActive",
  JOB_LIST_ARCHIVED = "jobListArchived",
  JOB_LIST_DRAFT = "jobListDraft",
  USERS = "users",
  BOOKINGS = "bookings",
  CUSTOMERS = "customers",
  IMPORT_CUSTOMERS = "importCustomers",
  AGENCY_JOB_LIST_ACTIVE = "agencyJobListActive",
  AGENCY_JOB_LIST_ARCHIVED = "agencyJobListArchived",
  AGENCY_JOB_LIST_DRAFT = "agencyJobListDraft",
  TALENT_POOLS = "talentPools",
  TALENT_POOL_CANDIDATE = "talentPoolCandidate",
  TALENT_POOL_JOBS = "talentPoolJobs",
} // add more types here
