import React, { useEffect, useMemo } from "react";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  Stack,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { StyledWarningTextContainer } from "../../../pages/JobList_new/styles";
import { getCompanySettingsCompanyState } from "../../../store_new/selectors/Company";
import {
  setArchiveRestoreDialogOpen,
  setIsLimitDialogOpen,
} from "../../../store_new/reducers/dialogs";
import { getDialogsState } from "../../../store_new/selectors/Dialogs";
import { getSingleJobState } from "../../../store_new/selectors/SingleJob";
import { useTranslation } from "react-i18next";
import {
  archiveRestoreJobs,
  fetchJobs,
} from "../../../store_new/reducers/JobList";
import { JOB_ACTIONS, JOB_STATUSES } from "../../../models/JobList";
import {
  fetchJobDetails,
  setTempJobDetails,
} from "../../../store_new/reducers/SingleJob";
import { getJobListIsLoading } from "../../../store_new/selectors/JobList";
import { fetchCompanyData } from "../../../store_new/reducers/CompanySettings";

const ArchiveRestoreJobModal = () => {
  const lang = localStorage.getItem("lang");
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { isArchiveRestoreModalOpen } = useSelector(getDialogsState);
  const { jobDetails, tempJobDetails } = useSelector(getSingleJobState);
  const companyData = useSelector(getCompanySettingsCompanyState);
  const isLoading = useSelector(getJobListIsLoading); // archive/activate action loading state

  const targetJobDetails = useMemo(
    () => jobDetails || tempJobDetails,
    [jobDetails, tempJobDetails]
  );
  const isArchived = useMemo(
    () => !!targetJobDetails?.is_archived,
    [targetJobDetails]
  );
  const archiveWidth = lang === "en_US" ? 600 : 730;
  const activeWidth = lang === "en_US" ? 520 : 640;

  const handleOnClose = () => {
    if (tempJobDetails) {
      // trigger these dispatches if there is tempJobDetails (action performed  from the job list)
      dispatch(
        fetchJobs(isArchived ? JOB_STATUSES.ARCHIVED : JOB_STATUSES.ACTIVE)
      );
      dispatch(fetchCompanyData());
    } else if (jobDetails) {
      dispatch(
        fetchJobDetails({
          jobUrlKey: jobDetails.url_key,
        })
      );
    }
    dispatch(setArchiveRestoreDialogOpen(false));
    dispatch(setTempJobDetails(null));
  };

  const handleOnConfirmArchiveRestore = () => {
    if (targetJobDetails?.hasOwnProperty("publicationsCount")) {
      const { publicationsCount, url_key } = targetJobDetails;
      dispatch(
        archiveRestoreJobs({
          jobUrlKeys: [url_key],
          action: isArchived
            ? JOB_ACTIONS.RESTORE
            : publicationsCount != "0"
            ? JOB_ACTIONS.UNPUBLISH
            : JOB_ACTIONS.ARCHIVE,
          callback: handleOnClose,
        })
      );
    }
  };

  useEffect(() => {
    if (
      isArchiveRestoreModalOpen &&
      isArchived &&
      !companyData.company?.jobs_creation_allowed
    ) {
      handleOnClose();
      dispatch(setIsLimitDialogOpen(true));
    }
  }, [isArchiveRestoreModalOpen]);

  const archivedTexts = [
    {
      text: t("archive_job_dialog.t1"),
      checked: false,
    },
    {
      text: t("archive_job_dialog.t2"),
      checked: false,
    },
    {
      text: t("archive_job_dialog.t3"),
      checked: false,
    },
    {
      text: t("archive_job_dialog.t4"),
      checked: false,
    },
    {
      text: t("archive_job_dialog.t5"),
      checked: true,
    },
    {
      text: t("archive_job_dialog.t6"),
      checked: true,
    },
  ];

  const activeTexts = [
    {
      text: t("archive_job_dialog.t7"),
      checked: false,
    },
    {
      text: t("archive_job_dialog.t8"),
      checked: false,
    },
  ];

  return (
    <Dialog
      open={isArchiveRestoreModalOpen}
      onClose={handleOnClose}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            minWidth: isArchived ? archiveWidth : activeWidth,
          },
        },
      }}
    >
      <DialogTitle sx={{ p: 3 }}>
        {isArchived
          ? t("archive_job_dialog.activate_title")
          : t("archive_job_dialog.title")}
      </DialogTitle>
      <DialogContent sx={{ pb: 0 }}>
        {isArchived ? (
          <Stack spacing={1.5} justifyContent="center">
            {activeTexts.map((item, index) => (
              <StyledWarningTextContainer
                key={index}
                direction="row"
                spacing={1.5}
                alignItems="center"
                type="success"
              >
                <Icon>
                  <CheckCircleIcon />
                </Icon>
                <Typography key={index} variant="body2" color="textSecondary">
                  {item.text}
                </Typography>
              </StyledWarningTextContainer>
            ))}
          </Stack>
        ) : (
          <Stack spacing={1.5} justifyContent="center">
            {archivedTexts.map((item, index) => (
              <StyledWarningTextContainer
                key={index}
                direction="row"
                spacing={1.5}
                alignItems="center"
                type={item.checked ? "success" : "warning"}
              >
                {item.checked ? (
                  <Icon>
                    <CheckCircleIcon />
                  </Icon>
                ) : (
                  <Icon>
                    <XCircleIcon />
                  </Icon>
                )}
                <Typography key={index} variant="body2" color="textSecondary">
                  {item.text}
                </Typography>
              </StyledWarningTextContainer>
            ))}
          </Stack>
        )}
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button
          variant={"outlined"}
          onClick={handleOnClose}
          disabled={isLoading}
        >
          {t(isArchived ? "button.cancel" : "archive_job_dialog.cancel")}
        </Button>
        <Button
          endIcon={
            isLoading ? (
              <CircularProgress
                size={16}
                style={{
                  color: "#9A9FAF",
                }}
              />
            ) : null
          }
          variant={"contained"}
          onClick={handleOnConfirmArchiveRestore}
          disabled={isLoading}
        >
          {isArchived ? t("joblist.activate") : t("archive_job_dialog.delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ArchiveRestoreJobModal;
