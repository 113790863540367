import React, { useState, useEffect } from "react";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SingleApplicationHeaderComponent from "./component";
import { getCurrentApplication } from "../../../store_new/selectors/Applications";
import { getTopHeaderState } from "../../../store_new/selectors/TopHeader";
import {
  setTopHeaderSubtitle,
  setTopHeaderTitle,
} from "../../../store_new/reducers/TopHeader";
import { APPLICATION_STATUSES } from "../../../models/Applications";
import { getCandidateState } from "../../../store_new/selectors/Candidate";
import { deleteApplication } from "../../../store_new/reducers/Applications";
import { addMessage } from "../../../slices/NotificationSlice/GlobalNotificationSlice";

const SingleApplicationCandidateHeader = ({
  desktop,
}: {
  readonly desktop: boolean;
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { title, subtitle } = useSelector(getTopHeaderState);
  const { isLoading } = useSelector(getCandidateState);
  const currentApplication = useSelector(getCurrentApplication);

  const [status, setStatus] = useState<APPLICATION_STATUSES | undefined>(
    undefined
  );
  const [isDeleteApplicationDialogOpen, setIsDeleteApplicationDialogOpen] =
    useState<boolean>(false);
  const [targetJob, setTargetJob] = useState<string>("");

  const handleOnNavigateBack = () => {
    navigate("/");
  };

  const handleOnOpenCloseDialog = (boolean: boolean) => {
    setIsDeleteApplicationDialogOpen(boolean);
  };

  const handleOnSuccessDeleteApplication = () => {
    handleOnNavigateBack();
    dispatch(
      addMessage({
        type: "success",
        title: t("applications.withdraw_application_snackbar"),
      })
    );
  };

  const handleOnDeleteApplication = () => {
    dispatch(
      deleteApplication({
        applicationId: currentApplication?.id as number,
        callback: () => handleOnSuccessDeleteApplication(),
      })
    );
  };

  useEffect(() => {
    if (currentApplication) {
      const { job, timestamp, status } = currentApplication;
      dispatch(setTopHeaderTitle(job.title));
      if (desktop) {
        dispatch(
          setTopHeaderSubtitle(
            `${t("single_job.applied_on")} ${moment(timestamp).format(
              "DD.MM.YYYY"
            )} - ${job.company}`
          )
        );
      } else {
        dispatch(
          setTopHeaderSubtitle(
            `${t("single_job.applied_on")} ${moment(timestamp).format(
              "DD.MM.YYYY"
            )}`
          )
        );
      }

      setStatus(status);
      setTargetJob(job.url_key);
    }
  }, [currentApplication]);

  return (
    <SingleApplicationHeaderComponent
      t={t}
      desktop={desktop}
      title={title}
      subtitle={subtitle}
      isLoading={isLoading || !currentApplication}
      status={status}
      targetJob={targetJob}
      isOpen={isDeleteApplicationDialogOpen}
      company={currentApplication?.job?.company || ""}
      onDeleteApplication={handleOnDeleteApplication}
      onOpenCloseDialog={handleOnOpenCloseDialog}
      onNavigateBack={handleOnNavigateBack}
    />
  );
};

export default SingleApplicationCandidateHeader;
