import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import SingleApplicationHeaderComponent from "./component";
import {
  getJobApplicationsLoadingState,
  getSelectedJobApplications,
} from "../../../store_new/selectors/Applications";
import { getTopHeaderState } from "../../../store_new/selectors/TopHeader";
import {
  setTopHeaderSubtitle,
  setTopHeaderTitle,
} from "../../../store_new/reducers/TopHeader";
import { APPLICATION_STATUSES } from "../../../models/Applications";
import { ITalentPool } from "../../../models/TalentPools";
import {
  fetchActivityLog,
  fetchSelectedJobApplications,
  resetCurrentApplication,
  updateApplicationStatus,
} from "../../../store_new/reducers/Applications";
import { getTalentPoolState } from "../../../store_new/selectors/Talentpool";
import { deleteCandidate } from "../../../store_new/reducers/TalentPools";
import { getCurrentUserData } from "../../../store_new/selectors/CurrentUser";

const SingleApplicationHeader = ({
  desktop,
}: {
  readonly desktop: boolean;
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const { job_id } = useParams();
  const { title, subtitle } = useSelector(getTopHeaderState);
  const { isAgency } = useSelector(getCurrentUserData);
  const isLoading = useSelector(getJobApplicationsLoadingState);
  const currentApplication = useSelector(getSelectedJobApplications)?.[0];
  const { talentPools } = useSelector(getTalentPoolState);

  const [status, setStatus] = useState<APPLICATION_STATUSES | undefined>(
    undefined
  );
  const [targetJob, setTargetJob] = useState<{ title: string; id: string }>({
    title: "",
    id: "",
  });

  useEffect(() => {
    if (currentApplication) {
      const candidateName = `${currentApplication.firstname} ${currentApplication.lastname}`;
      dispatch(setTopHeaderTitle(candidateName));
      dispatch(
        setTopHeaderSubtitle(
          `${t("single_job.applied_on")} ${moment(
            currentApplication.timestamp
          ).format("DD.MM.YYYY")}`
        )
      );
      setStatus(currentApplication.status);
      setTargetJob({
        title: currentApplication.title,
        id: currentApplication.job_id as string,
      });
    }
  }, [currentApplication]);

  const handleOnUpdateStatus = (newStatus: APPLICATION_STATUSES) => {
    if (currentApplication) {
      if (currentApplication?.is_talentpooled == 1) {
        const findTalentPool = talentPools.find(
          (item: ITalentPool) =>
            item.title === currentApplication?.talentpool_title
        );
        dispatch(
          updateApplicationStatus({
            applicationId: currentApplication.application_id,
            newStatus,
            index: "0",
            callback: () =>
              dispatch(
                deleteCandidate({
                  id: findTalentPool?.id as number,
                  applicationId:
                    currentApplication?.application_id as unknown as number,
                  callback: () => {
                    setStatus(newStatus);
                    dispatch(
                      fetchSelectedJobApplications({
                        job_id: job_id as string,
                        candidateUrlKey: currentApplication?.candidate_url_key,
                      })
                    );
                  },
                })
              ),
          })
        );
      } else {
        dispatch(
          updateApplicationStatus({
            applicationId: currentApplication.application_id,
            newStatus,
            index: "0",
            callback: () => {
              setStatus(newStatus);
              if (!isAgency) {
                dispatch(fetchActivityLog(currentApplication.application_id));
              }
            },
          })
        );
      }
    }
  };

  const handleOnNavigateBack = () => {
    if (location.pathname.includes("job")) {
      navigate(`/jobs/${job_id}/applications`);
    } else {
      navigate("/applications");
    }
    dispatch(resetCurrentApplication());
  };

  return (
    <SingleApplicationHeaderComponent
      t={t}
      title={title}
      subtitle={subtitle}
      isLoading={isLoading}
      status={status}
      targetJob={targetJob}
      currentApplication={currentApplication}
      desktop={desktop}
      onUpdateStatus={handleOnUpdateStatus}
      onNavigateBack={handleOnNavigateBack}
    />
  );
};

export default SingleApplicationHeader;
