import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { TJobLocation } from "../../models/SingleJob";
import { PayloadActionWithCallback } from "../../models/common";
import {
  IJobPreviewState,
  TAdditionalQuestionsFormFields,
  TJobActionRegisterFormFields,
  TJobPreviewJob,
} from "../../models/JobPreview";

const initialState: IJobPreviewState = {
  jobTemplate: null,
  companyColor: "#178CF2",
  jobLocations: [],
  otherJobs: [],
  isApplyInProgress: false,
  isLoading: false,
  applyError: "",
  error: "",
  checkEmailLoading: false,
  isMagicLinkLoading: false,
  jobData: {},
  cities: [],
  isSubmitted: false,
  totalJobs: 0,
  isLoadingAdditionalQuestionsUpdate: false,
  isJobLoadingFinished: false,
};

export const JobListSlice = createSlice({
  name: "JOB_LIST",
  initialState,
  reducers: {
    fetchJob: (
      state: Draft<IJobPreviewState>,
      _: PayloadAction<{ jobUrlKey: string }>
    ) => {
      state.isLoading = true;
    },
    fetchJobSuccess: (
      state: Draft<IJobPreviewState>,
      action: PayloadAction<{
        job: TJobPreviewJob;
        jobLocations: TJobLocation[];
        jobs: any[];
        data: any;
        companyColor: string;
        totalJobs: number;
      }>
    ) => {
      const { job, jobLocations, jobs, data, companyColor, totalJobs } =
        action.payload;
      state.jobTemplate = job;
      state.jobLocations = jobLocations;
      state.otherJobs = jobs;
      state.jobData = data;
      state.isLoading = false;
      state.companyColor = companyColor || initialState.companyColor;
      state.totalJobs = totalJobs;
      state.error = false;
      state.isJobLoadingFinished = true;
    },
    fetchJobFailed: (
      state: Draft<IJobPreviewState>,
      action: PayloadAction<unknown>
    ) => {
      state.isLoading = false;
      state.error = action.payload;
      state.isJobLoadingFinished = true;
    },
    applyToJob: (
      state: Draft<IJobPreviewState>,
      _: PayloadActionWithCallback<{
        jobUrlKey: string;
        body: TJobActionRegisterFormFields & { files?: FileList | null };
      }>
    ) => {
      state.isApplyInProgress = true;
    },
    applyToJobSuccess: (state: Draft<IJobPreviewState>) => {
      state.isApplyInProgress = false;
    },
    applyToJobFailed: (
      state: Draft<IJobPreviewState>,
      action: PayloadAction<unknown>
    ) => {
      state.isApplyInProgress = false;
      state.applyError = action.payload;
    },
    checkCandidateExist: (
      state: Draft<IJobPreviewState>,
      _: PayloadActionWithCallback<{
        email: string;
      }>
    ) => {
      state.checkEmailLoading = true;
    },
    checkCandidateExistSuccess: (state: Draft<IJobPreviewState>) => {
      state.checkEmailLoading = false;
    },
    checkCandidateExistFailed: (state: Draft<IJobPreviewState>) => {
      state.checkEmailLoading = false;
      state.applyError = true;
    },
    sendMagicLink: (
      state: Draft<IJobPreviewState>,
      _: PayloadActionWithCallback<{
        email: string;
        urlKey: string;
      }>
    ) => {
      state.isMagicLinkLoading = true;
    },
    sendMagicLinkSuccess: (state: Draft<IJobPreviewState>) => {
      state.isMagicLinkLoading = false;
      state.applyError = false;
    },
    sendMagicLinkFailed: (state: Draft<IJobPreviewState>) => {
      state.isMagicLinkLoading = false;
    },
    fetchCities: (_, _action: PayloadAction<{ city: string }>) => {},
    fetchCitiesSuccess: (
      state: Draft<IJobPreviewState>,
      action: PayloadAction<any>
    ) => {
      state.cities = action.payload;
    },
    fetchCitiesFailed: (
      state: Draft<IJobPreviewState>,
      action: PayloadAction<unknown>
    ) => {
      state.error = action.payload;
    },
    submitAdditionalQuestions: (
      state: Draft<IJobPreviewState>,
      _: PayloadAction<{
        data: TAdditionalQuestionsFormFields;
        jobUrlKey: string;
      }>
    ) => {
      state.isLoadingAdditionalQuestionsUpdate = true;
    },
    submitAdditionalQuestionsSuccess: (state: Draft<IJobPreviewState>) => {
      state.isLoadingAdditionalQuestionsUpdate = false;
      state.isSubmitted = true;
    },
    submitAdditionalQuestionsFailed: (
      state: Draft<IJobPreviewState>,
      action: PayloadAction<unknown>
    ) => {
      state.isLoadingAdditionalQuestionsUpdate = false;
      state.error = action.payload;
    },
    skipAdditionalQuestionsUpdate: (state: Draft<IJobPreviewState>) => {
      state.isSubmitted = true;
    },
    resetApplyError: (state: Draft<IJobPreviewState>) => {
      state.applyError = initialState.applyError;
    },
    fetchPreviewTemplate: (
      state: Draft<IJobPreviewState>,
      _: PayloadAction<string>
    ) => {
      state.isLoading = true;
    },
    fetchPreviewTemplateSuccess: (
      state: Draft<IJobPreviewState>,
      action: PayloadAction<{
        template: TJobPreviewJob;
      }>
    ) => {
      state.isLoading = false;
      state.jobTemplate = action.payload.template;
    },
    fetchPreviewTemplateFailed: (
      state: Draft<IJobPreviewState>,
      action: PayloadAction<unknown>
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchJob,
  fetchJobSuccess,
  fetchJobFailed,
  applyToJob,
  applyToJobSuccess,
  applyToJobFailed,
  checkCandidateExist,
  checkCandidateExistFailed,
  checkCandidateExistSuccess,
  sendMagicLink,
  sendMagicLinkFailed,
  sendMagicLinkSuccess,
  fetchCities,
  fetchCitiesSuccess,
  fetchCitiesFailed,
  submitAdditionalQuestions,
  submitAdditionalQuestionsSuccess,
  submitAdditionalQuestionsFailed,
  skipAdditionalQuestionsUpdate,
  resetApplyError,
  fetchPreviewTemplate,
  fetchPreviewTemplateFailed,
  fetchPreviewTemplateSuccess,
} = JobListSlice.actions;

export default JobListSlice.reducer;
