import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { PayloadActionWithCallback, TListOption } from "../../models/common";
import {
  TApiUpdateCurrentUserData,
  TApiUpdateCurrentUserPhoto,
  TApiUpdateCurrentUserPassword,
} from "../../models/CurrentUser";
import {
  TConvertedPremiumProduct,
  TPremiumPackage,
} from "../../models/SingleJob";

export interface IUserState {
  userData: {
    readonly agencyId: null | number;
    readonly companyId: null | number;
    readonly companyUrlKey: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly email: string;
    readonly username: string;
    readonly memberId: null | number;
    readonly phoneNumber: string;
    readonly isAgency: number; // TODO boolean
    readonly isAdmin: number; // TODO boolean
    readonly isDisabled: number; // TODO boolean
    readonly isActivated: number; // TODO boolean
    readonly lang: string;
    readonly photo: string;
    readonly noPassword: any;
    readonly linkedinUrl?: string | null;
    readonly xingUrl?: string | null;
    readonly applicationsAllowed: boolean;
  };
  readonly statuses: TListOption[];
  readonly isCandidate?: boolean;
  readonly token: string;
  readonly isLoading: boolean;
  readonly isUpdateLoading: boolean;
  readonly error: unknown;
  readonly jassId?: string | number;
  readonly activeProduct?: TConvertedPremiumProduct | TPremiumPackage;
}

const initialState: IUserState = {
  userData: {
    agencyId: null,
    companyId: null,
    companyUrlKey: "",
    firstName: "",
    lastName: "",
    email: "",
    username: "",
    memberId: null,
    phoneNumber: "",
    isAgency: 0,
    isAdmin: 1,
    isDisabled: 0,
    isActivated: 1,
    lang: "",
    photo: "",
    noPassword: undefined,
    linkedinUrl: null,
    xingUrl: null,
    applicationsAllowed: false,
  },
  statuses: [],
  isCandidate: undefined,
  token: "",
  isLoading: false,
  isUpdateLoading: false,
  error: "",
  jassId: undefined,
  activeProduct: undefined,
};

const userReducer = {
  setActiveProduct: (
    state: Draft<IUserState>,
    action: PayloadAction<TConvertedPremiumProduct>
  ) => {
    state.activeProduct = action.payload;
  },
  fetchUser: (state: Draft<IUserState>) => {
    state.isLoading = true;
  },
  fetchUserSuccess: (state: Draft<IUserState>, action: PayloadAction<any>) => {
    state.isLoading = false;
    state.userData = action.payload.userData;
    state.isCandidate = action.payload.isCandidate || false;
    state.jassId = action.payload.jassId;
    state.statuses = action.payload.statuses;
  },
  fetchUserWithCallback: (
    state: Draft<IUserState>,
    _: PayloadActionWithCallback<unknown>
  ) => {
    state.isLoading = true;
  },
  fetchUserFailed: (
    state: Draft<IUserState>,
    action: PayloadAction<unknown>
  ) => {
    state.error = action.payload;
    state.isLoading = false;
  },
  updateCurrentUserData: (
    state: Draft<IUserState>,
    _: PayloadActionWithCallback<{ data: TApiUpdateCurrentUserData }>
  ) => {
    state.isUpdateLoading = true;
  },
  updateCurrentUserDataSuccess: (state: Draft<IUserState>) => {
    state.isUpdateLoading = false;
  },
  updateCurrentUserDataFailed: (
    state: Draft<IUserState>,
    action: PayloadAction<unknown>
  ) => {
    state.error = action.payload;
    state.isUpdateLoading = false;
  },
  updateCurrentUserPhoto: (
    state: Draft<IUserState>,
    _: PayloadActionWithCallback<TApiUpdateCurrentUserPhoto>
  ) => {
    state.isUpdateLoading = true;
  },
  updateCurrentUserPhotoSuccess: (state: Draft<IUserState>) => {
    state.isUpdateLoading = false;
  },
  updateCurrentUserPhotoFailed: (
    state: Draft<IUserState>,
    action: PayloadAction<unknown>
  ) => {
    state.error = action.payload;
    state.isUpdateLoading = false;
  },
  updateCurrentUserPassword: (
    state: Draft<IUserState>,
    _: PayloadActionWithCallback<{ data: TApiUpdateCurrentUserPassword }>
  ) => {
    state.isUpdateLoading = true;
  },
  updateCurrentUserPasswordSuccess: (state: Draft<IUserState>) => {
    state.isUpdateLoading = false;
  },
  updateCurrentUserPasswordFailed: (
    state: Draft<IUserState>,
    action: PayloadAction<unknown>
  ) => {
    state.error = action.payload;
    state.isUpdateLoading = false;
  },
  setCurrentUserToken: (
    state: Draft<IUserState>,
    action: PayloadAction<string>
  ) => {
    state.token = action.payload;
  },
  resetCurrentUser: () => initialState,
};

export const UserSlice = createSlice({
  name: "USER",
  initialState,
  reducers: {
    ...userReducer,
  },
});

export const {
  fetchUser,
  fetchUserWithCallback,
  fetchUserSuccess,
  fetchUserFailed,
  updateCurrentUserData,
  updateCurrentUserDataFailed,
  updateCurrentUserDataSuccess,
  updateCurrentUserPhoto,
  updateCurrentUserPhotoFailed,
  updateCurrentUserPhotoSuccess,
  updateCurrentUserPassword,
  updateCurrentUserPasswordFailed,
  updateCurrentUserPasswordSuccess,
  resetCurrentUser,
  setActiveProduct,
  setCurrentUserToken,
} = UserSlice.actions;

export default UserSlice.reducer;
