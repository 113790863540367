import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflow: "auto",
    // paddingRight: "7px",
    "&::-webkit-scrollbar ": {
      width: "4px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#E5E7EB",
      borderRadius: "4px",
    },
  },
  notesSection: {
    display: "flex",
    marginBottom: "10px",
  },
  noteHeader: {
    display: "flex",
    justifyContent: "space-between",
    "& svg": {
      height: "20px",
      width: "20px",
      color: "#178CF2",
    },
  },
  avatar: {
    marginRight: "8px",
  },
  fullName: {
    margin: 0,
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "20px",
    color: "#374151",
  },
  noteActions: {
    display: "inline-flex",
    alignItems: "center",
    "& svg": {
      height: "14px",
      width: "14px",
      color: "#178CF2",
      cursor: "pointer",
    },
  },
  note: {
    width: "100%",
    background: "#F9FAFB",
    boxSizing: "content-box",
    borderRadius: "0px 6px 6px 6px",
  },
  noteWrapper: {
    padding: "12px 12px",
  },
  noteBody: {
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "20px",
    color: "#6B7280",
    margin: "4px 0px",
  },
  noteFooter: {
    display: "flex",
    justifyContent: "space-between",
    width: "-webkit-fill-available",
    "& p": {
      margin: 0,
    },
  },
  date: {
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "20px",
    color: "#9CA3AF",
  },
  replay: {
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "20px",
    color: "#178CF2",
    cursor: "pointer",
  },
  noteReply: {
    background: "#F3F4F6",
    display: "flex",
    padding: "12px",
    // marginBottom: "12px",
    borderRadius: "0px 6px 0px 6px",
    "& svg": {
      height: "20px",
      width: "20px",
      color: "#178CF2",
      transform: "rotate(180deg)",
    },
  },
  noteReplyHeader: {
    display: "flex",
  },
  textArea: {
    resize: "none",
    width: "100%",
    padding: "0px",
    border: "unset",
    boxSizing: "border-box",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    "&::placeholder": {
      fontSize: "12px",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      lineHeight: "20px",
      color: "#6B7280",
    },
    "&:focus": {
      outline: "none",
      // boxShadow: "0px 0px 0px 4px #C6E5FF",
      // border: "1px solid #5FB2FA",
    },
    "&::-webkit-scrollbar ": {
      width: "4px",
    },

    "&::-webkit-scrollbar-thumb": {
      background: "#E5E7EB",
      borderRadius: "4px",
    },
  },
  writeComment: {
    paddingTop: 12,
    display: "flex",
    gap: 24,
    justifyContent: "center",
    alignItems: "center",
    borderTop: "1px solid #E5E7EB",
    maxHeight: 130,
    overflow: "hidden",
  },
  acitonWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "16px",
    "& p": {
      margin: 0,
    },
  },
  button: {
    display: "flex",
    alignSelf: "center",
    background: "#178CF2",
    padding: "10px",
    borderRadius: "6px",
    boxSizing: "content-box",
    border: "none",
    cursor: "pointer",
    "&:hover": {
      background: "#0e6dbf",
    },
    "& svg": {
      width: "20px",
      height: "20px",
      color: "#fff",
    },
  },
  actionStatus: {
    fontSize: "12px",
    lineHeight: "20px",
    color: "#6B7280",
  },
  noNotes: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& svg": {
      width: "24px",
      height: "24px",
      padding: "8px",
      borderRadius: "100%",
      background: "#EEF2FF",
      color: "#818CF8",
      boxSizing: "content-box",
    },
    "& p": {
      color: "#6B7280",
      fontSize: "14px",
      lineHeight: "20px",
      textAlign: "center",
    },
  },
  initials: {
    margin: 0,
    padding: "5px",
    boxSizing: "border-box",
    borderRadius: "100%",
    background: "#bdbdbd",
    color: "#fff",
    fontSize: "12px",
    width: "35px",
    height: "35px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    lineHeight: "normal",
  },
  input: {
    width: "100%",
    borderRadius: "100px",
    padding: "10px 12px",
    border: "1px solid #E5E7EB",
    "&::placeholder": {
      fontSize: "12px",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      lineHeight: "20px",
      color: "#6B7280",
    },
    "&:focus": {
      outline: "none",
      border: "1px solid #5FB2FA",
    },
  },
  commentSection: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    "& svg": {
      width: "32px",
      height: "32px",
      marginLeft: "8px",
      color: "#178CF2",
    },
  },
  showComments: {
    fontSize: "12px",
    lineHeight: "20px",
    color: "#178CF2",
    margin: 0,
    fontFeatureSettings: "salt on",
  },
  clear: {
    background: "unset",
    border: "unset",
    color: "#6B7280",
    height: "35px",
    width: "35px",
    marginRight: "10px",
    cursor: "pointer",
    "& svg:hover path": {
      strokeWidth: "2px",
    },
    "& svg path": {
      strokeWidth: "1px",
    },
  },
}));
