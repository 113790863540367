import React, { useEffect, useMemo } from "react";
import { Control, useForm, UseFormRegister } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { additionalInfoFormFieldsSchema } from "./config";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentApplication,
  getJobApplicationsAnswerUpdateLoadingState,
} from "../../../../store_new/selectors/Applications";
import { getJobPreviewState } from "../../../../store_new/selectors/JobPreview";
import { TListOption } from "../../../../models/common";
import {
  fetchSelectedJobApplications,
  updateApplicationAnswers,
} from "../../../../store_new/reducers/Applications";
import AdditionalInfoEditModalComponent from "./component";
import { useParams } from "react-router";
import {
  TApplicationAdditionalInfoFormFields,
  TCandidateApplication,
} from "../../../../models/ApplicationPage";
import { getCurrentUserIsCandidate } from "../../../../store_new/selectors/CurrentUser";
import {
  fetchCandidateApplications,
  updateCandidateApplication,
} from "../../../../store_new/reducers/Candidate";
import { fetchJobDetails } from "../../../../store_new/reducers/SingleJob";

const AdditionalInfoEditModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { key: url_key, job_id } = useParams();

  const isLoading = useSelector(getJobApplicationsAnswerUpdateLoadingState);
  const isCandidate = useSelector(getCurrentUserIsCandidate);

  const application = (useSelector(getCurrentApplication) ||
    {}) as TCandidateApplication;

  const {
    salary_expectation,
    earliest_start_date,
    current_professional_status,
    highest_degree,
    drivers_license,
    languages: candidateLanguages,
    skills: candidateSkills,
  } = application;

  const applicationId = application.id || application.application_id;

  const {
    jobData: {
      driversLicenses,
      skills: skillList,
      languages: languageList,
      languageLevels,
    },
  } = useSelector(getJobPreviewState);

  useEffect(() => {
    if (isCandidate) {
      if (application) {
        const { url_key: jobUrlKey } = application.job;
        if (jobUrlKey) {
          dispatch(fetchJobDetails({ jobUrlKey }));
        }
      }
    }
  }, [application]);

  const modifiedDriverLicenses: TListOption[] = useMemo(() => {
    if (driversLicenses)
      return driversLicenses.map((dl: { id: string; name: string }) => ({
        value: dl.id,
        label: dl.name,
      }));
    return [];
  }, [driversLicenses]);

  const candidateOptions = useMemo(() => {
    let driverLicense: TListOption[] = [];
    let languages: { language: string; level: string }[] = [];
    let skills: { id: string; title: string }[] = [];
    if (modifiedDriverLicenses.length && drivers_license) {
      driverLicense = drivers_license
        .split(",")
        .reduce(
          (acc: TListOption[], dl: string) => [
            ...acc,
            modifiedDriverLicenses.find((item) => item.label === dl)!,
          ],
          []
        );
    }
    if (languageList?.length && candidateLanguages) {
      languages = Object.keys(JSON.parse(candidateLanguages)).reduce(
        (acc: { language: string; level: string }[], language: string) => {
          return [
            ...acc,
            { language, level: JSON.parse(candidateLanguages)[language] },
          ];
        },
        []
      );
    }

    if (skillList?.length && candidateSkills) {
      skills = candidateSkills
        .split(",")
        .reduce(
          (acc: { id: string; title: string }[], skill: string) => [
            ...acc,
            skillList.find(
              (item: { id: string; title: string }) => item.title === skill
            )!,
          ],
          []
        );
    }

    return { driverLicense, languages, skills };
  }, [modifiedDriverLicenses, skillList, languageList, languageLevels]);

  useEffect(() => {
    if (application.job) {
      dispatch(fetchJobDetails({ jobUrlKey: application.job.url_key }));
    }
  }, [application]);

  const onSubmit = (data: any) => {
    if (applicationId)
      if (isCandidate) {
        dispatch(
          updateCandidateApplication({
            ...data,
            applicationId,
            callback: () => {
              dispatch(fetchCandidateApplications());
            },
          })
        );
      } else
        dispatch(
          updateApplicationAnswers({
            applicationId: applicationId.toString(),
            data,
            callback: () => {
              dispatch(
                fetchSelectedJobApplications({
                  job_id: job_id as string,
                  candidateUrlKey: url_key as string,
                })
              );
            },
          })
        );
  };

  const { register, control, handleSubmit, reset } = useForm({
    resolver: yupResolver(
      additionalInfoFormFieldsSchema as TApplicationAdditionalInfoFormFields
    ),
    values: {
      salary_expectation: salary_expectation?.toString() || "",
      earliest_start_date: earliest_start_date || "",
      current_professional_status: current_professional_status || "",
      highest_degree: highest_degree || "",
      skills: candidateOptions.skills,
      driverLicense: candidateOptions.driverLicense,
      languages: candidateOptions.languages,
      questions: [],
    },
  });

  const handleOnClose = () => {
    onClose();
    reset();
  };

  return (
    <AdditionalInfoEditModalComponent
      t={t}
      control={
        control as unknown as Control<TApplicationAdditionalInfoFormFields>
      }
      isOpen={isOpen}
      isLoading={isLoading}
      register={
        register as unknown as UseFormRegister<TApplicationAdditionalInfoFormFields>
      }
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      onClose={handleOnClose}
    />
  );
};

export default AdditionalInfoEditModal;
