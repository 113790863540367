import React from "react";
import Column from "./Column";
import { IColumnsComponent } from "../../models/Kanban";

export default function Columns({
  t,
  columns,
  variant,
  selectedItems,
  handleSelectItem,
  onItemClick,
}: IColumnsComponent) {
  return (
    <>
      {Object.entries(columns).map(([columnId, column], index) => (
        <Column
          key={index}
          t={t}
          title={column.title}
          columnId={columnId}
          column={column}
          color={column.color}
          length={column.items.length}
          variant={variant}
          selectedItems={selectedItems}
          handleSelectItem={handleSelectItem}
          onItemClick={onItemClick}
        />
      ))}
    </>
  );
}
