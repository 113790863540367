import React from "react";
import { Stack, Typography } from "@mui/material";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getActiveProduct } from "../../../store_new/selectors/CurrentUser";
import { StyledDiscountLabel } from "../../Checkout/styles";

const PackageHeader = () => {
  const navigate = useNavigate();

  const activeProduct: any = useSelector(getActiveProduct);

  const handleOnNavigateBack = () => {
    navigate("/discover");
  };

  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      justifyContent="center"
    >
      <ArrowLeftIcon className="menu-icon" onClick={handleOnNavigateBack} />
      <Typography color="#131E30" fontSize={20} fontWeight={500}>
        {activeProduct?.package_name}
      </Typography>
      <StyledDiscountLabel>{activeProduct?.discount} %</StyledDiscountLabel>
    </Stack>
  );
};

export default PackageHeader;
