import dayjs from "dayjs";
import {
  TEAM_USER_ROLES,
  TEAM_USER_STATUSES,
  TTeamsInvitation,
  TTeamsMember,
} from "../models/Team";

export const getModifiedUserList = ({
  activeUser,
  userList,
  selectedUserStatus,
}: {
  readonly activeUser?: string;
  readonly userList: (TTeamsInvitation & TTeamsMember)[];
  readonly selectedUserStatus: TEAM_USER_STATUSES;
}) =>
  userList?.map((user: TTeamsInvitation & TTeamsMember) => {
    // TODO move value calculation to dataGrid config file if necessary
    const userTargetField =
      selectedUserStatus === TEAM_USER_STATUSES.JOINED
        ? user.username
        : user.email;
    return {
      ...user,
      location: userTargetField,
      contract_type: dayjs(user.timestamp).format("DD.MM.YYYY"),
      status: selectedUserStatus,
      role:
        activeUser === userTargetField
          ? TEAM_USER_ROLES.ADMIN
          : TEAM_USER_ROLES.RECRUITER,
      delete: true,
      notAdmin: activeUser !== userTargetField,
    };
  });
