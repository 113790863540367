import React from "react";
import { Divider, Stack } from "@mui/material";
import { TFunction } from "i18next";
import { Link } from "react-router-dom";
import { EyeIcon } from "@heroicons/react/24/outline";
import DataGrid from "../../../components/DataGrid_new";
import Loading from "../../../components/Loading";
import { DATA_GRID_LIST_TYPE, TDataGridList } from "../../../models/DataGrid";
import { TApplication } from "../../../models/Applications";
import { TSelectedJobCandidateApplication } from "../../../models/ApplicationPage";
import {
  StyledDashboardItemContainer,
  StyledDashboardItemTitle,
  StyledDashboardSecondaryText,
  StyledJobsEmptyListContainer,
} from "../styles";

const DashboardApplicationsComponent = ({
  t,
  applicationList,
  isLoading,
  onApplicationNavigate,
}: {
  readonly t: TFunction;
  readonly applicationList: TApplication[];
  readonly isLoading: boolean;
  readonly onApplicationNavigate: (
    application: TSelectedJobCandidateApplication
  ) => void;
}) => (
  <StyledDashboardItemContainer data-testid={"dashboard-applications-wrapper"}>
    <Stack spacing={3} className={"full-height"}>
      <Stack direction={"row"}>
        <StyledDashboardItemTitle>
          {t("dashboard.applications.title")}
        </StyledDashboardItemTitle>
        <StyledDashboardSecondaryText className={"title-row"}>
          <Link className={"link"} to={`/applications`}>
            {t("dashboard.applications.titleBtn")}
          </Link>
        </StyledDashboardSecondaryText>
      </Stack>
      <Divider />
      {isLoading ? (
        <Loading />
      ) : applicationList.length ? (
        <Stack
          height={290}
          maxHeight={290}
          data-testid={"dashboard-applications-list-wrapper"}
        >
          <DataGrid
            t={t}
            actions={{}}
            headerHeight={40}
            list={applicationList as TDataGridList}
            type={DATA_GRID_LIST_TYPE.APPLICATION_LIST_DASHBOARD}
            onRowClick={(application: TSelectedJobCandidateApplication) => {
              onApplicationNavigate(application);
            }}
          />
        </Stack>
      ) : (
        <StyledJobsEmptyListContainer
          data-testid={"dashboard-applications-empty-list-wrapper"}
        >
          <Stack className={"icon-container"}>
            <EyeIcon />
          </Stack>
          <StyledDashboardSecondaryText>
            {t("dashboard.applications.emptyList")}
          </StyledDashboardSecondaryText>
        </StyledJobsEmptyListContainer>
      )}
    </Stack>
  </StyledDashboardItemContainer>
);

export default DashboardApplicationsComponent;
