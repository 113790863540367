import React from "react";
import {
  GlobeAltIcon,
  MapPinIcon,
  EnvelopeIcon,
  PencilSquareIcon,
  PhoneIcon,
  ArrowDownOnSquareIcon,
} from "@heroicons/react/24/outline";
import { Box, IconButton, Stack, TextField, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import AutocompleteFilter from "../../../components/filters_new/AutocompleteFilter";
import { IApplicationPersonalInfoComponent } from "../../../models/ApplicationPage";
import {
  StyledCandidateContentContainer,
  StyledCandidateContentRow,
  StyledCandidateContentRowTitleContainer,
  StyledCandidateContentTitleContainer,
  StyledCandidateContentValue,
  StyledDivider,
} from "../styles";

const PersonalInformationComponent = ({
  t,
  isCandidate,
  control,
  register,
  handleSubmit,
  email,
  phone,
  location,
  country,
  countries,
  isEditMode,
  desktop,
  onEdit,
  onSubmit,
}: IApplicationPersonalInfoComponent) => (
  <StyledCandidateContentContainer>
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2}>
        <StyledCandidateContentTitleContainer direction={"row"}>
          <Typography
            fontSize={18}
            color={"#131E30"}
            fontWeight={500}
            lineHeight={"18px"}
          >
            {t("candidate.personal_information")}
          </Typography>
          {isCandidate && (
            <Box>
              {isEditMode ? (
                <IconButton type={"submit"}>
                  <ArrowDownOnSquareIcon />
                </IconButton>
              ) : (
                <IconButton type={"button"} onClick={onEdit}>
                  <PencilSquareIcon />
                </IconButton>
              )}
            </Box>
          )}
        </StyledCandidateContentTitleContainer>
        <StyledDivider />
        <StyledCandidateContentRow desktop={desktop} direction={"row"}>
          <StyledCandidateContentRowTitleContainer
            direction={"row"}
            spacing={2}
          >
            <EnvelopeIcon />
            {desktop && <span>{t("candidate.email")}</span>}
          </StyledCandidateContentRowTitleContainer>

          <StyledCandidateContentValue props={{ isEditMode }}>
            {isEditMode ? (
              <TextField fullWidth value={email} disabled />
            ) : (
              email
            )}
          </StyledCandidateContentValue>
        </StyledCandidateContentRow>

        <StyledCandidateContentRow desktop={desktop} direction={"row"}>
          <StyledCandidateContentRowTitleContainer
            direction={"row"}
            spacing={2}
          >
            <PhoneIcon />
            {desktop && <span>{t("candidate.phone")}</span>}
          </StyledCandidateContentRowTitleContainer>

          <StyledCandidateContentValue props={{ isEditMode }}>
            {isEditMode ? (
              <TextField
                {...register("phone")}
                placeholder={t("candidate.phone") as string}
                fullWidth
              />
            ) : (
              phone
            )}
          </StyledCandidateContentValue>
        </StyledCandidateContentRow>

        <StyledCandidateContentRow desktop={desktop} direction={"row"}>
          <StyledCandidateContentRowTitleContainer
            direction={"row"}
            spacing={2}
          >
            <MapPinIcon />
            {desktop && <span>{t("candidate.location")}</span>}
          </StyledCandidateContentRowTitleContainer>

          <StyledCandidateContentValue props={{ isEditMode }}>
            {isEditMode ? (
              <TextField
                {...register("location")}
                placeholder={t("candidate.location") as string}
                fullWidth
              />
            ) : (
              location
            )}
          </StyledCandidateContentValue>
        </StyledCandidateContentRow>

        <StyledCandidateContentRow desktop={desktop} direction={"row"}>
          <StyledCandidateContentRowTitleContainer
            direction={"row"}
            spacing={2}
          >
            <GlobeAltIcon />
            {desktop && <span>{t("candidate.country")}</span>}
          </StyledCandidateContentRowTitleContainer>

          <StyledCandidateContentValue props={{ isEditMode }}>
            {isEditMode ? (
              <Controller
                name={"country"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <AutocompleteFilter
                    onChange={onChange}
                    value={value}
                    options={countries}
                    hasFlags
                    placeholder={t("candidate.country")}
                  />
                )}
              />
            ) : (
              country
            )}
          </StyledCandidateContentValue>
        </StyledCandidateContentRow>
      </Stack>
    </form>
  </StyledCandidateContentContainer>
);

export default PersonalInformationComponent;
