import React, { useEffect } from "react";
import { TFunction } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import KanbanBoard from "../../components/Kanban/index";
import {
  setApplicationStatus,
  setView,
} from "../../store_new/reducers/Applications";
import { setCandidateId } from "../../slices/Candidate";
import { getApplicationListState } from "../../store_new/selectors/Applications";

export default function CardView({ t }: { t: TFunction }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    newApplications,
    reviewApplications,
    hiredApplications,
    rejectedApplications,
  } = useSelector(getApplicationListState);

  const statuses = [
    {
      const: "new",
      title: t("status.new"),
    },
    {
      const: "review",
      title: t("status.in_progress"),
    },
    {
      const: "hired",
      title: t("status.hired"),
    },
    {
      const: "rejected",
      title: t("status.rejected"),
    },
  ];

  const handleOnChangeStatus = (
    applicationId: string,
    newStatus: string,
    index: string
  ) => {
    dispatch(
      setApplicationStatus({
        applicationId,
        newStatus,
        index: index ?? "0",
      })
    );
  };

  const redirectToCandidate = (urlKey: string, jobId: string) => {
    dispatch(
      setCandidateId({
        candidateUrlKey: urlKey,
        candidateJobId: jobId,
      })
    );
    navigate(`/applications/candidate/${urlKey}/${jobId}`);
  };

  const initialColumns = {
    "0": {
      items: newApplications || [],
      columnId: "new",
      title: t("status.new"),
      color: "#818CF8",
    },
    "1": {
      items: reviewApplications || [],
      columnId: "inProgress",
      title: t("status.in_progress"),
      color: "#F59E0B",
    },
    "2": {
      items: hiredApplications || [],
      columnId: "hired",
      title: t("status.hired"),
      color: "#22C55E",
    },
    "3": {
      items: rejectedApplications || [],
      columnId: "rejected",
      title: t("status.rejected"),
      color: "#EF4444",
    },
  };

  useEffect(() => {
    return () => {
      dispatch(setView("table"));
    };
  }, []);

  return (
    <KanbanBoard
      t={t}
      variant="candidate"
      statuses={statuses}
      initialColumns={initialColumns}
      onChangeStatus={handleOnChangeStatus}
      onItemClick={redirectToCandidate}
    />
  );
}
