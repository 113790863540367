import React, { useEffect } from "react";
import i18n from "i18next";
import { useMediaQuery } from "@mui/material";
import { initReactI18next, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { createTheme, ThemeOptions, ThemeProvider } from "@mui/material/styles";
import LanguageDetector from "i18next-browser-languagedetector";
import getRouter from "./router";
import { translationDe } from "./utils/translationDe";
import { translationEn } from "./utils/translationEn";
import {
  getCurrentUserData,
  getCurrentUserIsCandidate,
  getCurrentUserJassId,
  getCurrentUserLoadingState,
} from "./store_new/selectors/CurrentUser";
import { fetchUser } from "./store_new/reducers/CurrentUser";
import { Dark } from "./layouts/Default/mods/Dark";
import { setIsCompanyAddressDialogOpen } from "./store_new/reducers/dialogs";
import { fetchTalentPools } from "./store_new/reducers/TalentPools";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en_US: { translation: translationEn },
      de_DE: { translation: translationDe },
    },
    fallbackLng: "de_DE",
    interpolation: { escapeValue: false },
  });

const App_new = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const theme = createTheme(Dark as unknown as ThemeOptions);
  const desktop = useMediaQuery(theme.breakpoints.up("sm"));

  const isCandidate = useSelector(getCurrentUserIsCandidate);
  const jassId = useSelector(getCurrentUserJassId);

  const { username, isAgency, lang } = useSelector(getCurrentUserData);
  const isLoading = useSelector(getCurrentUserLoadingState);

  useEffect(() => {
    if (token && !username && !isLoading) {
      dispatch(fetchUser());
    }
  }, [token, username, isLoading]);

  useEffect(() => {
    if (username && isCandidate === false && !jassId && !isAgency) {
      dispatch(setIsCompanyAddressDialogOpen(true));
    } else {
      dispatch(setIsCompanyAddressDialogOpen(false));
    }
  }, [isCandidate, jassId, username]);

  useEffect(() => {
    if (isCandidate === false && username) {
      dispatch(fetchTalentPools());
    }
  }, [username, isCandidate]);

  useEffect(() => {
    localStorage.setItem("lang", lang);
    i18n.changeLanguage(lang);
  }, [lang]);

  return (
    <ThemeProvider theme={theme}>
      <RouterProvider
        router={getRouter({
          desktop,
          t,
          token,
          isCandidate: isCandidate as boolean,
        })}
      />
    </ThemeProvider>
  );
};

export default App_new;
