import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  apiDeleteCandidateApplication,
  apiFetchCandidateApplications,
  apiUpdateCandidateApplication,
  apiUploadCandidateDocument
} from "../../api/Candidate";
import { apiGetCountries } from "../../api/Company";
import { axiosInstanceDownload } from "../../api/config";
import { TCandidateApplicationDocument } from "../../models/ApplicationPage";
import { PayloadActionWithCallback } from "../../models/common";
import {
  deleteCandidateDocument,
  deleteCandidateDocumentFailed,
  downloadCandidateDocument,
  downloadCandidateDocumentFailed,
  fetchCandidateApplications,
  fetchCandidateApplicationsFailed,
  fetchCandidateApplicationsSuccess,
  fetchCountries,
  fetchCountriesFailed,
  fetchCountriesSuccess,
  updateCandidateApplication,
  updateCandidateApplicationFailed,
  uploadCandidateDocument,
  uploadCandidateDocumentFailed
} from "../reducers/Candidate";
import { TCompanySettingsCountriesResponse } from "../../models/CompanySettings";
import { getIsCandidateState } from "../selectors/Candidate";

function* handleOnFetchCandidateApplications() {
  const url = `/candidate/applications`;

  try {
    const { data } = yield call(apiFetchCandidateApplications, { url });
    yield put(
      fetchCandidateApplicationsSuccess({ applications: data.applications })
    );
  } catch (e) {
    yield put(fetchCandidateApplicationsFailed(e)); // TODO handle error
  }
}

function* handleOnUpdateCandidateApplication({
  payload
}: PayloadActionWithCallback<{
  applicationId: string;
  phone: string;
  location: string;
  country: string;
  salary_expectation?: string;
  earliest_start_date?: string;
  current_professional_status?: string;
  highest_degree?: string;
  skills?: [];
  driverLicense?: [];
  languages?: [];
  questions?: [];
}>) {
  const {
    applicationId,
    phone,
    location,
    country,
    salary_expectation,
    driverLicense,
    highest_degree,
    languages,
    skills,
    current_professional_status,
    earliest_start_date,
    questions,
    callback
  } = payload;
  const url = `/candidate/applications/update/${applicationId}`;

  const languagesModified = languages?.reduce((result: any, item: any) => {
    result[item.language] = item.level;
    return result;
  }, {});

  const skillsModified = skills?.reduce((result: any, item: any) => {
    result[item.id] = item.title;
    return result;
  }, {});

  const driversLicenseModified = driverLicense?.reduce(
    (result: any, item: any) => {
      result[item.value] = item.label;
      return result;
    },
    {}
  );
  const apiPayload = {
    phone,
    location,
    country_iso: country,
    salary_expectation,
    highest_degree,
    current_job: current_professional_status,
    earliest_start_date,
    answers: questions,
    languages_and_levels: JSON.stringify(languagesModified),
    skills: JSON.stringify(skillsModified),
    drivers_license: JSON.stringify(driversLicenseModified)
  };

  try {
    yield call(apiUpdateCandidateApplication, { url, apiPayload });
    yield call(callback);
  } catch (e) {
    yield put(updateCandidateApplicationFailed(e)); // TODO handle error
  }
}

function* handleOnFetchCountries() {
  // TODO move this saga to COMMON saga later
  try {
    const { data } = yield call(apiGetCountries);
    const {
      countries
    }: { readonly countries: TCompanySettingsCountriesResponse } = data;
    const modifiedCountries = countries.map((country) => ({
      label: country.Label,
      value: country.Id
    }));
    yield put(fetchCountriesSuccess({ countries: modifiedCountries }));
  } catch (e) {
    yield put(fetchCountriesFailed(e)); // TODO handle error
  }
}

function* handleOnUploadCandidateDocument({
  payload
}: PayloadActionWithCallback<{
  files: [File];
  jobUrlKey: string;
  type: "documents" | "cv";
}>) {
  const { files, jobUrlKey, type, callback } = payload;
  const url = `/candidate/applications/upload-${type}/${jobUrlKey}`;

  const apiPayload = { files };

  try {
    yield call(apiUploadCandidateDocument, {
      url,
      apiPayload
    });
    yield call(callback);
  } catch (e) {
    yield put(uploadCandidateDocumentFailed(e));
  }
}

function* handleOnDownloadCandidateDocument({
  payload
}: PayloadAction<{
  doc: TCandidateApplicationDocument;
}>) {
  const isCandidate: boolean = yield select(getIsCandidateState);
  const targetUrl = isCandidate
    ? "/candidate/documents/download"
    : "/company/candidate-documents/download";
  const { doc } = payload;
  const url = `${targetUrl}/${doc.application_id}/${doc.id}`;
  const docName = doc.filename?.split("/")?.pop()?.split("_")?.pop();

  try {
    const { data } = yield axiosInstanceDownload.post(url);
    const blob = new Blob([data], {
      type: "application/octet-stream"
    });
    const downloadUrl = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.download = docName as string; // Set the desired filename
    link.click();
    // Clean up the temporary URL and link element
    URL.revokeObjectURL(downloadUrl);
  } catch (e) {
    console.error("Error downloading the file:", e);
    yield put(downloadCandidateDocumentFailed(e));
  }
}

function* handleOnDeleteCandidateDocument({
  payload
}: PayloadActionWithCallback<{
  doc: TCandidateApplicationDocument;
}>) {
  const { doc, callback } = payload;
  const url = `/candidate/applications/remove-document/${doc.application_id}`;
  const apiPayload = { documentId: doc.id };

  try {
    yield call(apiDeleteCandidateApplication, {
      url,
      apiPayload
    });
    yield call(callback);
  } catch (e) {
    yield put(deleteCandidateDocumentFailed(e));
  }
}

function* ApplicationsSaga() {
  yield takeLatest(
    fetchCandidateApplications,
    handleOnFetchCandidateApplications
  );
  yield takeLatest(uploadCandidateDocument, handleOnUploadCandidateDocument);
  yield takeLatest(
    updateCandidateApplication,
    handleOnUpdateCandidateApplication
  );
  yield takeLatest(fetchCountries, handleOnFetchCountries);
  yield takeLatest(
    downloadCandidateDocument,
    handleOnDownloadCandidateDocument
  );
  yield takeLatest(deleteCandidateDocument, handleOnDeleteCandidateDocument);
}

export default ApplicationsSaga;
