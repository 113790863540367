import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { PayloadActionWithCallback } from "../../models/common";
import {
  ITalentPool,
  ITalentPoolCandidate,
  ITalentPoolProfile,
  ITalentPoolsState,
} from "../../models/TalentPools";
import { TJobListItem } from "../../models/JobList";
import { TCandidateApplication } from "../../models/ApplicationPage";

const initialState: ITalentPoolsState = {
  error: false,
  isListLoading: false,
  isProfileLoading: false,
  isAddTalentPoolLoading: false,
  isDeleteTalentPoolLoading: false,
  talentPoolsQuickSearch: "",
  talentPools: [],
  selectedTalentPool: null,
  editedTalentPool: null,
  selectedJob: null,
  talentPoolCandidates: undefined,
  isAddDeleteCandidateLoading: false,
  isCandidatesListLoading: false,
  filters: {
    quickSearch: "",
    location: "",
    skill: "",
  },
  pagination: {
    pageNum: 1,
    pageSize: 10,
    totalCount: 0,
  },
};

const talentPoolsReducers = {
  addUpdateTalentPool: (
    state: Draft<ITalentPoolsState>,
    _: PayloadActionWithCallback<{
      talentPool: ITalentPool;
    }>
  ) => {
    state.isAddTalentPoolLoading = true;
  },
  addUpdateTalentPoolSuccess: (state: Draft<ITalentPoolsState>) => {
    state.isAddTalentPoolLoading = false;
  },
  addUpdateTalentPoolFailed: (
    state: Draft<ITalentPoolsState>,
    action: PayloadAction<unknown>
  ) => {
    state.error = action.payload;
    state.isAddTalentPoolLoading = false;
  },
  fetchTalentPools: (state: Draft<ITalentPoolsState>) => {
    state.isListLoading = true;
  },
  fetchTalentPoolsSuccess: (
    state: Draft<ITalentPoolsState>,
    action: PayloadAction<ITalentPool[]>
  ) => {
    state.isListLoading = false;
    state.talentPools = action.payload;
  },
  fetchTalentPoolsFailed: (
    state: Draft<ITalentPoolsState>,
    action: PayloadAction<unknown>
  ) => {
    state.error = action.payload;
    state.isListLoading = false;
  },
  deleteTalentPool: (
    state: Draft<ITalentPoolsState>,
    _: PayloadActionWithCallback<{
      id?: number;
    }>
  ) => {
    state.isDeleteTalentPoolLoading = true;
  },
  deleteTalentPoolSuccess: (state: Draft<ITalentPoolsState>) => {
    state.isDeleteTalentPoolLoading = false;
  },
  deleteTalentPoolFailed: (
    state: Draft<ITalentPoolsState>,
    action: PayloadAction<unknown>
  ) => {
    state.error = action.payload;
    state.isDeleteTalentPoolLoading = false;
  },
  setEditedTalentPool: (
    state: Draft<ITalentPoolsState>,
    action: PayloadAction<ITalentPool | null>
  ) => {
    state.editedTalentPool = action.payload;
  },
  setTalentPoolsQuickSearch: (
    state: Draft<ITalentPoolsState>,
    action: PayloadAction<string>
  ) => {
    state.talentPoolsQuickSearch = action.payload;
  },
  setSelectedTalentPool: (
    state: Draft<ITalentPoolsState>,
    action: PayloadAction<ITalentPool>
  ) => {
    state.selectedTalentPool = action.payload;
    state.editedTalentPool = action.payload;
  },
};

const selectedTalentPoolReducers = {
  addCandidateInTalentPool: (
    state: Draft<ITalentPoolsState>,
    _: PayloadActionWithCallback<{
      id: number;
      applications: TCandidateApplication | TCandidateApplication[];
    }>
  ) => {
    state.isAddDeleteCandidateLoading = true;
  },
  addCandidateInTalentPoolSuccess: (state: Draft<ITalentPoolsState>) => {
    state.isAddDeleteCandidateLoading = false;
  },
  addCandidateInTalentPoolFailed: (
    state: Draft<ITalentPoolsState>,
    action: PayloadAction<unknown>
  ) => {
    state.error = action.payload;
    state.isAddDeleteCandidateLoading = false;
  },
  fetchTalentPoolCandidates: (
    state: Draft<ITalentPoolsState>,
    _: PayloadAction<number>
  ) => {
    state.isCandidatesListLoading = true;
  },
  fetchTalentPoolCandidatesSuccess: (
    state: Draft<ITalentPoolsState>,
    action: PayloadAction<{
      readonly candidates: ITalentPoolCandidate[];
      readonly totalCount: number;
    }>
  ) => {
    state.isCandidatesListLoading = false;
    state.talentPoolCandidates = action.payload.candidates;
    state.pagination.totalCount = action.payload.totalCount;
  },
  fetchTalentPoolCandidatesFailed: (
    state: Draft<ITalentPoolsState>,
    action: PayloadAction<unknown>
  ) => {
    state.error = action.payload;
    state.isCandidatesListLoading = false;
  },
  deleteCandidate: (
    state: Draft<ITalentPoolsState>,
    _: PayloadActionWithCallback<{
      id: number;
      applicationId: number;
    }>
  ) => {
    state.isAddDeleteCandidateLoading = true;
  },
  deleteCandidateSuccess: (state: Draft<ITalentPoolsState>) => {
    state.isAddDeleteCandidateLoading = false;
  },
  deleteCandidateFailed: (
    state: Draft<ITalentPoolsState>,
    action: PayloadAction<unknown>
  ) => {
    state.error = action.payload;
    state.isAddDeleteCandidateLoading = false;
  },
  addCandidateToJob: (
    state: Draft<ITalentPoolsState>,
    _: PayloadActionWithCallback<{
      id: number;
    }>
  ) => {
    state.isAddTalentPoolLoading = true;
  },
  addCandidateToJobSuccess: (state: Draft<ITalentPoolsState>) => {
    state.isAddTalentPoolLoading = false;
  },
  addCandidateToJobFailed: (
    state: Draft<ITalentPoolsState>,
    action: PayloadAction<unknown>
  ) => {
    state.error = action.payload;
    state.isAddTalentPoolLoading = false;
  },
  setTalentPoolCandidateFilter: (
    state: Draft<ITalentPoolsState>,
    action: PayloadAction<{
      filterType: keyof {
        readonly quickSearch: string;
        readonly location: string;
        readonly skill?: string;
      };
      value: string;
    }>
  ) => {
    const { filterType, value } = action.payload;
    state.filters[filterType] = value;
  },
  setTalentPoolCandidatePage: (
    state: Draft<ITalentPoolsState>,
    action: PayloadAction<number>
  ) => {
    state.pagination.pageNum = action.payload;
  },
  setSelectedJob: (
    state: Draft<ITalentPoolsState>,
    action: PayloadAction<TJobListItem | null>
  ) => {
    state.selectedJob = action.payload;
  },
  addCandidateInTalentPoolManually: (
    state: Draft<ITalentPoolsState>,
    _: PayloadActionWithCallback<ITalentPoolProfile & { talentpool_id: string }>
  ) => {
    state.isProfileLoading = true;
  },
  addCandidateInTalentPoolManuallySuccess: (
    state: Draft<ITalentPoolsState>
  ) => {
    state.isProfileLoading = false;
  },
  addCandidateInTalentPoolManuallyFailed: (
    state: Draft<ITalentPoolsState>,
    action: PayloadAction<unknown>
  ) => {
    state.error = action.payload;
    state.isProfileLoading = false;
  },
};

export const TalentPoolsSlice = createSlice({
  name: "TALENT_POOLS",
  initialState,
  reducers: {
    ...talentPoolsReducers,
    ...selectedTalentPoolReducers,
  },
});

export const {
  addUpdateTalentPool,
  addUpdateTalentPoolFailed,
  addUpdateTalentPoolSuccess,
  fetchTalentPools,
  fetchTalentPoolsFailed,
  fetchTalentPoolsSuccess,
  setSelectedTalentPool,
  deleteTalentPool,
  deleteTalentPoolFailed,
  deleteTalentPoolSuccess,
  setEditedTalentPool,
  setTalentPoolsQuickSearch,
  addCandidateInTalentPool,
  addCandidateInTalentPoolFailed,
  addCandidateInTalentPoolSuccess,
  fetchTalentPoolCandidates,
  fetchTalentPoolCandidatesFailed,
  fetchTalentPoolCandidatesSuccess,
  deleteCandidate,
  deleteCandidateFailed,
  deleteCandidateSuccess,
  addCandidateToJob,
  addCandidateToJobFailed,
  addCandidateToJobSuccess,
  setTalentPoolCandidateFilter,
  setTalentPoolCandidatePage,
  setSelectedJob,
  addCandidateInTalentPoolManually,
  addCandidateInTalentPoolManuallySuccess,
  addCandidateInTalentPoolManuallyFailed,
} = TalentPoolsSlice.actions;

export default TalentPoolsSlice.reducer;
