import React from "react";
import { createBrowserRouter, Navigate, Outlet } from "react-router-dom";
import { TFunction } from "i18next";
import Messages_NewPage from "./pages/Messages_New";
import ApplicationPage_new from "./pages/ApplicationPage_new";
import MainLayout from "./layouts/Main";
import JobListPageNew from "./pages/JobList_new";
import ApplicationsPageNew from "./pages/Applications_new";
// import UserSettingsPage from "./pages/UserSettings";
import CompanySettings_new from "./pages/CompanySettings_new";
import SingleJob_new from "./pages/SingleJob_new";
import TeamSettingsPageNew from "./pages/TeamSettings_new";
import MagicLinkConfirmationPage from "./pages/MagicLinkConfirmation";
import LoginPageNew from "./pages/Login_new";
import ResetPasswordPageNew from "./pages/Password_new/Reset";
import NewPasswordPageNew from "./pages/Password_new/NewPassword";
import ResendPasswordPageNew from "./pages/Password_new/Resend";
import ConfirmationPasswordPageNew from "./pages/Password_new/Confirmation";
import CompanyRegisterPageNew from "./pages/Registration_new/Company";
import RegisterCompany_new from "./pages/Registration_new/Company";
import RecruiterRegisterPageNew from "./pages/Registration_new/Recruiter";
import ResendEmailRegisterPageNew from "./pages/Registration_new/Resend";
import JobPreview from "./pages/JobPreview";
import AdditionalQuestions_new from "./pages/AdditionalQuestions_new";
import CandidateDashboard_new from "./pages/CandidateDashboard_new";
import ConfirmationRegisterPageNew from "./pages/Registration_new/Confirmation";
import TemplatePreviewNewPage from "./pages/TemplatePreview_new";
import CompanyInfoPage_new from "./pages/CompanyInfo_new";
import AppliedResendEmail from "./pages/AppliedResendEmail";
import JobWidgetPageNew from "./pages/JobWidgetPage_new";
import CustomerPage_New from "./pages/Customers_new";
import CustomerDetails_New from "./pages/CustomerDetails_new";
import NewMessageRedirectionNew from "./pages/NewMessageRedirection_new";
import AgencyExistingCompanyConfirmation_new from "./pages/Registration_new/AgencyExistingCompanyConfirmation";
import Dashboard from "./pages/Dashboard";
import TalentPoolsNewPage from "./pages/TalentPools_new";
import TalentPoolNewPage from "./pages/TalentPool_new";
import UserSettingsPageNew from "./pages/UserSettings_new";
import DiscoverPageNew from "./pages/DiscoverPage";
import InventoryPageNew from "./pages/InventoryPage";
import ProductPageNew from "./pages/ProductPage";
import PackagePageNew from "./pages/PackagePage";
import DetailedApplication from "./pages/TalentPool_new/DetailedApplication";
import { PAGE_TYPES } from "./models/TopHeader";
import { SIDEBAR_MENU_ITEM_IDS } from "./models/Menu";

const getRouter = ({
  desktop,
  t,
  token,
  isCandidate,
}: {
  desktop: boolean;
  t: TFunction;
  token: string | null;
  isCandidate: boolean;
}) => {
  const PrivateRoute = () => {
    const path = window.location.pathname;
    if (!token) {
      if (path.includes("/applications/candidate/")) {
        sessionStorage.setItem("redirection-url", window.location.pathname);
      }
      return <Navigate to={"/login"} replace />;
    }
    return <MainLayout />;
  };

  const UnauthorizedRoute = () => {
    if (token)
      return (
        <Navigate
          to={
            isCandidate !== undefined && isCandidate === false
              ? `/dashboard`
              : `/`
          }
          replace
        />
      );

    return <Outlet />;
  };

  return createBrowserRouter([
    {
      path: "/",
      element: <PrivateRoute />,
      children: [
        {
          path: "/applications",
          element: <ApplicationsPageNew />,
          handle: {
            title: t("navigation.applications"),
            sidebarMenu: SIDEBAR_MENU_ITEM_IDS.APPLICATIONS,
          },
        },
        {
          path: "/customers",
          element: <CustomerPage_New />,
          handle: {
            title: t("navigation.customers"),
            sidebarMenu: SIDEBAR_MENU_ITEM_IDS.CUSTOMERS,
          },
        },
        {
          path: "/customer/details/:id",
          element: <CustomerDetails_New />,
          handle: {
            title: "",
            sidebarMenu: SIDEBAR_MENU_ITEM_IDS.CUSTOMERS,
            pageType: PAGE_TYPES.CUSTOMER_DETAILS,
          },
        },
        {
          path: "/applications/candidate/:key/:job_id",
          element: <ApplicationPage_new desktop={desktop} />,
          handle: {
            pageType: PAGE_TYPES.SINGLE_APPLICATION,
            sidebarMenu: SIDEBAR_MENU_ITEM_IDS.APPLICATIONS,
          },
        },
        {
          path: "/jobs/applications/candidate/:key/:job_id",
          element: <ApplicationPage_new desktop={desktop} />,
          handle: {
            pageType: PAGE_TYPES.SINGLE_APPLICATION,
            sidebarMenu: SIDEBAR_MENU_ITEM_IDS.JOBS,
          },
        },
        {
          path: "/talentpool/:talentpoolId/applications/candidate/:key/:job_id",
          element: <ApplicationPage_new desktop={desktop} />,
          handle: {
            pageType: PAGE_TYPES.SINGLE_APPLICATION,
            sidebarMenu: SIDEBAR_MENU_ITEM_IDS.TALENTPOOLS,
          },
        },
        {
          path: "/talentpool/:talentpoolId/candidate/",
          element: <DetailedApplication desktop={desktop} />,
          handle: {
            pageType: PAGE_TYPES.SINGLE_APPLICATION,
            sidebarMenu: SIDEBAR_MENU_ITEM_IDS.TALENTPOOLS,
          },
        },
        {
          path: "/candidate-dashboard/job/:key",
          element: <ApplicationPage_new desktop={desktop} />,
          handle: { pageType: PAGE_TYPES.SINGLE_APPLICATION_CANDIDATE },
        },
        {
          path: "/",
          element: <CandidateDashboard_new desktop={desktop} />,
          handle: {
            title: "Dashboard",
            sidebarMenu: SIDEBAR_MENU_ITEM_IDS.DASHBOARD,
          },
        },
        {
          path: "/dashboard",
          element: <Dashboard />,
          handle: {
            title: "Dashboard",
            sidebarMenu: SIDEBAR_MENU_ITEM_IDS.DASHBOARD,
          },
        },
        {
          path: "/talentpools",
          element: <TalentPoolsNewPage />,
          handle: {
            title: t("talentPools.talentPools"),
            sidebarMenu: SIDEBAR_MENU_ITEM_IDS.TALENTPOOLS,
          },
        },
        {
          path: "/talentpool/:id",
          element: <TalentPoolNewPage />,
          handle: {
            pageType: PAGE_TYPES.TALENT_POOL,
            sidebarMenu: SIDEBAR_MENU_ITEM_IDS.TALENTPOOLS,
          },
        },
        {
          path: "/messages",
          element: <Messages_NewPage desktop={desktop} />,
          handle: {
            title: t("navigation.messages"),
            sidebarMenu: SIDEBAR_MENU_ITEM_IDS.MESSAGES,
            pageType: PAGE_TYPES.MESSAGES_MOBILE,
          },
        },
        {
          path: "/jobs/:tab",
          element: <JobListPageNew />,
          handle: { title: "Jobs", sidebarMenu: SIDEBAR_MENU_ITEM_IDS.JOBS },
        },
        {
          path: "/jobs/:key/:tab",
          element: <SingleJob_new />,
          handle: {
            pageType: PAGE_TYPES.SINGLE_JOB,
            sidebarMenu: SIDEBAR_MENU_ITEM_IDS.JOBS,
          },
        },
        {
          path: "/user-settings",
          element: <UserSettingsPageNew desktop={desktop} />,
          handle: {
            title: t("userSettings.user_settings"),
            sidebarMenu: SIDEBAR_MENU_ITEM_IDS.USER_SETTINGS,
          },
        },
        {
          path: "/company-settings",
          element: <CompanySettings_new />,
          handle: {
            title: t("company_settings.title"),
            sidebarMenu: SIDEBAR_MENU_ITEM_IDS.COMPANY_SETTINGS,
          },
        },
        {
          path: "/team/settings/:tab",
          element: <TeamSettingsPageNew />,
          handle: { title: "Team", sidebarMenu: SIDEBAR_MENU_ITEM_IDS.TEAM },
        },
        {
          path: "/job-widget",
          element: <JobWidgetPageNew desktop={desktop} />,
          handle: {
            title: t("job_widget.title"),
            sidebarMenu: SIDEBAR_MENU_ITEM_IDS.JOB_WIDGET,
          },
        },
        {
          path: "/discover",
          element: <DiscoverPageNew />,
          handle: {
            title: t("navigation.discover_all_products"),
            sidebarMenu: SIDEBAR_MENU_ITEM_IDS.DISCOVER,
          },
        },
        {
          path: "/product",
          element: <ProductPageNew />,
          handle: {
            sidebarMenu: SIDEBAR_MENU_ITEM_IDS.DISCOVER,
            pageType: PAGE_TYPES.PRODUCT,
          },
        },
        {
          path: "/package",
          element: <PackagePageNew />,
          handle: {
            sidebarMenu: SIDEBAR_MENU_ITEM_IDS.DISCOVER,
            pageType: PAGE_TYPES.PACKAGE,
          },
        },
        {
          path: "/inventory",
          element: <InventoryPageNew />,
          handle: {
            title: t("navigation.inventory"),
            sidebarMenu: SIDEBAR_MENU_ITEM_IDS.INVENTORY,
          },
        },
      ],
    },
    {
      path: "/",
      element: <UnauthorizedRoute />,
      children: [
        { path: "/login", element: <LoginPageNew desktop={desktop} /> },
        {
          path: "/forgot-password",
          element: <ResetPasswordPageNew desktop={desktop} />,
        },
        {
          path: "/forgot-password/resend",
          element: <ResendPasswordPageNew desktop={desktop} />,
        },
        {
          path: "/password/confirmation",
          element: <ConfirmationPasswordPageNew desktop={desktop} />,
        },
        {
          path: "/password/recovery/:code",
          element: <NewPasswordPageNew desktop={desktop} />,
        },
        {
          path: "/register/company",
          element: <CompanyRegisterPageNew desktop={desktop} />,
        },
        {
          path: "/register/recruiter",
          element: <RecruiterRegisterPageNew desktop={desktop} />,
        },
        {
          path: "/registration/invitation-confirm/:urlKey",
          element: <RecruiterRegisterPageNew desktop={desktop} />,
        },
        {
          path: "/registration/agency/invitation-accept/:urlKey",
          element: <AgencyExistingCompanyConfirmation_new />,
        },
        {
          path: "/registration/agency/invitation-confirm/:urlKey",
          element: <RegisterCompany_new desktop={desktop} />,
        },
        {
          path: "/register/resend-email",
          element: <ResendEmailRegisterPageNew desktop={desktop} />,
        },
      ],
    },
    {
      path: "/magic-link-confirmation",
      element: <MagicLinkConfirmationPage desktop={desktop} />,
    },
    {
      path: "/template/preview/:id",
      element: <TemplatePreviewNewPage desktop={desktop} />,
    },
    {
      path: "/companies/:companyname",
      element: <CompanyInfoPage_new desktop={desktop} />,
    },
    { path: "/job/:key", element: <JobPreview desktop={desktop} /> },
    {
      path: "/candidate/login/:code", // TODO check this route
      element: <AdditionalQuestions_new desktop={desktop} />,
    },
    {
      path: "/candidate/login/additional-questions/:code",
      element: <AdditionalQuestions_new desktop={desktop} />,
    },
    {
      path: "/messages/:code/:jobId/:applicationId/:recruiterId",
      element: <NewMessageRedirectionNew />,
    },
    {
      path: "/registration/confirm/:code",
      element: <ConfirmationRegisterPageNew />,
    },
    {
      path: "/successful-application",
      element: <AppliedResendEmail desktop={desktop} />,
    },
  ]);
};

export default getRouter;
