export interface ISidebarMenuItem {
  readonly label: string;
  readonly icon: Element;
  readonly route: string;
  readonly nodeId: string;
  readonly comingSoon?: boolean;
  readonly children?: {
    readonly label: string;
    readonly icon: Element;
    readonly route: string;
    readonly nodeId: string;
  };
}

export enum SIDEBAR_MENU_ITEM_IDS {
  JOBS = "jobs",
  APPLICATIONS = "applications",
  MESSAGES = "messages",
  COMPANY_SETTINGS = "companySettings",
  JOB_WIDGET = "jobWidget",
  TEAM = "team",
  USER_SETTINGS = "userSettings",
  DASHBOARD = "dashboard",
  CUSTOMERS = "customers",
  TALENTPOOLS = "talentpools",
  SHOP = "shop",
  DISCOVER = "discover",
  INVENTORY = "inventory",
}
