import React, { useEffect, useMemo, useState } from "react";
import { Typography } from "@mui/material";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Languages from "./Languages";
import Questions from "./Questions";
import Skills from "./Skills";
import EditModal from "./EditModal";
import {
  getApplicationAdditionalInfo,
  getCandidateAnswers,
  getCurrentApplication,
} from "../../../store_new/selectors/Applications";
import { getCandidateState } from "../../../store_new/selectors/Candidate";
import { getSingleJobState } from "../../../store_new/selectors/SingleJob";
import { TJobDetails, TJobQuestions } from "../../../models/SingleJob";
import { getJobPreviewState } from "../../../store_new/selectors/JobPreview";
import {
  StyledCandidateContentContainer,
  StyledCandidateContentTitleContainer,
  StyledDivider,
} from "../styles";
import { convertRequiredProperties } from "../../../utils";
import { fetchJob } from "../../../store_new/reducers/JobPreview";

const AdditionalInfo = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const candidateAnswers = useSelector(getCandidateAnswers);
  const currentApplication = useSelector(getCurrentApplication);

  const { isCandidate } = useSelector(getCandidateState);
  const {
    additionalQuestions,
    mainQuestions,
    skills,
    languages,
    driverLicenses,
  } = useSelector(getApplicationAdditionalInfo);
  const { jobDetails, jobQuestions } = useSelector(getSingleJobState);

  const [isOpenEditModal, setIsOpenEditModal] = useState(false);

  const targetJobDetails = useMemo(
    () => jobDetails || currentApplication?.job,
    [jobDetails, currentApplication]
  );

  const showEditIcon = isCandidate
    ? currentApplication?.job.is_archived != "1"
    : jobDetails && jobDetails?.is_archived != "1";

  const formattedTargetJobDetails: any =
    convertRequiredProperties(targetJobDetails);

  const {
    jobData: { job_questions: candidateJobQuestions },
  } = useSelector(getJobPreviewState);

  const sortedQuestion = useMemo(() => {
    let sorted: any[] = mainQuestions || [];

    if (targetJobDetails && mainQuestions) {
      sorted = mainQuestions.sort((a) => {
        if (!targetJobDetails[`${a.type}_required` as keyof TJobDetails])
          return 1;
        return -1;
      });
    }

    return sorted;
  }, [targetJobDetails, mainQuestions]);

  const targetAdditionalQuestions = useMemo(() => {
    let questions = [];

    if (isCandidate) questions = additionalQuestions;
    else questions = candidateAnswers;

    if (!questions.length) {
      const targetJobQuestions = (jobQuestions ||
        candidateJobQuestions) as TJobQuestions[];
      questions =
        targetJobQuestions?.map(({ question, is_answer_type_yesno }) => ({
          label: question,
          isTypeYesNo: Boolean(is_answer_type_yesno),
          value: "",
        })) || [];
    }

    return questions;
  }, [additionalQuestions, candidateAnswers, jobQuestions]);

  const handleOnOpenEditModal = () => {
    setIsOpenEditModal(true);
  };

  const handleOnCloseEditModal = () => {
    setIsOpenEditModal(false);
  };

  useEffect(() => {
    if (showEditIcon) {
      const key = jobDetails?.url_key || currentApplication?.job.url_key;
      dispatch(
        fetchJob({
          jobUrlKey: key as string,
        })
      );
    }
  }, [jobDetails?.is_archived]);

  return (
    <StyledCandidateContentContainer spacing={2}>
      <StyledCandidateContentTitleContainer>
        <Typography
          fontSize={18}
          color={"#131E30"}
          fontWeight={500}
          lineHeight={"18px"}
        >
          {t("candidate.additional_information")}
        </Typography>
        {showEditIcon && (
          <PencilSquareIcon
            width={24}
            height={24}
            onClick={handleOnOpenEditModal}
            cursor={"pointer"}
            stroke={"#178CF2"}
          />
        )}
      </StyledCandidateContentTitleContainer>
      <StyledDivider />
      <Questions
        t={t}
        questions={sortedQuestion}
        jobDetails={formattedTargetJobDetails}
      />
      <Skills
        isActive={Boolean(formattedTargetJobDetails?.driver_license_required)}
        t={t}
        skills={driverLicenses}
        label={t("candidate_questions.driving_licenses") as string}
      />
      <Languages
        isActive={Boolean(formattedTargetJobDetails?.langs_required)}
        t={t}
        languages={languages}
      />
      <Skills
        isActive={Boolean(formattedTargetJobDetails?.skills_required)}
        t={t}
        skills={skills}
        label={t("candidate_questions.skills") as string}
      />
      <Questions t={t} questions={targetAdditionalQuestions} />
      <EditModal isOpen={isOpenEditModal} onClose={handleOnCloseEditModal} />
    </StyledCandidateContentContainer>
  );
};

export default AdditionalInfo;
