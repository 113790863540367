import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomerComponent from "./component";
import { DIALOG_TYPE } from "../../models/common";
import {
  fetchCustomers,
  inviteCustomers,
  resetCustomersState,
  importCustomers,
  setCustomersFilters,
  fetchJassCustomers,
  setSelectedImportCustomers,
} from "../../store_new/reducers/Customers";
import {
  getCustomersFilterState,
  getCustomersListState,
  getCustomersState,
} from "../../store_new/selectors/Customers";
import { TCustomersFilters, TJassCustomer } from "../../models/Agency";
import { addMessage } from "../../slices/NotificationSlice/GlobalNotificationSlice";
import { getCurrentUserJassId } from "../../store_new/selectors/CurrentUser";

const CustomerPage_New = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { list } = useSelector(getCustomersListState);
  const jassId = useSelector(getCurrentUserJassId);
  const { isListLoading, isLoading, jassCustomers, selectedImportCustomers } =
    useSelector(getCustomersState);
  const { quickSearch, location } = useSelector(getCustomersFilterState);
  const [emailsToInvite, setEmailsToInvite] = useState<string[]>([]);
  const [openedDialogType, setOpenedDialogType] = useState<DIALOG_TYPE | null>(
    null
  );

  const handleOnChangeEmails = (emails: string[]) => {
    setEmailsToInvite(emails);
  };

  const handleOnOpenInviteDialog = () => {
    setEmailsToInvite([]);
    setOpenedDialogType(DIALOG_TYPE.INVITE);
  };

  const handleOnCloseDialog = () => {
    setOpenedDialogType(null);
  };

  const handleOnInvite = () => {
    dispatch(
      inviteCustomers({
        emails: emailsToInvite,
        callback: () => {
          setOpenedDialogType(null);
          dispatch(
            addMessage({
              type: "success",
              title: t("customers.invite_snackbar_success"),
            })
          );
        },
      })
    );
  };

  const handleOnOpenImportCustomersDialog = () => {
    dispatch(
      fetchJassCustomers({
        callback: () => {
          setOpenedDialogType(DIALOG_TYPE.SYNC_CUSTOMERS);
        },
      })
    );
  };

  const handleOnSuccesImportCustomers = () => {
    dispatch(fetchCustomers());
    handleOnCloseDialog();
    dispatch(
      addMessage({
        type: "success",
        title: t("customers.import_success_snackbar"),
      })
    );
  };

  const handleOnImportCustomers = () => {
    const selectedImportCustomersEmails = selectedImportCustomers.map(
      (item) => item.email
    );
    dispatch(
      importCustomers({
        emails: selectedImportCustomersEmails,
        callback: () => handleOnSuccesImportCustomers(),
      })
    );
  };

  const handleOnChangeFilter = ({
    value,
    filterType,
  }: {
    readonly value: string;
    readonly filterType: keyof TCustomersFilters;
  }) => {
    dispatch(setCustomersFilters({ filterType, value }));
  };

  const handleOnSelectCustomer = (id: string) => {
    navigate(`/customer/details/${id}`);
  };

  const handleSelectDescelectCustomer = (customer: TJassCustomer) => {
    dispatch(setSelectedImportCustomers(customer));
  };

  useEffect(() => {
    dispatch(fetchCustomers());
  }, [quickSearch, location]);

  useEffect(() => {
    return () => {
      dispatch(resetCustomersState());
    };
  }, []);

  return (
    <CustomerComponent
      t={t}
      list={list}
      isListLoading={isListLoading}
      isLoading={isLoading}
      openedDialogType={openedDialogType}
      emailsToInvite={emailsToInvite}
      jassId={jassId}
      location={location}
      quickSearch={quickSearch}
      selectedImportCustomers={selectedImportCustomers}
      jassCustomers={jassCustomers}
      onSelectDescelectCustomer={handleSelectDescelectCustomer}
      onSelectCustomer={handleOnSelectCustomer}
      onCloseDialog={handleOnCloseDialog}
      onEmailsChange={handleOnChangeEmails}
      onInvite={handleOnInvite}
      onOpenInviteDialog={handleOnOpenInviteDialog}
      onOpenImportCustomersDialog={handleOnOpenImportCustomersDialog}
      onImportCustomers={handleOnImportCustomers}
      onChangeFilters={handleOnChangeFilter}
    />
  );
};

export default CustomerPage_New;
