import React from "react";
import dayjs from "dayjs";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  Stack,
  TextField,
} from "@mui/material";
import { Control, Controller } from "react-hook-form";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Languages from "../../../AdditionalQuestions_new/components/Languages";
import AdditionalQuestionsQuestions from "../../../AdditionalQuestions_new/components/Questions";
import Skills from "../../../AdditionalQuestions_new/components/Skills";
import AdditionalQuestionsDriverLicenses from "../../../AdditionalQuestions_new/components/DriverLicenses";
import { IApplicationAdditionalInfoComponent } from "../../../../models/ApplicationPage";
import { TAdditionalQuestionsFormFields } from "../../../../models/JobPreview";
import { isValidNumber } from "../../../../utils/common";

const AdditionalInfoEditModalComponent = ({
  t,
  control,
  isOpen,
  isLoading,
  register,
  handleSubmit,
  onSubmit,
  onClose,
}: IApplicationAdditionalInfoComponent) => (
  <Dialog onClose={onClose} open={isOpen} fullWidth>
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogTitle>{t("candidate_questions.editAdditionalInfo")}</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Box>
            <InputLabel>{t("candidate_questions.highest_degree")}</InputLabel>
            <TextField
              {...register("highest_degree")}
              placeholder={t("candidate_questions.highest_degree") as string}
              fullWidth
            />
          </Box>
          <Box>
            <InputLabel>
              {t("candidate_questions.current_professional_status")}
            </InputLabel>
            <TextField
              {...register("current_professional_status")}
              placeholder={
                t("candidate_questions.current_professional_status") as string
              }
              fullWidth
            />
          </Box>
          <Box>
            <InputLabel>
              {t("candidate_questions.earliest_start_date")}
            </InputLabel>
            <Controller
              name={"earliest_start_date"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <Box className={"input-field"}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      sx={{ width: "100%" }}
                      format="DD.MM.YYYY"
                      value={
                        value && value != "0000-00-00 00:00:00"
                          ? dayjs(value)
                          : null
                      }
                      onChange={onChange}
                    />
                  </LocalizationProvider>
                </Box>
              )}
            />
          </Box>
          <Box>
            <InputLabel>
              {t("candidate_questions.salary_expectation")}
            </InputLabel>
            <TextField
              {...register("salary_expectation")}
              placeholder={
                t("candidate_questions.salary_expectation") as string
              }
              fullWidth
              onKeyPress={(e) => isValidNumber(e as unknown as KeyboardEvent)}
            />
          </Box>
          <Box>
            <AdditionalQuestionsDriverLicenses
              boldHeading
              control={
                control as unknown as Control<TAdditionalQuestionsFormFields>
              }
              desktop={false}
              companyColor={"#178CF2"}
            />
          </Box>
          <Box>
            <Languages
              boldHeading
              control={
                control as unknown as Control<TAdditionalQuestionsFormFields>
              }
              desktop={false}
              companyColor={"#178CF2"}
              isEditDialog
            />
          </Box>
          <Box>
            <Skills
              boldHeading
              control={
                control as unknown as Control<TAdditionalQuestionsFormFields>
              }
              desktop={false}
              companyColor={"#178CF2"}
            />
          </Box>
          <Box>
            <AdditionalQuestionsQuestions
              boldHeading
              control={
                control as unknown as Control<TAdditionalQuestionsFormFields>
              }
              desktop={false}
            />
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button variant={"outlined"} onClick={onClose} disabled={isLoading}>
          {t("button.secondCancel")}
        </Button>
        <Button
          endIcon={
            isLoading ? (
              <CircularProgress
                size={16}
                style={{
                  color: "#9A9FAF",
                }}
              />
            ) : null
          }
          variant={"contained"}
          disabled={isLoading}
          type={"submit"}
        >
          {t("button.save")}
        </Button>
      </DialogActions>
    </form>
  </Dialog>
);

export default AdditionalInfoEditModalComponent;
