import React, { SyntheticEvent, useEffect, useState, useMemo } from "react";
import CircleIcon from "@mui/icons-material/Circle";
import {
  BriefcaseIcon,
  CogIcon,
  CreditCardIcon,
  RectangleGroupIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { useMatches, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SidebarComponent from "./component";
import { getMenuItems } from "./config";
import { openJobEditor } from "../../../store_new/reducers/JobEditor";
import {
  getCurrentUserData,
  getCurrentUserIsCandidate,
  getCurrentUserLoadingState,
} from "../../../store_new/selectors/CurrentUser";
import { setMobileDrawerOpen } from "../../../slices/Navigation/navigationSlice";
import {
  setIsLimitDialogOpen,
  setIsSelectCustomerDialogOpen,
} from "../../../store_new/reducers/dialogs";
import { getCompanySettingsCompanyState } from "../../../store_new/selectors/Company";
import { ISidebarMenuItem, SIDEBAR_MENU_ITEM_IDS } from "../../../models/Menu";
import { JOB_STATUSES } from "../../../models/JobList";
import { TEAM_USER_STATUSES } from "../../../models/Team";
import { StyledSubItemIconContainer } from "./styles";

const Sidebar = ({
  desktop,
  mobileDrawerOpen,
}: {
  readonly desktop: boolean;
  readonly mobileDrawerOpen: boolean;
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const matches = useMatches();
  const {
    handle: { sidebarMenu },
  } = (matches?.[1] as { handle: { sidebarMenu: SIDEBAR_MENU_ITEM_IDS } }) || {
    handle: { sidebarMenu: "" },
  };

  const { username, isAdmin, isAgency, applicationsAllowed } =
    useSelector(getCurrentUserData);
  const isCandidate = useSelector(getCurrentUserIsCandidate);
  const isLoading = useSelector(getCurrentUserLoadingState);
  const { company } = useSelector(getCompanySettingsCompanyState);

  const agencyAllMenuItems = [
    {
      label: "Dashboard",
      icon: <RectangleGroupIcon />,
      nodeId: SIDEBAR_MENU_ITEM_IDS.DASHBOARD,
      route: `/dashboard`,
    },
    {
      label: "Jobs",
      icon: <BriefcaseIcon />,
      nodeId: SIDEBAR_MENU_ITEM_IDS.JOBS,
      route: `/jobs/${JOB_STATUSES.ACTIVE}`,
    },
    {
      label: t("navigation.applications"),
      icon: <UsersIcon />,
      nodeId: SIDEBAR_MENU_ITEM_IDS.APPLICATIONS,
      route: `/applications`,
    },
    {
      label: t("navigation.customers"),
      icon: <UsersIcon />,
      nodeId: SIDEBAR_MENU_ITEM_IDS.CUSTOMERS,
      route: `/customers`,
    },
    {
      label: "Shop",
      icon: <CreditCardIcon />,
      route: `/discover`,
      nodeId: SIDEBAR_MENU_ITEM_IDS.DISCOVER,
    },
    /*  AFTER FINISH INVENTORY
   {
      label: "Shop",
      icon: <CreditCardIcon />,
      nodeId: "shop",
      children: [
        {
          label: t("navigation.discover"),
          icon: (
            <StyledSubItemIconContainer>
              <CircleIcon className={"circle-icon"} />
            </StyledSubItemIconContainer>
          ),
          nodeId: SIDEBAR_MENU_ITEM_IDS.DISCOVER,
          route: `/discover`,
        },
        {
          label: t("navigation.inventory"),
          icon: (
            <StyledSubItemIconContainer>
              <CircleIcon className={"circle-icon"} />
            </StyledSubItemIconContainer>
          ),
          nodeId: SIDEBAR_MENU_ITEM_IDS.INVENTORY,
          route: `/inventory`,
        },
      ],
    }, */
    {
      label: t("navigation.settings"),
      icon: <CogIcon />,
      nodeId: "settings",
      children: [
        {
          label: t("navigation.company_profiles"),
          icon: (
            <StyledSubItemIconContainer>
              <CircleIcon className={"circle-icon"} />
            </StyledSubItemIconContainer>
          ),
          nodeId: SIDEBAR_MENU_ITEM_IDS.COMPANY_SETTINGS,
          route: `/company-settings`,
        },
        {
          label: "Job Widget",
          icon: (
            <StyledSubItemIconContainer>
              <CircleIcon className={"circle-icon"} />
            </StyledSubItemIconContainer>
          ),
          nodeId: SIDEBAR_MENU_ITEM_IDS.JOB_WIDGET,
          route: `/job-widget`,
        },
        isAdmin
          ? {
              label: "Team",
              icon: (
                <StyledSubItemIconContainer>
                  <CircleIcon className={"circle-icon"} />
                </StyledSubItemIconContainer>
              ),
              nodeId: SIDEBAR_MENU_ITEM_IDS.TEAM,
              route: `/team/settings/${TEAM_USER_STATUSES.JOINED}`,
            }
          : null,
      ],
    },
  ];

  const agencyMenuItemsWithoutApplications = agencyAllMenuItems.filter(
    (item) => item?.nodeId !== SIDEBAR_MENU_ITEM_IDS.APPLICATIONS
  );

  const agencyMenuItems = useMemo(() => {
    if (applicationsAllowed) {
      return agencyAllMenuItems;
    }
    return agencyMenuItemsWithoutApplications;
  }, [username]);

  const menuItems = getMenuItems({
    t,
    isAgency: Boolean(isAgency),
    agencyMenuItems: agencyMenuItems as unknown as ISidebarMenuItem[],
    isAdmin: Boolean(isAdmin),
    isCandidate: Boolean(isCandidate),
  });

  const isSettingsSubMenus =
    sidebarMenu === SIDEBAR_MENU_ITEM_IDS.COMPANY_SETTINGS ||
    sidebarMenu === SIDEBAR_MENU_ITEM_IDS.JOB_WIDGET ||
    sidebarMenu === SIDEBAR_MENU_ITEM_IDS.TEAM;

  const isShopSubMenus =
    sidebarMenu === SIDEBAR_MENU_ITEM_IDS.DISCOVER ||
    sidebarMenu === SIDEBAR_MENU_ITEM_IDS.INVENTORY;

  const [isOpenMenu, setIsOpenMenu] = useState(true);
  const [expandedMenuItems, setExpandedMenuItems] = useState<string[]>(
    isSettingsSubMenus ? ["settings"] : isShopSubMenus ? ["shop"] : []
  );
  const [selectedMenuItems, setSelectedMenuItems] = useState<string[]>([
    sidebarMenu,
  ]);

  const handleOnOpenMenu = () => {
    setIsOpenMenu(!isOpenMenu);
  };

  const handleOnOpenJobEditor = () => {
    if (isAgency) {
      dispatch(setIsSelectCustomerDialogOpen(true));
    } else if (company?.jobs_creation_allowed) {
      dispatch(openJobEditor());
    } else {
      dispatch(setIsLimitDialogOpen(true));
    }
  };

  const handleOnNavigateMenu = (route?: string) => {
    if (route) {
      navigate(route);
    }
    if (!desktop) {
      dispatch(setMobileDrawerOpen(false));
    }
  };

  const handleOnExpandMenuItem = (
    _event: SyntheticEvent,
    nodeIds: string[]
  ) => {
    setExpandedMenuItems(nodeIds);
  };

  const handleOnSelectMenuItem = (
    _event: SyntheticEvent,
    nodeIds: string[]
  ) => {
    const ids = Array.isArray(nodeIds) ? nodeIds : [nodeIds];
    if (ids.includes("settings") || ids.includes("shop")) {
      setSelectedMenuItems([]);
    } else {
      setSelectedMenuItems(ids);
    }
  };

  const handleOnMobileDrawerClose = () => {
    dispatch(setMobileDrawerOpen(false));
  };

  useEffect(() => {
    setSelectedMenuItems([sidebarMenu]);
    if (isSettingsSubMenus) {
      setExpandedMenuItems(["settings"]);
    }
    if (isShopSubMenus) {
      setExpandedMenuItems(["shop"]);
    }
  }, [sidebarMenu]);

  useEffect(() => {
    if (expandedMenuItems.length > 1) {
      setExpandedMenuItems([expandedMenuItems[0]]);
    }
  }, [expandedMenuItems]);

  return (
    <SidebarComponent
      t={t}
      expandedMenuItems={expandedMenuItems}
      isCandidate={Boolean(isCandidate)}
      isOpenMenu={isOpenMenu}
      isLoading={isLoading}
      menuItems={menuItems}
      desktop={desktop}
      mobileDrawerOpen={mobileDrawerOpen}
      selectedMenuItems={selectedMenuItems}
      onMobileDraweClose={handleOnMobileDrawerClose}
      onExpandMenuItem={handleOnExpandMenuItem}
      onNavigateMenu={handleOnNavigateMenu}
      onOpenJobEditor={handleOnOpenJobEditor}
      onSelectMenuItem={handleOnSelectMenuItem}
      onToggleMenu={handleOnOpenMenu}
    />
  );
};

export default Sidebar;
