import React from "react";
import CandidateItem from "./CandidateItem";
import { IRenderItemComponent } from "../../models/Kanban";

const RenderItem = ({
  t,
  variant = "candidate",
  provided,
  data,
  selectedItems,
  handleSelectItem,
  onItemClick,
}: IRenderItemComponent) => {
  switch (variant) {
    case "candidate":
      return (
        <CandidateItem
          t={t}
          provided={provided}
          data={data}
          selectedItems={selectedItems}
          handleSelectItem={handleSelectItem}
          onItemClick={onItemClick}
        />
      );
    default:
      return (
        <CandidateItem
          t={t}
          provided={provided}
          data={data}
          selectedItems={selectedItems}
          handleSelectItem={handleSelectItem}
          onItemClick={onItemClick}
        />
      );
  }
};

export default RenderItem;
