import { TLoginPayload } from "../models/Auth";
import { apiRequest } from "./config";

export const apiLogIn = ({
  url,
  apiPayload,
}: {
  url: string;
  apiPayload: TLoginPayload;
}) => apiRequest({ type: "post", url, apiPayload });

export const apiCandidateLogin = ({ url }: { url: string }) =>
  apiRequest({ type: "post", url });

export const apiResetPassword = ({
  url,
  apiPayload,
}: {
  url: string;
  apiPayload: { email: string };
}) => apiRequest({ type: "post", url, apiPayload });

export const apiRecoveryPassword = ({
  url,
  apiPayload,
}: {
  url: string;
  apiPayload: { password: string; confirm: string };
}) => apiRequest({ type: "post", url, apiPayload });
